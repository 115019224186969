import { ApolloQueryResult } from '@apollo/client'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { useSettings } from '../../utils/lexical/context/SettingsContext'
import SeisoNodes from '../../utils/lexical/nodes/SeisoNodes'
import SeisoEditorTheme from '../../utils/lexical/themes/SeisoEditorTheme'
import { CreateFeedItemForm } from './createFeedItemForm'
import './styles/feed.css'
import { CardinalStateContext } from '../../context/cardinal'

export enum FeedType {
  Replies = 'replies',
  Post = 'post',
}

export enum PostType {
  Post = 'post',
  Reply = 'reply',
}
interface FeedProps {
  scrollPosition?: number
  disableCreateFeedItem?: boolean
  newPostsNotification?: JSX.Element | null
  focusedItem?: JSX.Element | null
  items?: JSX.Element[]
  loading?: boolean
  feedType: FeedType
  fetchMore?: () => Promise<ApolloQueryResult<any>>
  noItemsMessage?: string
}

export function Feed({
  scrollPosition = 0,
  disableCreateFeedItem,
  newPostsNotification,
  focusedItem,
  items,
  loading,
  fetchMore,
  feedType,
  noItemsMessage,
}: FeedProps) {
  const { isMobileView } = useContext(CardinalStateContext)
  const { post_id } = useParams()
  const [prevIntersectionTarget, setPrevIntersectionTarget] = useState<String | undefined>()

  const scrollToTop = useDebouncedCallback(() => {
    window.scrollTo(0, scrollPosition)
    console.log(scrollPosition)
    //document.body.scrollTo(0, scrollPosition)
  }, 0)

  useEffect(() => {
    window.scrollTo(0, scrollPosition)
    // scrollToTop()
  }, [items])

  const observer = useRef<IntersectionObserver>()
  const intersectionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting && fetchMore && prevIntersectionTarget !== entry.target.id) {
          setPrevIntersectionTarget(entry.target.id)
          fetchMore()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [fetchMore, observer, prevIntersectionTarget]
  )

  let loadingItems = Array(10)
    .fill(undefined)
    .map((_value, index) => {
      return (
        <div className="Post-Container" key={index}>
          <div className="Post-Loading-Placeholder" />
        </div>
      )
    })

  // useEffect(() => {
  //   window.addEventListener('load', videoScroll)
  //   window.addEventListener('scroll', videoScroll)

  //   return () => {
  //     window.removeEventListener('load', videoScroll)
  //     window.removeEventListener('scroll', videoScroll)
  //   }
  // }, [])

  function videoScroll() {
    let videoEl: any = document.getElementsByClassName('Post-Video')
    if (videoEl.length > 0) {
      let windowHeight = window.innerHeight

      for (let i = 0; i < videoEl.length; i++) {
        let thisVideoEl = videoEl[i],
          videoHeight = thisVideoEl.clientHeight,
          videoClientRect = thisVideoEl.getBoundingClientRect().top

        if (
          videoClientRect <= windowHeight - videoHeight * 0.5 &&
          videoClientRect >= 0 - videoHeight * 0.5 &&
          thisVideoEl.readyState === 4
        ) {
          thisVideoEl.play()
        } else if (thisVideoEl.readyState === 4) {
          thisVideoEl.pause()
        }
      }
    }
  }

  const {
    settings: { emptyEditor },
  } = useSettings()

  const initialConfig = {
    editorState: emptyEditor ? undefined : undefined,
    namespace: 'Seiso',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <div className={`Feed-Container ${feedType === FeedType.Replies ? 'Feed-Container-Thread' : ''}`}>
      {focusedItem}
      {!disableCreateFeedItem && !isMobileView && (
        <LexicalComposer initialConfig={initialConfig}>
          <CreateFeedItemForm feedType={feedType} threadId={post_id} />
        </LexicalComposer>
      )}
      {newPostsNotification}
      {items?.map((item, index) =>
        items.length - 10 === index - 1 ? (
          <div id={`${item.key}`} key={item.key} ref={intersectionElementRef}>
            {item}
          </div>
        ) : (
          item
        )
      )}
      {/* {items && items.length === 0 && noItemsMessage && <div className="No-Feed-Items-Message">{noItemsMessage}</div>}
      {(loading || !items) && loadingItems} */}
    </div>
  )
}

export default Feed
