import { useContext, useEffect, useState } from 'react'
import './reportModal.css'
import CloseIcon from '@mui/icons-material/Close'
import { useReportPostMutation, useReportUserMutation } from '../../gql/generated/graphql'
import { CardinalStateContext } from '../../context/cardinal'

interface ReportModalProps {
  post?: boolean
  user?: boolean
  bug?: boolean
  postId?: string
  userHandle?: any
  closeModal: () => void
}

export function ReportModal({ post, user, bug, postId, userHandle, closeModal }: ReportModalProps) {
  const [text, setText] = useState('')
  const [blockDupe, setBlockDupe] = useState(false)
  const state = useContext(CardinalStateContext)
  const [reportPost] = useReportPostMutation()
  const [reportUser] = useReportUserMutation()

  async function reportPostSubmit() {
    if (postId && state.user && !blockDupe) {
      setBlockDupe(true)
      await reportPost({ variables: { post_id: postId, text: text, submitted_by_handle: state?.user.handle } })
      closeModal()
    }
  }

  async function reportUserSubmit() {
    if (userHandle && state.user && !blockDupe) {
      setBlockDupe(true)
      await reportUser({
        variables: { user_to_report: userHandle, text: text, submitted_by_handle: state?.user.handle },
      })
      closeModal()
    }
  }

  useEffect(() => {
    let drag = false
    let startOnModal = true

    function trackClick(e: any) {
      if (e.target.closest('#Report-Modal-Container-Inner') == null && !drag) {
        closeModal()
      }
      startOnModal = true
    }
    function setDragTrue() {
      if (startOnModal) {
        drag = true
      }
    }
    function setDragFalse(e: any) {
      drag = false
      if (e.target.closest('#Report-Modal-Container-Inner') == null) {
        startOnModal = false
      }
    }

    document.addEventListener('mousedown', setDragFalse, true)
    document.addEventListener('mousemove', setDragTrue, true)
    document.addEventListener('mouseup', trackClick, true)
    return () => {
      document.removeEventListener('mousedown', setDragFalse, true)
      document.removeEventListener('mousemove', setDragTrue, true)
      document.removeEventListener('mouseup', trackClick, true)
    }
  }, [])
  return (
    <div className="Report-Modal-Container">
      <div className="Report-Modal-Container-Inner" id="Report-Modal-Container-Inner">
        <div className="Close-Report-Modal" onClick={closeModal}>
          <CloseIcon />
        </div>
        <div className="Report-Fields-Wrapper">
          <h1>{bug ? 'Bug' : `Report a ${post ? 'post' : user && 'user'}`}</h1>
          <span>
            {bug
              ? 'Please provide as much detail as you can so we can locate the issue. Thank you!'
              : `Please describe the reason you're reporting this ${post ? 'post.' : user && 'user.'}`}
          </span>
          <textarea value={text} onChange={e => setText(e.target.value)} placeholder="Report description" />
          <div
            className="Create-Report-Button"
            onClick={() => {
              if (post) reportPostSubmit()
              if (user) reportUserSubmit()
            }}>
            Submit
          </div>
        </div>
      </div>
    </div>
  )
}
