import './styles/profileMenu.css'
import { useState } from 'react'
import { ReportModal } from '../reports/reportModal'
import { useNavigate } from 'react-router-dom'
import { User } from '../../gql/generated/graphql'
import { ClickAwayListener } from '../modal/clickAwayListener'

interface ProfileMenuProps {
  closeMenuFunction: () => void
  user: User
  profileId: string
}

export function ProfileMenu({ closeMenuFunction, user, profileId }: ProfileMenuProps) {
  const navigate = useNavigate()

  const [reportModalOpen, setReportModalOpen] = useState(false)

  return (
    <>
      <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Profile-Menu">
        <div className="Profile-Menu">
          {reportModalOpen && <ReportModal user userHandle={profileId} closeModal={closeMenuFunction} />}
          <div className="Profile-Menu-Options">
            {profileId === user.handle ? (
              <div onClick={() => navigate('/settings/general')} className="Profile-Menu-Option">
                Edit Profile
              </div>
            ) : (
              <>
                <div className="Profile-Menu-Option" onClick={() => closeMenuFunction()}>
                  Block (@{profileId})
                </div>
                <div className="Profile-Menu-Option Profile-Menu-Option-Red" onClick={() => setReportModalOpen(true)}>
                  Report User
                </div>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}
