import './styles/miniProfileDefaultBackground.css'

function InnerHex(): JSX.Element {
  let hexagons = Array(9)
    .fill(undefined)
    .map((_value, index) => {
      return <div className="Mini-Profile-Hexagon" key={index} />
    })
  return (
    <>
      <div className="Mini-Profile-BG-Glow"></div>
      <div className="Mini-Profile-Hex-Row">{hexagons}</div>
      <div className="Mini-Profile-Hex-Row">{hexagons}</div>
      <div className="Mini-Profile-Hex-Row">{hexagons}</div>
      <div className="Mini-Profile-Hex-Row">{hexagons}</div>
      <div className="Mini-Profile-Hex-Row">{hexagons}</div>
      <div className="Mini-Profile-Hex-Row">{hexagons}</div>
    </>
  )
}
export function MiniProfileDefaultBackground() {
  return (
    <div className="Mini-Profile-Hexagon-Container">
      <InnerHex />
    </div>
  )
}
