import { NavLink } from 'react-router-dom'

import './styles/navTabs.css'

export interface TabType {
  path: string
  tabName: string
  tabID: string
}
interface NavTabsProps {
  tabs: TabType[]
  activeTab: string
}

export function NavTabs({ tabs, activeTab }: NavTabsProps) {
  return (
    <div className="Nav-Tabs">
      {tabs.map((item: TabType, key: number) => (
        <NavLink replace key={key} to={item.path} className={`Nav-Tab ${activeTab === item.tabID && 'Nav-Tab-Active'}`}>
          {item.tabName}
        </NavLink>
      ))}
    </div>
  )
}
