import { useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { AuthStateContext, AuthenticationState } from '../../context/authContext'

export function RequireAuth({ children }: any) {
  const location = useLocation()
  const { authenticationState } = useContext(AuthStateContext)
  if (authenticationState === AuthenticationState.Anonymous) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}
