import './streamContainer.css'
import { StreamProvider } from '../../../gql/scalars'

export type StreamSettings = {
  __typename?: 'StreamSettings'
  twitchID?: string | null
  youtubeID?: string | null
}

interface StreamProps {
  streamProvider: any
  streamSettings: StreamSettings | null | undefined
}

export function StreamContainer(props: StreamProps) {
  const isDev = process.env.NODE_ENV === 'development' ? true : false
  let localhost = 'localhost'
  let domain = 'test-4ch.pages.dev'
  return (
    <>
      <div className="Video-Container Fade_In">
        {props.streamProvider === StreamProvider.Twitch ? (
          <iframe
            title="TwitchStream"
            src={`https://player.twitch.tv?autoplay=true&channel=${props.streamSettings?.twitchID}&parent=${
              isDev ? localhost : domain
            }&player=popout&muted=true&playsinline=true&/`}
            allowFullScreen
            scrolling="no"
            frameBorder="0"
            allow="autoplay; fullscreen"
            sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            height="100%"
            width="100%"
          />
        ) : (
          props.streamProvider === StreamProvider.Youtube && (
            <iframe
              title="YoutubeStream"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${props.streamSettings?.youtubeID}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )
        )}
      </div>
    </>
  )
}
