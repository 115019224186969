import { useContext, useEffect } from 'react'
import './styles/navMobileProfileMenu.css'
import { CardinalStateContext } from '../context/cardinal'
import { ProfileAvatar } from '../components/profile/display/profileAvatar'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Twemoji from 'react-twemoji'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { getProviderIcon } from '../utils/statusUtils'

export function NavMobileProfileMenu() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.state === null || location.state.background === null) {
      navigate('/')
    }
  }, [])

  const { user } = useContext(CardinalStateContext)
  return (
    <div className="Nav-Mobile-Profile-Menu-Container">
      <div className="Nav-Mobile-Profile-Menu-Header">
        <video
          autoPlay
          loop
          playsInline
          muted
          disablePictureInPicture
          className="Nav-Mobile-Profile-Menu-Header-BG"
          src={user?.mini_profile_background + '#t=0.1' ?? ''}
        />
        <div className="Nav-Mobile-Profile-Menu-Header-Inner">
          <div className="Nav-Mobile-Profile-Menu-Header-Inner-Back" onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon />
          </div>
          {user && <ProfileAvatar disableLink disableMiniProfile size="Medium" user={user} />}
          <div className="Nav-Mobile-Profile-Menu-Header-Inner-User-Info">
            <div className="Nav-Mobile-Profile-Menu-Header-Inner-User-Info-Top">
              <Twemoji options={{ className: 'Mini-Profile-Displayname-Emoji' }}>{user?.username}</Twemoji>
            </div>
            <div className="Nav-Mobile-Profile-Menu-Header-Inner-User-Info-Bottom">
              <NavLink className="Nav-Mobile-Profile-Menu-View-Profile-Button" to={`/profile/${user?.handle}`} replace>
                View My Profile
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="Nav-Mobile-Profile-Menu-User-Info">
        <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregates">
          <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate">
            <NavLink
              to={`/profile/${user?.handle}/activity`}
              className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top"
              replace>
              {user?.posts_aggregate.aggregate?.count ?? 0}
            </NavLink>
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom">Posts</div>
          </div>
          <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate">
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top">
              {user?.following_aggregate.aggregate?.count ?? 0}
            </div>
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom">Following</div>
          </div>
          <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate">
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top">
              {user?.followers_aggregate.aggregate?.count ?? 0}
            </div>
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom">Followers</div>
          </div>
        </div>
        <div className="Nav-Mobile-Profile-Menu-User-Info-Socials">
          {user?.social_links.slice(0, 5).map((socialLink, key) => (
            <a
              href={socialLink?.url}
              target="_blank"
              rel="noreferrer"
              className="Social-Icon-Container Mini-Profile-Social-Icon-Container"
              key={key}>
              <div className={`Social-Icon ${socialLink?.provider}-Icon`}>
                <img src={getProviderIcon(socialLink?.provider) ?? socialLink?.icon} alt="" />
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="Nav-Mobile-Profile-Menu-Options">
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/notifications/all'} replace>
          Notifications
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/messages'} replace>
          Messages
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/bookmarks'} replace>
          Bookmarks
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/status'} replace>
          Set Status
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/settings/general'} replace>
          Settings
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'#'} replace>
          Logout
        </NavLink>
      </div>
    </div>
  )
}
