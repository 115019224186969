import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import { PathNames, ModalPathNames, PathNameRoutes } from './constants/pathNames'
/*Navigation*/
import { Nav } from './navigation/nav'
import { RequireAuth } from './managers/redirectManager/requireAuth'
/*Components*/
import { BackgroundManager } from './managers/backgroundManager/backgroundManager'
import { ThemeManager } from './managers/themeManager/themeManager'
import { PostImagePreviewModal } from './components/feed/modal/postImagePreviewModal'
import { OpenImageModal } from './components/miscModals/openImageModal'
import { CreatePostRouteModal } from './components/feed/modal/createPostRouteModal'
import { ImageEditorRouteModal } from './components/profile/editor/editorModals/imageEditorRouteModal'
import { VideoEditorRouteModal } from './components/profile/editor/editorModals/videoEditorRouteModal'
import { NavMobileProfileMenu } from './navigation/navMobileProfileMenu'
import { SetStatusRouteModal } from './navigation/setStatusRouteModal'
/*Pages*/
import { Authentication } from './pages/authentication'
import { Bookmarks } from './pages/bookmarks'
import { Browse } from './pages/browse'
import { DevTools } from './pages/devTools'
import { Explore } from './pages/explore'
import { Hashtag } from './pages/hashtag'
import { Home } from './pages/home'
import { Notifications } from './pages/notifications'
import { Post } from './pages/post'
import { Profile } from './pages/profile'
import { ProfileSettings } from './pages/profileSettings'
import { RoadMap } from './pages/roadMap'
import { TermsOfService } from './pages/termsOfService'
import { PrivacyPolicy } from './pages/privacyPolicy'
import { Search } from './pages/search'
import { FollowList } from './pages/followList'
/*Cardinal*/
import { Cardinal, CardinalStateContext } from './context/cardinal'
import { Editor } from './context/editorContext'
/*Styles*/
import './globalstyles/App.css'
import './globalstyles/Crop.css'
import './globalstyles/Themes.css'
import { Messages } from './pages/messages'

import {
  Outlet,
  Route,
  Routes,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  matchPath,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CardinalApolloProvider } from './apollo/cardinalApolloProvider'
import { MiniProfile } from './components/profile/display/miniProfile'
import { AuthProvider } from './context/authContext'
import { useContext, useEffect } from 'react'

function App() {
  Amplify.configure(awsconfig)
  const isDev = process.env.NODE_ENV === 'development' ? true : false

  function RootRouteComponent() {
    const location = useLocation()
    const background = location.state && location.state.background
    const { isMobileView } = useContext(CardinalStateContext)

    useEffect(() => {
      if (background) {
        document.documentElement.style.overflowY = 'hidden'
        if (isMobileView) document.documentElement.style.position = 'fixed'
      } else {
        document.documentElement.style.overflowY = 'scroll'
        //wonder if i should just make it unset so scroll thumb doesnt always show
        document.documentElement.style.position = 'unset'
      }
    }, [background])

    return (
      <>
        <ScrollRestoration
          getKey={location => {
            const paths = ['/']
            return paths.includes(location.pathname) ? location.pathname : location.key
          }}
        />
        <MiniProfile />
        <Nav />
        <BackgroundManager />

        <div className="App-Browser">
          <Routes location={background || location}>
            <Route index element={<Home />} />
            <Route path={PathNames.TOS} element={<TermsOfService />} />
            <Route path={PathNames.Privacy} element={<PrivacyPolicy />} />
            <Route path={PathNames.Authentication} element={<Authentication />} />
            <Route path={PathNames.Profile} element={<Profile />} />
            <Route path={PathNames.ProfileTab} element={<Profile />} />
            <Route path={PathNames.Followers} element={<FollowList />} />
            <Route path={PathNames.Following} element={<FollowList />} />
            <Route path={PathNames.Followers_You_Follow} element={<FollowList />} />
            <Route path={PathNames.DevTools} element={isDev ? <DevTools /> : <Home />} />
            <Route path={PathNames.Post} element={<Post />} />
            <Route path={PathNames.RoadMap} element={<RoadMap />} />
            <Route path={PathNames.Explore} element={<Explore />} />
            <Route path={PathNames.Bookmarks} element={<Bookmarks />} />
            <Route path={PathNames.Search} element={<Search />} />
            <Route path={PathNames.SearchId} element={<Search />} />
            <Route path={PathNames.Hashtag} element={<Hashtag />} />
            <Route path={PathNames.Browse} element={<Browse />} />
            <Route
              path={PathNames.Messages}
              element={
                <RequireAuth>
                  <Messages />
                </RequireAuth>
              }
            />
            <Route
              path={PathNames.MessagesId}
              element={
                <RequireAuth>
                  <Messages />
                </RequireAuth>
              }
            />
            <Route
              path={PathNames.Settings}
              element={
                <RequireAuth>
                  <ProfileSettings />
                </RequireAuth>
              }
            />
            <Route
              path={PathNames.SettingsTab}
              element={
                <RequireAuth>
                  <ProfileSettings />
                </RequireAuth>
              }
            />
            <Route
              path={PathNames.Notifications}
              element={
                <RequireAuth>
                  <Notifications />
                </RequireAuth>
              }
            />
          </Routes>

          <Routes location={location}>
            <Route path={ModalPathNames.PostImage} element={<PostImagePreviewModal />} />
            <Route
              path={ModalPathNames.CreatePost}
              element={
                <RequireAuth>
                  <CreatePostRouteModal />
                </RequireAuth>
              }
            />
            <Route path={ModalPathNames.SetStatus} element={<SetStatusRouteModal />} />
            <Route path={ModalPathNames.OpenImage} element={<OpenImageModal />} />
            <Route path={ModalPathNames.ShowcaseImageEditor} element={<ImageEditorRouteModal />} />
            <Route path={ModalPathNames.ShowcaseVideoEditor} element={<VideoEditorRouteModal />} />
            <Route path={ModalPathNames.MobileProfileNav} element={<NavMobileProfileMenu />} />
          </Routes>

          <Outlet />
        </div>
      </>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootRouteComponent />}>
        {PathNameRoutes.map((item, index) => (
          <Route key={index} path={item} element={null} />
        ))}
      </Route>
    )
  )

  return (
    <AuthProvider>
      <CardinalApolloProvider>
        <Cardinal>
          <Editor>
            <ThemeManager>
              <div className="App">
                <ToastContainer theme="dark" position="bottom-left" limit={5} closeOnClick={false} />
                <RouterProvider router={router} />
              </div>
            </ThemeManager>
          </Editor>
        </Cardinal>
      </CardinalApolloProvider>
    </AuthProvider>
  )
}

export default App
