import { LazyQueryHookOptions } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import { BrowsingState, CardinalStateContext } from '../context/cardinal'
import {
  GetPostQuery,
  GetPostQueryVariables,
  Order_By,
  Post as GraphqlPost,
  useGetPostLazyQuery,
  User,
} from '../gql/generated/graphql'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './styles/thread.css'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'

export function Post() {
  const navigate = useNavigate()
  const location = useLocation()
  const { owner_id, post_id } = useParams()
  const { user, browsingState } = useContext(CardinalStateContext)
  const [getPost, { data, loading, fetchMore }] = useGetPostLazyQuery()
  let queryOptions: LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables> = {
    variables: {
      id: post_id,
      limit: 20,
      offset: data?.post_by_pk?.replies?.length ?? 0,
      order_by: { created_at: Order_By.Desc },
      currentUserHandle: browsingState === BrowsingState.Authenticated ? user?.handle : '',
    },
  }

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      getPost(queryOptions)
    }
    // eslint-disable-next-line
  }, [browsingState, getPost, location])

  const feedItems = useMemo(() => {
    return data?.post_by_pk?.replies.map((reply, index) => {
      return (
        <FeedItem
          id={reply.id}
          timestamp={reply.created_at}
          owner={reply.owner as User}
          text={reply.text}
          gifUrl={reply.gif_url}
          imageUrls={reply.image_urls}
          videoUrl={reply.video_url}
          replyCount={reply.replies_aggregate.aggregate?.count}
          voteCount={reply.votes_aggregate.aggregate?.count}
          shareCount={
            (reply.reposts_aggregate.aggregate?.count ?? 0) + (reply.quote_reposts_aggregate.aggregate?.count ?? 0)
          }
          voted={reply.votes.length > 0 && reply.votes[0].owner_handle === user?.handle}
          bookmarked={reply.bookmarked_posts.length > 0 && reply.bookmarked_posts[0]?.owner_handle === user?.handle}
          itemType={FeedType.Replies}
          replyToHandle={reply.reply_to_post?.owner_handle}
          key={index}
        />
      )
    })
    // eslint-disable-next-line
  }, [data])

  const focusedItem = data?.post_by_pk && (
    <FeedItem
      id={data.post_by_pk.id}
      timestamp={data.post_by_pk.created_at}
      owner={data.post_by_pk.owner as User}
      text={data.post_by_pk.text}
      gifUrl={data.post_by_pk.gif_url}
      imageUrls={data.post_by_pk.image_urls}
      videoUrl={data.post_by_pk.video_url}
      replyCount={data.post_by_pk.replies_aggregate.aggregate?.count}
      voteCount={data.post_by_pk.votes_aggregate.aggregate?.count}
      shareCount={
        (data.post_by_pk.reposts_aggregate.aggregate?.count ?? 0) +
        (data.post_by_pk.quote_reposts_aggregate.aggregate?.count ?? 0)
      }
      voted={data.post_by_pk.votes?.length > 0 && data.post_by_pk.votes[0]?.owner_handle === user?.handle}
      bookmarked={
        data.post_by_pk.bookmarked_posts.length > 0 &&
        data.post_by_pk.bookmarked_posts[0]?.owner_handle === user?.handle
      }
      itemType={FeedType.Post}
      key={data.post_by_pk.id}
      replyToHandle={data.post_by_pk.reply_to_post?.owner_handle}
      quoteRepostData={data.post_by_pk.quote_repost_post as GraphqlPost}
      focusedItem
    />
  )

  return (
    <div className="Thread-Container">
      <NavBackButtonHeader title="Post" />

      <Feed
        loading={loading}
        items={feedItems}
        focusedItem={focusedItem}
        fetchMore={() => fetchMore(queryOptions)}
        feedType={FeedType.Replies}
        noItemsMessage="There are no replies yet, be the first!"
      />
    </div>
  )
}
