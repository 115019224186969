import { HTMLInputTypeAttribute } from 'react'

export enum AuthenticationTab {
  SignIn = 'signIn',
  SignUp = 'signUp',
}

export enum AuthenticationFormField {
  Username = 'username',
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  ConfirmationCode = 'confirmationCode',
}

export enum AuthenticationAction {
  SignIn = 'signIn',
  SignUp = 'signUp',
  ConfirmSignUp = 'confirmSignUp',
  SendSignUpConfirmationCode = 'sendSignUpConfirmationCode',
  ResetPassword = 'resetPassword',
  SendResetPasswordCode = 'sendResetPasswordCode',
  ConfirmResetPassword = 'confirmResetPassword',
  GoToSignIn = 'goToSignIn', // For returning when in the reset password flow.
  GoToSignUp = 'goToSignUp',
}

export enum AuthenticationFlowState {
  None = 'none',
  ConfirmingSignUp = 'confirmingSignUp',
  FailedAutoSignUp = 'failedAutoSignUp',
  RequestingResetPassword = 'requestingResetPassword',
  ConfirmingResetPassword = 'confirmingResetPassword',
  SuccessConfirmation = 'successConfirmation',
}

export interface ITabFormField {
  formFieldName: AuthenticationFormField
  type?: HTMLInputTypeAttribute
  placeholder?: string
}

export interface IInputValidator {
  formFieldName: AuthenticationFormField
  regexExpression: RegExp
  errorMessage: string
}

export interface IAuthenticationAction {
  displayText: string
  action: AuthenticationAction
}

export type IAuthenticationFormData = {
  [key in AuthenticationFormField]?: string
}
