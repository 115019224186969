import { useContext, useMemo, useState } from 'react'
import { CardinalStateContext } from '../../context/cardinal'
import { Post, User } from '../../gql/generated/graphql'
import { Feed, FeedType } from '../feed/feed'
import { FeedItem } from '../feed/feedItem'
import './styles/profileActivity.css'
import { PostFilterType, useListPosts } from '../../gql'
import MenuIcon from '@mui/icons-material/Menu'
import { ClickAwayListener } from '../modal/clickAwayListener'

interface ProfileActivityProps {
  profile: User | undefined
}

export enum ActivityType {
  posts = 'posts',
  likes = 'likes',
  comments = 'comments',
}

export function ProfileActivity(props: ProfileActivityProps) {
  const [activityType, setActivityType] = useState<ActivityType>(ActivityType.posts)
  const { user } = useContext(CardinalStateContext)
  const { data, loading, fetchMore } = useListPosts(getFilterByActivityType(activityType), props.profile?.handle)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  function getFilterByActivityType(activityType?: ActivityType) {
    if (activityType === ActivityType.posts) {
      return PostFilterType.CreatedByUser
    }

    if (activityType === ActivityType.likes) {
      return PostFilterType.LikedByUser
    }

    //TODO: Change default when replies are implement
    return PostFilterType.CreatedByUser
  }

  const activityPostItems = useMemo(() => {
    return data?.post.map((post, index) => {
      if (post.repost_post) {
        return (
          <FeedItem
            id={post.repost_post.id}
            timestamp={post.repost_post.created_at}
            owner={post.repost_post.owner as User}
            text={post.repost_post.text}
            gifUrl={post.repost_post.gif_url}
            imageUrls={post.repost_post.image_urls}
            videoUrl={post.repost_post.video_url}
            replyCount={post.repost_post.replies_aggregate.aggregate?.count}
            voteCount={post.repost_post.votes_aggregate.aggregate?.count}
            shareCount={
              (post.repost_post.quote_reposts_aggregate.aggregate?.count ?? 0) +
              (post.repost_post.reposts_aggregate.aggregate?.count ?? 0)
            }
            voted={post.repost_post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle}
            bookmarked={
              post.repost_post.bookmarked_posts.length > 0 &&
              post.repost_post.bookmarked_posts[0]?.owner_handle === user?.handle
            }
            itemType={FeedType.Post}
            key={index}
            sharedBy={post.owner as User}
          />
        )
      } else {
        return (
          <FeedItem
            id={post.id}
            timestamp={post.created_at}
            owner={post.owner as User}
            text={post.text}
            gifUrl={post.gif_url}
            imageUrls={post.image_urls}
            videoUrl={post.video_url}
            replyCount={post.replies_aggregate.aggregate?.count}
            voteCount={post.votes_aggregate.aggregate?.count}
            shareCount={
              (post.quote_reposts_aggregate.aggregate?.count ?? 0) + (post.reposts_aggregate.aggregate?.count ?? 0)
            }
            voted={post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle}
            bookmarked={post.bookmarked_posts.length > 0 && post.bookmarked_posts[0]?.owner_handle === user?.handle}
            itemType={FeedType.Post}
            key={index}
            replyToHandle={post.reply_to_post?.owner_handle}
            quoteRepostData={post.quote_repost_post as Post}
          />
        )
      }
    })
  }, [data])

  return (
    <>
      <div className="Profile-Activity-Container">
        <div onClick={() => setMobileMenuOpen(true)} className="Profile-Activity-Mobile-Menu-Button">
          <MenuIcon />
        </div>
        <ClickAwayListener onClickAway={() => setMobileMenuOpen(false)} id="Profile-Activity-Left-Mobile-Menu">
          <div className={`Profile-Activity-Left ${mobileMenuOpen ? 'Profile-Activity-Left-Mobile-Menu-Open' : ''}`}>
            <div
              onClick={() => {
                setMobileMenuOpen(false)
                setActivityType(ActivityType.posts)
              }}
              className={`Profile-Activity-Tab ${
                activityType === ActivityType.posts && 'Profile-Activity-Link-Active'
              }`}>
              Posts
            </div>
            <div
              onClick={() => {
                setMobileMenuOpen(false)
                setActivityType(ActivityType.likes)
              }}
              className={`Profile-Activity-Tab ${
                activityType === ActivityType.likes && 'Profile-Activity-Link-Active'
              }`}>
              Likes
            </div>
            <div
              onClick={() => {
                setMobileMenuOpen(false)
                setActivityType(ActivityType.comments)
              }}
              className={`Profile-Activity-Tab ${
                activityType === ActivityType.comments && 'Profile-Activity-Link-Active'
              }`}>
              Replies
            </div>
            <div
              onClick={() => {
                setMobileMenuOpen(false)
                setActivityType(ActivityType.comments)
              }}
              className={`Profile-Activity-Tab ${
                activityType === ActivityType.comments && 'Profile-Activity-Link-Active'
              }`}>
              Media
            </div>
          </div>
        </ClickAwayListener>
        <div className="Profile-Activity-Right">
          {(activityType === ActivityType.posts || activityType === ActivityType.likes) && (
            <Feed
              disableCreateFeedItem
              loading={loading}
              items={activityPostItems}
              feedType={FeedType.Post}
              fetchMore={fetchMore}
            />
          )}
        </div>
      </div>
    </>
  )
}
