import { useContext, useEffect, useMemo } from 'react'
import { ListPostsQuery, ListPostsQueryVariables, Order_By, useListPostsLazyQuery } from '../../generated/graphql'
import { BrowsingState, CardinalStateContext } from '../../../context/cardinal'
import { LazyQueryHookOptions } from '@apollo/client'
import { PostFilterType } from '../../filter'

export function useListPosts(filterType: PostFilterType, filterValue?: string) {
  const { user, browsingState } = useContext(CardinalStateContext)
  const [listPosts, { data, loading, fetchMore, refetch }] = useListPostsLazyQuery()

  // TODO: Define filter for new users (logged in), hashtags, etc.
  const queryOptions: LazyQueryHookOptions<ListPostsQuery, ListPostsQueryVariables> = useMemo(() => {
    const commonOptions: LazyQueryHookOptions<ListPostsQuery, ListPostsQueryVariables> = {
      variables: {
        limit: 20,
        offset: 0,
        order_by: { created_at: Order_By.Desc },
        currentUserHandle: user?.handle ?? '',
      },
      fetchPolicy: 'cache-and-network',
    }
    switch (filterType) {
      case PostFilterType.Following:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: user?.handle
              ? {
                  _or: [
                    { owner: { followers: { follower_handle: { _eq: user?.handle } } }, type: { _eq: 'post' } },
                    {
                      hashtags: { hashtagName: { hashtag_followers: { follower_handle: { _eq: user?.handle } } } },
                      type: { _eq: 'post' },
                    },
                  ],
                }
              : {},
          },
        }
      case PostFilterType.CreatedByUser:
        return {
          ...commonOptions,
          variables: { ...commonOptions.variables, postFilter: { owner_handle: { _eq: filterValue ?? '' } } },
        }
      case PostFilterType.LikedByUser:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { votes: { owner_handle: { _eq: filterValue ?? '' } } },
          },
        }
      case PostFilterType.ByHashtag:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { hashtags: { hashtag: { _eq: filterValue } }, type: { _eq: 'post' } },
          },
        }
      default:
        return commonOptions
    }
  }, [user?.handle, filterType, filterValue])

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      listPosts(queryOptions)
    }
  }, [browsingState, listPosts, queryOptions])

  return {
    data,
    loading,
    fetchMore: () => fetchMore({ variables: { ...queryOptions.variables, offset: data?.post.length ?? 0 } }),
    refetch,
  }
}
