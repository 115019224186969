import AddIcon from '@mui/icons-material/Add'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { Masonry } from '@mui/lab'
import imageCompression from 'browser-image-compression'
import { ChangeEvent, useContext } from 'react'
import { ActionType, BlobType, EditorDispatchContext, ShowcaseContext } from '../../../../context/editorContext'
import { GalleryShowcase } from '../../../../gql/scalars'
import '../styles/galleryBlock.css'

type GalleryBlockProps = {
  showcase: (GalleryShowcase & ShowcaseContext) | null | undefined
  keyProp: number
  tab: string
}

export function GalleryBlock(props: GalleryBlockProps) {
  const editorDispatch = useContext(EditorDispatchContext)

  const imageCompressionOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }

  async function imageUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files != null && e.target.files[0].type.includes('image')) {
      let blob = e.target.files[0]
      const compressedFile = await imageCompression(blob, imageCompressionOptions)
      let output = URL.createObjectURL(compressedFile)
      let newBlob: BlobType = {
        blob: compressedFile,
        imagePosition: props.showcase?.images?.length ? props.showcase?.images.length : 0,
        itemKey: undefined,
      }

      const showcase = props.showcase

      if (showcase) {
        console.log(showcase)
        const showcaseUpdate = Object.assign({}, showcase, {
          images: showcase && showcase.images ? [...showcase.images, output] : [output],
          blobs: showcase.blobs ? [...showcase.blobs, newBlob] : [newBlob],
        })

        console.log(showcaseUpdate)

        editorDispatch({
          type: ActionType.secondLevelUpdate,
          payload: showcaseUpdate,
          firstLevelKey: props.tab,
          secondLevelKey: props.keyProp,
        })
      }
      e.target.value = ''
    }

    return
  }

  function removeImage(position: number) {
    const showcase = props.showcase

    if (showcase && showcase && showcase.images) {
      console.log(showcase)
      const showcaseUpdate = Object.assign({}, showcase, {
        images: [...showcase.images.slice(0, position), ...showcase.images.slice(position + 1)],

        blobs:
          showcase.blobs &&
          showcase.blobs
            .filter(item => item.imagePosition !== position)
            .map(item =>
              item.imagePosition && item.imagePosition > position
                ? { ...item, imagePosition: item.imagePosition - 1 }
                : { ...item }
            ),
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }

  return (
    <>
      <input
        id={`Gallery-Upload-Input-${props.tab}-${props.keyProp}`}
        type="file"
        accept="image/*"
        onChange={e => imageUpload(e)}
        hidden
      />
      <div className="Profile-Custom-Block">
        <div className="Gallery-Showcase-Container-Inner" id="Gallery-Showcase">
          <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={0.5}>
            {props.showcase &&
              props.showcase?.images!.map((item, key) => (
                <div className="Gallery-Item" key={key}>
                  <div className="Delete-Gallery-Item-Icon" onClick={() => removeImage(key)}>
                    <CloseSharpIcon />
                  </div>
                  <div className="Gallery-Showcase-Item-Film"></div>
                  <img className="Gallery-Showcase-Item-Image-Editor" src={item ? item : ''} alt="Cannot load Image" />
                </div>
              ))}
            <div>
              <div
                className="Gallery-Editor-Item-Add"
                onClick={() => document.getElementById(`Gallery-Upload-Input-${props.tab}-${props.keyProp}`)?.click()}>
                <AddIcon />
              </div>
            </div>
          </Masonry>
        </div>
      </div>
    </>
  )
}
