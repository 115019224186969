import { useState, useEffect } from 'react'
import './tabNameModal.css'
import { ClickAwayListener } from '../../../modal/clickAwayListener'

interface TabModalProps {
  modalPosition?: any
  modalFunction?: any
  closeModal?: any
  preExistingName?: string | null | undefined
}

export function TabNameModal(props: TabModalProps) {
  const [tabName, setTabName] = useState<string>('')

  useEffect(() => {
    if (props.preExistingName) {
      setTabName(props.preExistingName)
    }
    // eslint-disable-next-line
  }, [])

  let height: number = window.screen.height
  let width: number = window.screen.height
  let modalTop =
    height / 2 > props.modalPosition?.height
      ? `${props.modalPosition?.height + 20}px`
      : `${props.modalPosition?.height - 320}px`

  let modalLeft =
    width / 2 > props.modalPosition?.width ? `${props.modalPosition?.width}px` : `${props.modalPosition?.width - 320}px`

  return (
    <>
      <ClickAwayListener onClickAway={() => props.closeModal()} id="Tab-Name-Modal">
        <div className={'Modal-Container-Alt'}>
          <div
            className="Tab-Name-Modal-Container"
            id="Modal-Inner-Tab"
            style={{
              position: 'fixed',
              top: modalTop,
              left: modalLeft,
            }}>
            <>
              <div className="Tab-Name-Input-Bar">
                <input
                  type="text"
                  placeholder="Add a tab name"
                  value={tabName}
                  onChange={e => setTabName(e.target.value)}
                  maxLength={12}
                />
              </div>
            </>
            <div className="Quick-Options-Button-Container">
              <div className="Quick-Options-Buttons">
                <button className="Quick-Options-Button-1" onClick={props.closeModal}>
                  Cancel
                </button>
                <button className="Quick-Options-Button-2" onClick={() => props.modalFunction(tabName)}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}
