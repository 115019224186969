import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'

import './styles/navBackButtonHeader.css'

interface NavBackButtonHeaderProps {
  title: string
}

export function NavBackButtonHeader({ title }: NavBackButtonHeaderProps) {
  const navigate = useNavigate()

  return (
    <div className="Nav-Back-Button-Header-Top">
      <div
        className="Nav-Back-Button-Header-Back-Button"
        onClick={() => {
          navigate(-1)
        }}>
        <ArrowBackIcon />
      </div>
      <span className="Nav-Back-Button-Header-Title">{title}</span>
    </div>
  )
}
