import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import GifBoxOutlinedIcon from '@mui/icons-material/GifBoxOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import imageCompression from 'browser-image-compression'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import sanitizeHTML from 'sanitize-html'
import { CardinalStateContext } from '../../context/cardinal'
import { useCreateMessageMutation, User, Messages_Insert_Input } from '../../gql/generated/graphql'
import { sanitizeText, saveSelection, restoreSelection } from '../../utils/lexical/helper/lexicalHelper'
import { uploadToStorage } from '../../utils/cloudflareR2'
import { EmojiPicker } from '../feed/modal/emojiPicker'
import { GifModal } from '../feed/modal/gifModal'
import { CropImageModal } from '../feed/modal/cropImageModal'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  CLEAR_EDITOR_COMMAND,
  KEY_DOWN_COMMAND,
  PASTE_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  BLUR_COMMAND,
} from 'lexical'
import Editor from '../../utils/lexical/Editor'
import './messageInput.css'
import { imageCompressionOptions } from '../../utils/constants'

interface MessageInputProps {
  chat_id: string
  user: User
}

export function MessageInput({ chat_id, user }: MessageInputProps) {
  const [createMessage] = useCreateMessageMutation()
  const [editor] = useLexicalComposerContext()

  const state = useContext(CardinalStateContext)
  const [messageText, setMessageText] = useState<string>('')
  const [postImages, setPostImages] = useState<string[]>([])
  const [imagesOriginal, setImagesOriginal] = useState<string[]>([])
  const [postVideo, setPostVideo] = useState<string>()
  const [cropModal, setCropModal] = useState<boolean>(false)
  const [gifModal, setGifModal] = useState<boolean>(false)
  const [emojiModal, setEmojiModal] = useState<boolean>(false)
  const [modalPosition, setModalPosition] = useState<object>({})
  const [cropImageKey, setCropImageKey] = useState<number>(0)
  const [imagesBlobs, setImagesBlobs] = useState<File[]>([])
  const [videoBlob, setVideoBlob] = useState<File[]>([])
  const [selection, setSelection] = useState()
  const [disablePostButton, setDisablePostButton] = useState<boolean>(false)

  function clearEditor() {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
  }

  useEffect(() => {
    return editor.registerCommand<KeyboardEvent>(
      KEY_DOWN_COMMAND,
      (event: KeyboardEvent) => {
        let key = event.code || event.key
        let inputEl = document.getElementById('Message-Chatbox-Input')

        if (inputEl?.getAttribute('aria-controls')) {
          return false
        }
        if ((key === 'Enter' || key === 'NumpadEnter') && event.shiftKey) {
          return false
        } else if (key === 'Enter' || key === 'NumpadEnter') {
          postMessage()
          event.preventDefault()
          return true
        }
        return false
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor, messageText, postImages, postVideo, disablePostButton])

  const removeBlurListener = editor.registerCommand<FocusEvent>(
    BLUR_COMMAND,
    () => {
      setSelection(saveSelection())
      return false
    },
    COMMAND_PRIORITY_EDITOR
  )

  const removePasteListener = editor.registerCommand<ClipboardEvent>(
    PASTE_COMMAND,
    (event: ClipboardEvent) => {
      event.preventDefault()
      if (event?.clipboardData?.files[0] != null) {
        pasteUpload(event.clipboardData?.files[0])
        return true
      }
      return false
    },
    COMMAND_PRIORITY_EDITOR
  )

  useEffect(() => {
    return () => {
      removePasteListener()
      removeBlurListener()
    }
  }, [])

  function addEmojiLexical(selectedOption: any) {
    document.getElementById('Message-Chatbox-Input')?.focus()

    restoreSelection(selection, 'Message-Chatbox-Input')
    editor.update(() => {
      const selection = $getSelection()

      if (!$isRangeSelection(selection) || selectedOption == null) {
        return
      }

      selection.insertNodes([
        $createTextNode(
          selectedOption.native
            ? selectedOption.native
            : selectedOption.shortcodes
            ? selectedOption.shortcodes
            : selectedOption.emoji
        ),
      ])
    })
    if (state.isMobileView) {
      setEmojiModal(false)
    }
  }

  async function postMessage() {
    const sanitizeConf = {
      allowedTags: ['img'],
    }
    if (
      disablePostButton ||
      (imagesBlobs.length === 0 && videoBlob.length === 0 && sanitizeHTML(messageText, sanitizeConf).length === 0)
    ) {
      return
    }

    setDisablePostButton(true)
    let imageLinks = imagesBlobs ? await uploadToStorage(imagesBlobs) : null
    let videoLink = videoBlob ? await uploadToStorage(videoBlob) : null
    let sanitizedText = sanitizeText(messageText)

    if (chat_id && user) {
      const input: Messages_Insert_Input = {
        handle_from: user.handle,
        text: sanitizedText,
        message_images: imageLinks,
        message_video: videoLink![0],
        message_group_id: chat_id,
      }

      await createMessage({ variables: { object: input, groupId: chat_id, currentUserHandle: user.handle } })

      setMessageText('')
      clearEditor()
      setPostVideo('')
      setPostImages([])
      setImagesOriginal([])
      setImagesBlobs([])
      setVideoBlob([])
      setDisablePostButton(false)
    }
  }

  return (
    <>
      <input
        id="Message-Chatbox-Input-Upload"
        type="file"
        accept={postImages.length > 0 ? 'image/*' : 'image/* video/*'}
        onChange={e => postUpload(e)}
        hidden
      />
      {state.user && (
        <>
          {emojiModal && (
            <EmojiPicker closeModal={() => closeModal()} addEmoji={addEmojiLexical} modalPosition={modalPosition} />
          )}
          {cropModal && (
            <CropImageModal
              closeModal={() => closeModal()}
              updateFunction={updateCroppedImage}
              imageKey={cropImageKey}
              image={imagesOriginal[cropImageKey]}
            />
          )}
          {gifModal && (
            <GifModal closeModal={() => closeModal()} modalFunction={addGif} modalPosition={modalPosition} />
          )}
          <div className="Message-Chatbox-Input-Container Active-Scrollbar">
            <div className="Message-Chatbox-Input-Container-Top">
              {(postVideo || postImages.length > 0) && (
                <div className="Create-Post-Extra-Content">
                  {postVideo && (
                    <div className="Create-Post-Extra-Video-Container">
                      <DeleteIcon className="Create-Post-Upload-Close-Icon" onClick={() => removeVideoUpload()} />
                      <video controls loop playsInline autoPlay muted className="Create-Post-Video" src={postVideo} />
                    </div>
                  )}

                  {postImages.map((image, key) => (
                    <div className="Create-Message-Image-Container" key={key}>
                      <DeleteIcon className="Create-Message-Upload-Close-Icon" onClick={() => removeImageUpload(key)} />
                      <EditIcon className="Create-Message-Upload-Edit-Icon" onClick={() => cropImage(key)} />

                      <img className="Create-Message-Image" src={image ? image : ''} alt="" />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="Message-Chatbox-Input-Container-Bottom">
              <div className="Message-Chatbox-Input-Container-Inner">
                <Editor
                  className="Message-Chatbox-Input"
                  id="Message-Chatbox-Input"
                  editorOnChange={change => setMessageText(change)}
                />
              </div>
              <div className="Message-Chatbox-Input-Options">
                <div
                  className="Message-Chatbox-Input-Option"
                  onClick={e => {
                    openEmojiModal(e)
                  }}>
                  <EmojiEmotionsOutlinedIcon style={{ fontSize: '2.5rem' }} />
                </div>
                <div
                  className="Message-Chatbox-Input-Option"
                  onClick={e => {
                    if (postVideo || postImages.length > 0) {
                      return
                    } else openGifModal(e)
                  }}>
                  <GifBoxOutlinedIcon style={{ fontSize: '2.5rem' }} />
                </div>
                <div
                  className="Message-Chatbox-Input-Option"
                  onClick={() => {
                    if (postVideo || postImages.length > 3) {
                      return
                    } else document.getElementById('Message-Chatbox-Input-Upload')?.click()
                  }}>
                  <ImageOutlinedIcon style={{ fontSize: '2.5rem' }} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )

  async function pasteUpload(file: File) {
    if (file != null && postImages.length < 4 && !postVideo) {
      if (file.type.includes('image')) {
        let blob = file
        const compressedFile = await imageCompression(blob, imageCompressionOptions)
        let output = URL.createObjectURL(compressedFile)
        setImagesBlobs(current => [...current, compressedFile])
        setPostImages(current => [...current, output])
        setImagesOriginal(current => [...current, output])
      } else {
        let blob = file
        let output = URL.createObjectURL(file)
        setVideoBlob(current => [...current, blob])
        setPostVideo(output)
      }
    }
    return
  }

  async function postUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files != null && postImages.length < 4 && !postVideo) {
      if (e.target.files[0].type.includes('image')) {
        let blob = e.target.files[0]
        const compressedFile = await imageCompression(blob, imageCompressionOptions)
        let output = URL.createObjectURL(compressedFile)
        setImagesBlobs(current => [...current, compressedFile])
        setPostImages(current => [...current, output])
        setImagesOriginal(current => [...current, output])

        e.target.value = ''
      } else {
        let blob = e.target.files[0]
        let output = URL.createObjectURL(e.target.files[0])
        setVideoBlob(current => [...current, blob])
        setPostVideo(output)
        e.target.value = ''
      }
    }
    document.getElementById('Message-Chatbox-Input')?.focus()
    return
  }

  function removeImageUpload(keyToRemove: number) {
    setPostImages(
      postImages.filter(function (item, key) {
        return key !== keyToRemove
      })
    )
    setImagesOriginal(
      imagesOriginal.filter(function (item, key) {
        return key !== keyToRemove
      })
    )
    setImagesBlobs(
      imagesBlobs.filter(function (item, key) {
        return key !== keyToRemove
      })
    )
    document.getElementById('Message-Chatbox-Input')?.focus()
    return
  }

  function removeVideoUpload() {
    setPostVideo(undefined)
    setVideoBlob([])
  }

  async function cropImage(key: number) {
    setCropImageKey(key)
    setCropModal(true)
  }

  function updateCroppedImage(image: string, key: number, blob: File) {
    let targetItem = (item: string, itemKey: number) => {
      if (itemKey === key) {
        item = image
      }
      return item
    }

    let targetBlob = (item: File, itemKey: number) => {
      if (itemKey === key) {
        item = blob
      }
      return item
    }

    setImagesBlobs(imagesBlobs.map(targetBlob))
    setPostImages(postImages.map(targetItem))
    setCropModal(false)
  }

  async function addGif(url: string) {
    if (chat_id && user) {
      const input: Messages_Insert_Input = {
        handle_from: user.handle,
        message_gif: url,
        message_group_id: chat_id,
      }
      await createMessage({ variables: { object: input, groupId: chat_id, currentUserHandle: user.handle } })
    }
  }

  function openGifModal(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    let currentTargetRect: DOMRect = e.currentTarget.getBoundingClientRect()
    setModalPosition({
      height: currentTargetRect.y,
      width: currentTargetRect.x,
    })
    setGifModal(!gifModal)
  }
  function openEmojiModal(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    let currentTargetRect: DOMRect = e.currentTarget.getBoundingClientRect()
    setModalPosition({
      height: currentTargetRect.y,
      width: currentTargetRect.x,
    })
    setEmojiModal(!emojiModal)
  }
  function closeModal() {
    setCropModal(false)
    setGifModal(false)
    setEmojiModal(false)
  }
}
