import { useContext, useEffect, useRef, useState } from 'react'
import Twemoji from 'react-twemoji'
import { useDebouncedCallback } from 'use-debounce'
import { ActionType, CardinalDispatchContext, CardinalStateContext } from '../../../context/cardinal'
import { EditorStateContext } from '../../../context/editorContext'
import { ProfileAvatar } from './profileAvatar'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { MiniProfileDefaultBackground } from './miniProfileDefaultBackground'
import {
  useUnFollowMutation,
  useFollowMutation,
  User,
  useGetUserMiniProfileLazyQuery,
} from '../../../gql/generated/graphql'
import './styles/miniProfile.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { numberToLetterNotation } from '../../../utils/converterUtils'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined'
import { getProviderIcon } from '../../../utils/statusUtils'
import { VerifiedCheck } from './verifiedCheck'
import { sanitizeHtmlConfLessDataAttribute } from '../../../utils/constants'
import sanitizeHTML from 'sanitize-html'

interface MiniProfileProps {
  editing?: boolean
}

export function MiniProfile(props: MiniProfileProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const [unFollowUser] = useUnFollowMutation()
  const [followUser] = useFollowMutation()
  const [following, setFollowing] = useState(false)
  const [currentMiniProfile, setCurrentMiniProfile] = useState<User | undefined>()
  const { user, isMobileView } = useContext(CardinalStateContext)
  const { miniProfileBackground } = useContext(EditorStateContext)
  let miniProfileBackgroundSrc = props.editing ? miniProfileBackground : currentMiniProfile?.mini_profile_background
  const userRef = useRef(user)
  userRef.current = user
  const [queryHandle, setQueryHandle] = useState<string>('')
  const [getUserMiniProfile] = useGetUserMiniProfileLazyQuery()
  const [cursorOnElement, setCursorOnElement] = useState<boolean>(false)
  const [cursorOnMiniProfile, setCursorOnMiniProfile] = useState<boolean>(false)

  useEffect(() => {
    if (!props.editing) document.addEventListener('mouseover', miniProfileMentionManager, true)
    return () => {
      document.removeEventListener('mouseover', miniProfileMentionManager, true)
    }
  }, [])

  useEffect(() => {
    setCurrentMiniProfile(undefined)
    if (queryHandle.length > 0 && !isMobileView) {
      getMiniProfileData(queryHandle)
    }
  }, [queryHandle])

  useEffect(() => {
    if (!props.editing && !isMobileView) checkCursor()
    // eslint-disable-next-line
  }, [cursorOnElement, cursorOnMiniProfile])

  useEffect(() => {
    CloseMiniProfile()
  }, [location])

  function CloseMiniProfile() {
    let miniProfile: HTMLElement | null = document.getElementById('Mini-Profile')
    if (miniProfile) miniProfile.style.display = 'none'
    setCursorOnElement(false)
    setCursorOnMiniProfile(false)
  }

  function MiniProfileManager(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!props.editing) {
      const isMouseEnter = e.type === 'mouseenter' ? true : false
      setCursorOnMiniProfile(isMouseEnter)
    }
  }

  const checkCursor = useDebouncedCallback(() => {
    let miniProfile: HTMLElement | null = document.getElementById('Mini-Profile')
    if ((cursorOnMiniProfile || cursorOnElement) && miniProfile) {
      if (miniProfile.style.display !== 'block') miniProfile.style.display = 'block'
    } else if (!cursorOnMiniProfile && !cursorOnElement && miniProfile) {
      miniProfile.style.display = 'none'
    }
  }, 300)

  const getMiniProfileData = useDebouncedCallback(async queryValue => {
    let data = await getUserMiniProfile({
      variables: {
        handle: queryValue,
        currentUserHandle: userRef.current ? userRef.current?.handle : '',
      },
      fetchPolicy: 'cache-and-network',
    })
    setCurrentMiniProfile(data.data?.user_by_pk as User)
  }, 300)

  async function miniProfileMentionManager(event: MouseEvent) {
    let eventTarget: any = event.target

    if (
      eventTarget.getAttribute('data-seiso-miniprofile') &&
      eventTarget.getAttribute('data-seiso-miniprofile').length > 0
    ) {
      let miniProfile: HTMLElement | null = document.getElementById('Mini-Profile')
      let width: number = window.screen.width
      let height: number = window.screen.height
      let currentTargetRect: DOMRect = eventTarget.getBoundingClientRect()
      let posLeft = 0,
        posLeftAlt = 350,
        posTop = 10,
        posTopAlt = 260

      if (eventTarget.getAttribute('class') && eventTarget.getAttribute('class').includes('Avatar')) {
        posLeft = 50
        posLeftAlt = 350
        posTop = 10
        posTopAlt = 200
      }

      setQueryHandle(eventTarget.getAttribute('data-seiso-miniprofile'))

      setCursorOnElement(true)

      if (miniProfile) {
        if (width / 2 > currentTargetRect.x) {
          miniProfile.style.left = `${currentTargetRect.x + posLeft + 'px'}`
        } else {
          miniProfile.style.left = `${currentTargetRect.x - posLeftAlt + 'px'}`
        }

        if (height / 2 > currentTargetRect.y) {
          miniProfile.style.top = `${currentTargetRect.y + posTop + 'px'}`
        } else {
          miniProfile.style.top = `${currentTargetRect.y - posTopAlt + 'px'}`
        }
      }
    } else {
      setCursorOnElement(false)
    }
  }

  useEffect(() => {
    setFollowing(currentMiniProfile?.followers && currentMiniProfile.followers.length > 0 ? true : false)
  }, [currentMiniProfile])

  async function follow() {
    if (currentMiniProfile?.handle && user) {
      await followUser({
        variables: {
          currentUserHandle: user.handle,
          handleToFollow: currentMiniProfile.handle,
        },
      })
      setFollowing(true)
    }
  }
  async function unFollow() {
    if (currentMiniProfile?.handle && user) {
      await unFollowUser({
        variables: {
          currentUserHandle: user.handle,
          handleToUnFollow: currentMiniProfile.handle,
        },
      })
      setFollowing(false)
    }
  }

  return (
    <>
      <div
        className={`${props.editing ? 'Mini-Profile-Wrapper-Editing' : 'Mini-Profile-Wrapper'} ${
          currentMiniProfile ? 'Mini-Profile-Wrapper-Visible' : ''
        }`}
        id="Mini-Profile"
        onMouseEnter={e => MiniProfileManager(e)}
        onMouseLeave={e => MiniProfileManager(e)}>
        <div className="Mini-Profile-Inner">
          <div className="Mini-Profile-Container">
            <div className="Mini-Profile-Background">
              <MiniProfileDefaultBackground />
              {miniProfileBackgroundSrc && miniProfileBackgroundSrc?.length > 0 && (
                <video
                  className="Mini-Profile-Background-Video"
                  playsInline
                  autoPlay
                  muted
                  loop
                  disablePictureInPicture
                  src={miniProfileBackgroundSrc}
                />
              )}
            </div>
            <div className="Mini-Profile-User-Section">
              <div className="Mini-Profile-Avatar-Container">
                <ProfileAvatar disableMiniProfile size="Medium" user={props.editing ? user : currentMiniProfile} />
              </div>
              <div className="Mini-Profile-User-Name">
                <span className="Mini-Profile-Displayname">
                  <Twemoji options={{ className: 'Mini-Profile-Displayname-Emoji' }}>
                    {props.editing ? user?.username : currentMiniProfile?.username}
                  </Twemoji>
                  <div>{currentMiniProfile?.is_verified && <VerifiedCheck />}</div>
                </span>
                <span className="Mini-Profile-Handle">
                  {' '}
                  @{props.editing ? user?.handle : currentMiniProfile?.handle}
                </span>
              </div>
            </div>
            <div className="Mini-Profile-Status-Section">
              <div className="Mini-Profile-Status-Details">
                <span className="Mini-Profile-Status-Details-Text">
                  <Twemoji options={{ className: 'twemoji' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentMiniProfile?.status_message
                          ? sanitizeHTML(currentMiniProfile?.status_message, sanitizeHtmlConfLessDataAttribute)
                          : '',
                      }}
                    />
                  </Twemoji>
                </span>
                <div className="Mini-Profile-Stats">
                  <span>
                    <div>Posts:</div>
                    {currentMiniProfile?.posts_aggregate && currentMiniProfile?.posts_aggregate.aggregate?.count
                      ? numberToLetterNotation(currentMiniProfile?.posts_aggregate.aggregate?.count)
                      : 0}
                  </span>
                  <span>
                    <div>Following:</div>
                    {currentMiniProfile?.following_aggregate && currentMiniProfile?.following_aggregate.aggregate?.count
                      ? numberToLetterNotation(currentMiniProfile?.following_aggregate.aggregate?.count)
                      : 0}
                  </span>
                  <span>
                    <div>Followers:</div>
                    {currentMiniProfile?.followers_aggregate && currentMiniProfile?.followers_aggregate.aggregate?.count
                      ? numberToLetterNotation(currentMiniProfile?.followers_aggregate.aggregate?.count)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="Mini-Profile-Details-Section">
              <div className="Mini-Profile-Socials">
                {currentMiniProfile?.social_links?.slice(0, 5).map((socialLink: any, key: number) => (
                  <a
                    href={socialLink?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="Social-Icon-Container Mini-Profile-Social-Icon-Container"
                    key={key}>
                    <div className={`Social-Icon ${socialLink?.provider}-Icon`}>
                      <img src={getProviderIcon(socialLink?.provider) ?? socialLink.icon} alt="" />
                    </div>
                  </a>
                ))}
                <div
                  className={` Mini-Profile-Follow-Button ${following && 'Profile-Follow-Button-Active'}`}
                  onClick={e => {
                    if (currentMiniProfile?.handle === user?.handle) {
                      navigate('/settings/general')
                      CloseMiniProfile()
                    } else if (!following) follow()
                    else if (following) unFollow()
                  }}>
                  {currentMiniProfile?.handle === user?.handle ? (
                    'Edit Profile'
                  ) : following ? (
                    <>
                      <FavoriteOutlinedIcon className="Full-Heart" />
                      <HeartBrokenOutlinedIcon className="Break-Heart" />
                    </>
                  ) : (
                    'Follow'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
