import './loading.css'
import { loadingQuotes } from './loadingQuotes'

interface LoadingModalProps {
  header?: string
  subheader?: string
}

export function LoadingModal({ header, subheader }: LoadingModalProps) {
  const quoteIndex = Math.floor(Math.random() * loadingQuotes.length)

  return (
    <div className="Loading-Modal-Container-BG">
      <div className="Loading-Modal-Container">
        <div className="Loading-Cubes">
          <div className="Loading-Cube Loading-Cube-1"></div>
          <div className="Loading-Cube Loading-Cube-2"></div>
        </div>
        <h2>{header ?? 'Loading..'}</h2>
        <h3>{subheader ?? loadingQuotes[quoteIndex]}</h3>
      </div>
    </div>
  )
}
