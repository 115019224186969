import VerifiedIcon from '@mui/icons-material/Verified'
import './styles/verifiedCheck.css'

export function VerifiedCheck() {
  return (
    <span className="Verified-User-Check">
      <VerifiedIcon />
    </span>
  )
}
