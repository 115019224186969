import { BaseSyntheticEvent, useContext, useEffect, useState } from 'react'
import {
  AuthenticationAction,
  AuthenticationFlowState,
  AuthenticationFormField,
  IAuthenticationAction,
  IAuthenticationFormData,
  IInputValidator,
  ITabFormField,
} from './auth_interfaces'
import { AuthFormField } from './authFormField'
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { AuthContextActionType, AuthDispatchContext } from '../../context/authContext'
import { LoadingModal } from '../loading/loadingModal'

export interface AuthBodyProps {
  formFields: ITabFormField[]
  submitAction?: IAuthenticationAction
  inputValidators?: IInputValidator[]
  secondaryActions?: IAuthenticationAction[]
  headerText?: string
  footerActions?: IAuthenticationAction[]
}

export function AuthBody({
  formFields,
  submitAction,
  inputValidators,
  secondaryActions,
  headerText,
  footerActions,
}: AuthBodyProps) {
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [username, setUsername] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const dispatch = useContext(AuthDispatchContext)

  useEffect(() => {
    if (
      submitAction?.action === AuthenticationAction.SignUp ||
      submitAction?.action === AuthenticationAction.ConfirmResetPassword
    ) {
      const passwordField = document.getElementById(AuthenticationFormField.Password) as HTMLInputElement
      const confirmPasswordField = document.getElementById(AuthenticationFormField.ConfirmPassword) as HTMLInputElement

      // TODO: Make password validation for specific issues.
      function validatePassword() {
        if (passwordField.value !== confirmPasswordField.value) {
          setError('Password does not match')
          setDisabled(true)
        } else {
          setError(undefined)
          setDisabled(false)
        }
      }

      if (passwordField && confirmPasswordField) {
        passwordField.onchange = validatePassword
        confirmPasswordField.onkeyup = validatePassword
      }
    } else {
      setError(undefined)
      setDisabled(false)
    }
  }, [submitAction?.action])

  function hasInputErrors(formData: IAuthenticationFormData) {
    if (inputValidators) {
      for (const validator of inputValidators) {
        if (formData[validator.formFieldName]?.match(validator.regexExpression)) {
          setError(validator.errorMessage)
          return true
        }
      }
    }

    setLoading(true)
    return false
  }

  function handleOnAction(event: BaseSyntheticEvent, action?: AuthenticationAction) {
    event.preventDefault()
    setError(undefined)

    const formData: IAuthenticationFormData = {}
    if (event.type === 'submit') {
      new FormData(event.currentTarget).forEach((value, key) => {
        formData[key as AuthenticationFormField] = value as string
      })
    }

    switch (action) {
      case AuthenticationAction.SignUp:
        if (formData.username && formData.email && formData.password && formData.confirmPassword) {
          if (hasInputErrors(formData)) {
            break
          }

          //TODO: Add a profanity filter

          const uuid = crypto.randomUUID()
          Auth.signUp({
            username: uuid,
            password: formData.password,
            attributes: {
              email: formData.email,
              name: formData.username,
            },
            autoSignIn: {
              enabled: true,
            },
          })
            .then(() => {
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.ConfirmingSignUp,
              })
              setUsername(uuid)
            })
            .catch(e => {
              if (e.message) {
                // Error message is defined in the lambda function.
                // We need to split the cognito message from our message using ':'
                const message = (e.message as string).split(':')
                const displayMessage = message.length > 1 ? message[1] : undefined
                if (displayMessage) {
                  setError(displayMessage)
                }
              }
            })
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ConfirmSignUp:
        if (formData.confirmationCode && username) {
          if (hasInputErrors(formData)) {
            break
          }

          Auth.confirmSignUp(username, formData.confirmationCode, { forceAliasCreation: false })
            .then(() => {
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.SuccessConfirmation,
              })
              if (password) {
                Auth.signIn(username, password).then((user: CognitoUser) => {
                  setPassword(undefined)
                  setUsername(undefined)
                  dispatch({
                    type: AuthContextActionType.SetCognitoUser,
                    payload: user,
                  })
                })
              }
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.SendSignUpConfirmationCode:
        if (username) {
          setLoading(true)
          Auth.resendSignUp(username)
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.SignIn:
        if (formData.username && formData.password) {
          if (hasInputErrors(formData)) {
            break
          }

          Auth.signIn(formData.username, formData.password)
            .catch(e => {
              if (e.message) {
                // Error message is defined in the lambda function.
                // We need to split the cognito message from our message using ':'
                const message = (e.message as string).split(':')

                // Check if the error message contains the correct username
                // This means the user is not confirmed but left the confirmation flow prematurly.
                const correctUsername = message.length > 1 ? message[1].replace('.', '') : undefined
                if (correctUsername) {
                  dispatch({
                    type: AuthContextActionType.SetFlowState,
                    payload: AuthenticationFlowState.ConfirmingSignUp,
                  })
                  setUsername(correctUsername)
                  setPassword(formData.password)
                  Auth.resendSignUp(correctUsername).catch(e => setError(e.message))
                } else {
                  setError(e.message)
                }
              }
            })
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ResetPassword:
        dispatch({
          type: AuthContextActionType.SetFlowState,
          payload: AuthenticationFlowState.RequestingResetPassword,
        })
        break
      case AuthenticationAction.SendResetPasswordCode:
        const currentEmail = formData.email ?? email
        if (currentEmail) {
          if (hasInputErrors(formData)) {
            break
          }

          Auth.forgotPassword(currentEmail)
            .then(() => {
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.ConfirmingResetPassword,
              })
              setEmail(currentEmail)
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ConfirmResetPassword:
        if (email && formData.confirmationCode && formData.password && formData.confirmPassword) {
          if (hasInputErrors(formData)) {
            break
          }

          Auth.forgotPasswordSubmit(email, formData.confirmationCode, formData.password)
            .then(() =>
              dispatch({
                type: AuthContextActionType.SetFlowState,
                payload: AuthenticationFlowState.SuccessConfirmation,
              })
            )
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.GoToSignIn:
        dispatch({
          type: AuthContextActionType.SetFlowState,
          payload: AuthenticationFlowState.None,
        })
        break
      default:
        break
    }
  }

  return (
    <div>
      <div>
        {loading && <LoadingModal header="Loading.." />}
        <form onSubmit={e => handleOnAction(e, submitAction?.action)}>
          {headerText && <h1>{headerText}</h1>}
          {formFields.map((field, key) => (
            <AuthFormField key={`${key}-${field.formFieldName}`} {...field} />
          ))}
          {error && <div className="Auth-Body-Error">{error}</div>}
          {secondaryActions?.map((secondaryAction, key) => (
            <div
              className="Auth-Body-Secondary-Text"
              key={`${key}-${secondaryAction.displayText}`}
              onClick={e => handleOnAction(e, secondaryAction.action)}>
              {secondaryAction.displayText}
            </div>
          ))}
          {submitAction && <input type="submit" value={submitAction.displayText} disabled={disabled} />}

          {footerActions &&
            footerActions?.map((footerAction, key) => (
              <div
                className="Auth-Body-Footer-Text"
                key={`${key}-${footerAction.displayText}`}
                onClick={e => handleOnAction(e, footerAction.action)}>
                {footerAction.displayText}
              </div>
            ))}
        </form>
      </div>
    </div>
  )
}
