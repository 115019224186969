import { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CardinalStateContext } from '../context/cardinal'
import './styles/navProfileMenu.css'
import './styles/navQuickOptionsMenu.css'
import { ClickAwayListener } from '../components/modal/clickAwayListener'
import { Auth } from 'aws-amplify'

interface NavProfileMenuProps {
  closeMenuFunction: () => void
}

export function NavProfileMenu({ closeMenuFunction }: NavProfileMenuProps) {
  const location = useLocation()
  const globalState = useContext(CardinalStateContext)

  return (
    <>
      {/* <div className="Modal-Block-Interaction-BG" /> */}
      <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Nav-Profile-Menu">
        <div className="Nav-Profile-Menu">
          <div className="Nav-Profile-Menu-Options">
            <NavLink
              to={`/profile/${globalState.user?.handle}`}
              className="Nav-Profile-Menu-Option"
              onClick={() => closeMenuFunction()}>
              View My Profile ({globalState.user?.handle})
            </NavLink>
            {globalState.isMobileView && (
              <>
                <NavLink
                  to={`/notifications/all`}
                  className="Nav-Notification-Menu-Option"
                  onClick={() => closeMenuFunction()}>
                  Notifications
                </NavLink>
                <NavLink to={`/messages`} className="Nav-Notification-Menu-Option" onClick={() => closeMenuFunction()}>
                  Messages
                </NavLink>
              </>
            )}

            <NavLink
              to={'/status'}
              state={{ background: location }}
              className="Nav-Profile-Menu-Option"
              onClick={() => closeMenuFunction()}>
              Set Status
            </NavLink>
            <NavLink to="/bookmarks" className="Nav-Profile-Menu-Option" onClick={() => closeMenuFunction()}>
              Bookmarks
            </NavLink>
            <NavLink to="/settings/general" className="Nav-Profile-Menu-Option" onClick={() => closeMenuFunction()}>
              Settings
            </NavLink>
            <div
              className="Nav-Profile-Menu-Option Nav-Profile-Menu-Option-Logout"
              onClick={() => {
                closeMenuFunction()
                Auth.signOut()
              }}>
              Logout
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}
