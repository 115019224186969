import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

const ACCOUNT_ID = '705e48ec71574f320f940b69ce40eae5'
const ACCESS_KEY_ID = '85751ede173c64cc1db8197025dc6b73'
const SECRET_ACCESS_KEY = 'ca2f4936b51d0fa3d3204721572a3ac52eb57c6397c68669172cb4982868dce2'

const S3 = new S3Client({
  region: 'auto',
  endpoint: `https://${ACCOUNT_ID}.r2.cloudflarestorage.com`,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
})

export const uploadToStorage = async (blob: File[]) => {
  try {
    let links: string[] = []
    for (let i = 0; i < blob.length; i++) {
      let filetype = blob[i].type.split('/')
      const filename = `${crypto.randomUUID()}.${filetype[1]}`
      await S3.send(new PutObjectCommand({ Bucket: 'sayso', Body: blob[i], Key: filename, ContentType: filetype[1] }))
      links.push('https://saysocdn.com/' + filename)
    }
    return links
  } catch (err) {
    console.log(err)
  }
}
