import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { $generateNodesFromDOM } from '@lexical/html'
import { ChangeEvent, ReactComponentElement, useContext, useEffect } from 'react'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import Editor from '../../../utils/lexical/Editor'
import SeisoNodes from '../../../utils/lexical/nodes/SeisoNodes'
import SeisoEditorTheme from '../../../utils/lexical/themes/SeisoEditorTheme'
import './styles/generalEditor.css'
import { LoadInitialContent } from '../../../utils/lexical/initialContent/initialContent'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, $isDecoratorNode, $isElementNode } from 'lexical'

export function GeneralEditor() {
  const state = useContext(EditorStateContext)
  // console.log(SeisoNodes)
  const initialConfig = {
    namespace: 'Seiso-Status-Input',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {/* <LoadInitialContent initialContent={state.profileDesc} /> */}
      <GeneralEditorInner />
    </LexicalComposer>
  )
}

export function GeneralEditorInner() {
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(state.profileDesc, 'text/html')

      const nodes = $generateNodesFromDOM(editor, dom)

      nodes.forEach(n => {
        if ($isElementNode(n) || $isDecoratorNode(n)) {
          $getRoot().append(n)
        }
      })
      $getRoot().getFirstDescendant()?.remove()
    })
  }, [])

  return (
    <>
      <h1 className="Tab-Header-Mobile">GENERAL</h1>
      <div className="General-Editor-Container">
        <h1>GENERAL</h1>
        <h2>DISPLAY NAME</h2>
        <input
          value={state.displayname}
          onChange={onDisplaynameChange}
          className="General-Editor-Textarea"
          placeholder="Enter a display name"
          maxLength={32}
        />
        <h2>PROFILE STATUS TEXT</h2>
        {/* <textarea
          value={state.profileDesc}
          onChange={onChangeDescription}
          id="General-Editor-Profile-Description-Input"
          className="General-Editor-Textarea"
          placeholder="Well... What are you doin?"
          maxLength={270}
        /> */}
        <Editor
          className="General-Editor-Textarea"
          id="Message-Chatbox-Input"
          editorOnChange={change => onChangeDescriptionAlt(change)}
        />
      </div>
    </>
  )

  function onDisplaynameChange(e: ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: ActionType.setDisplayname,
      payload: e.target.value,
    })
  }

  function onChangeDescription(e: ChangeEvent<HTMLTextAreaElement>) {
    let postInputElement = document.getElementById('General-Editor-Profile-Description-Input')
    if (postInputElement) {
      postInputElement?.setAttribute('style', 'height: auto;')
      postInputElement?.setAttribute('style', 'height:' + postInputElement.scrollHeight + 'px;overflow-y:hidden;')
    }

    dispatch({
      type: ActionType.setProfileDesc,
      payload: e.target.value,
    })
  }
  function onChangeDescriptionAlt(value: string) {
    dispatch({
      type: ActionType.setProfileDesc,
      payload: value,
    })
  }
}
