import { ChangeEvent, useState } from 'react'
import { MOCK_POSTS, MOCK_USERS } from '../constants/tempData'
import { Auth, Storage } from 'aws-amplify'
import { useBatchCreatePostMutation, useCreateUserMutation, useUpdateUserMutation } from '../gql/generated/graphql'
import './styles/devTools.css'
import ContentEditable from 'react-contenteditable'
import Settings from '../utils/lexical/Settings'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import SeisoEditorTheme from '../utils/lexical/themes/SeisoEditorTheme'
import SeisoNodes from '../utils/lexical/nodes/SeisoNodes'
import { SettingsContext, useSettings } from '../utils/lexical/context/SettingsContext'
import Editor from '../utils/lexical/Editor'
import { CustomVideoElement } from '../utils/customVideoElement'
import { LoadingModal } from '../components/loading/loadingModal'
import EmojiPicker, { Categories, EmojiStyle, SuggestionMode, Theme } from '../utils/emojiPicker'

import {
  S3Client,
  ListBucketsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
} from '@aws-sdk/client-s3'
import { SliderToggle } from '../components/uiKit/sliderToggle'

const ACCOUNT_ID = '705e48ec71574f320f940b69ce40eae5'
const ACCESS_KEY_ID = '85751ede173c64cc1db8197025dc6b73'
const SECRET_ACCESS_KEY = 'ca2f4936b51d0fa3d3204721572a3ac52eb57c6397c68669172cb4982868dce2'

export const customEmoji = [
  {
    name: 'Rejis',
    category: 'custom',
    icon: 'https://saysocdn.com/7198b2d5-9cdd-4c3d-add7-e7b2df3b871b.png',
    images: [
      {
        name: 'some_name',
        search: ['key1', 'key2'],
        imageUrl: 'https://saysocdn.com/e4432a46-b9b6-4744-b5d0-457a9c531929.png',
      },
      {
        name: 'yolo123',
        search: ['kek'],
        imageUrl: 'https://saysocdn.com/5b3dffe5-b693-4cb1-bd2a-4622ef8733f8.gif',
      },
    ],
  },
  {
    name: 'BabyJesus',
    category: 'custom',
    icon: 'https://saysocdn.com/e4432a46-b9b6-4744-b5d0-457a9c531929.png',
    images: [
      // {
      //   name: 'some_name',
      //   search: ['key1', 'key2'],
      //   imageUrl: 'https://saysocdn.com/e4432a46-b9b6-4744-b5d0-457a9c531929.png',
      // },
      {
        name: 'yolo',
        search: ['kek'],
        imageUrl: 'https://saysocdn.com/5b3dffe5-b693-4cb1-bd2a-4622ef8733f8.gif',
      },
    ],
  },
  {
    name: 'Ghouldura',
    category: 'custom',
    icon: 'https://project11301826-developer.s3.amazonaws.com/public/b6d588b7-9306-4a82-a6a5-54ca60c7ef63.png',
    images: [
      {
        name: 'fubuki',
        search: ['kek'],
        imageUrl: 'https://saysocdn.com/a4684454-bfa5-499e-a528-ff07c033c7be.gif',
      },
    ],
  },
]

export function DevTools() {
  let loading = false

  const S3 = new S3Client({
    region: 'auto',
    endpoint: `https://${ACCOUNT_ID}.r2.cloudflarestorage.com`,
    credentials: {
      accessKeyId: ACCESS_KEY_ID,
      secretAccessKey: SECRET_ACCESS_KEY,
    },
  })

  const [inputHTML, setInputHTML] = useState('')
  const [toggleButton, setToggleButton] = useState(false)
  const [name, setName] = useState('kurumii')
  const [batchCreatePostMutation] = useBatchCreatePostMutation({
    variables: {
      objects: MOCK_POSTS,
    },
  })
  const [createUserMutation] = useCreateUserMutation()
  const [updateUserMutation] = useUpdateUserMutation()

  async function createMockData() {
    console.log('Creating mock data...')

    MOCK_USERS.forEach(async user => {
      await createUserMutation({ variables: { object: user } })
    })

    await batchCreatePostMutation()
  }

  async function updateMockData() {
    console.log('Updating mock data...')
    MOCK_USERS.forEach(async user => {
      await updateUserMutation({ variables: { handle: user.handle!, object: user } })
    })
  }

  const uploadEmote = async (blob: File[], name: string) => {
    let reponse = await S3.send(new PutObjectCommand({ Bucket: 'sayso', Body: blob[0], Key: 'random.png' }))
    console.log(reponse)
    try {
      let links: string[] = []
      let progressbar = document.getElementById('Progress-Bar')

      for (let i = 0; i < blob.length; i++) {
        let filetype = blob[i].type.split('/')
        const filename = `${name}.${filetype[1]}`
        await Storage.put(filename, blob[i], {
          progressCallback(progress) {
            if (progressbar) {
              progressbar.style.display = 'block'
              progressbar.style.width = `${Math.floor((progress.loaded / progress.total) * 100)}%`
            }
          },
        })
        links.push(filename)
      }
      if (progressbar) {
        progressbar.style.display = 'none'
        progressbar.style.width = `0%`
      }
      return links
    } catch (err) {
      console.log(err)
    }
  }

  async function createEmote(e: ChangeEvent<HTMLInputElement>) {
    if (e?.target?.files![0]) {
      let imageLink = await uploadEmote([e?.target?.files![0]], name)

      console.log(imageLink)

      // if (imageLink) {
      //   const input: CreateCustomEmotesInput = {
      //     url: imageLink[0],
      //     code: `:${name}:`,
      //   }

      //   let response = await createGraphqlEmote(input)
      //   console.log(response)
      // }
    }
  }

  // useEffect(() => {
  //   createIt('Post-Input-Alt')
  // }, [])

  const {
    settings: { emptyEditor },
  } = useSettings()

  const initialConfig = {
    editorState: emptyEditor ? undefined : undefined,
    namespace: 'Seiso',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <div>
      <button className="Devtool-Button" onClick={createMockData}>
        Create Mock Data
      </button>
      <button className="Devtool-Button" onClick={updateMockData}>
        Update Mock Data
      </button>
      <button
        className="Devtool-Button"
        onClick={() => {
          Auth.signOut()
        }}>
        Logout
      </button>
      <input value={name} onChange={e => setName(e.target.value)} />
      <button className="Devtool-Button" onClick={() => document.getElementById('Dev-Upload-Input')?.click()}>
        Upload Emote
      </button>
      {/* <MentionableContentEditable /> */}
      <div style={{ background: 'black', textAlign: 'left' }}>
        <LexicalComposer initialConfig={initialConfig}>
          <Editor editorOnChange={change => null} treeView />
        </LexicalComposer>
      </div>
      <div className="Loading">
        <div className="facespinner">
          <div className="facespinner__eye"></div>
        </div>
      </div>
      {loading && <LoadingModal header="test" subheader="test2" />}
      {/* <EmojiPicker
        onEmojiClick={emojiData => console.log(emojiData)}
        suggestedEmojisMode={SuggestionMode.FREQUENT}
        height={600}
        width="100%"
        theme={Theme.DARK}
        emojiStyle={EmojiStyle.TWITTER}
        categories={[
          {
            name: 'Recently Used',
            category: Categories.SUGGESTED,
          },
          ...customEmoji.sort((a, b) => a.name.localeCompare(b.name)),
          {
            name: 'Smiles & Emotions',
            category: Categories.SMILEYS_PEOPLE,
          },
          {
            name: 'Animals & Nature',
            category: Categories.ANIMALS_NATURE,
          },
          {
            name: 'Food & Drink',
            category: Categories.FOOD_DRINK,
          },
          {
            name: 'Travel & Places',
            category: Categories.TRAVEL_PLACES,
          },
          {
            name: 'Activities',
            category: Categories.ACTIVITIES,
          },
          {
            name: 'Objects',
            category: Categories.OBJECTS,
          },
          {
            name: 'Symbols',
            category: Categories.SYMBOLS,
          },
          {
            name: 'Flags',
            category: Categories.FLAGS,
          },
        ]}
      /> */}
      {/* <CustomVideoElement
        autoPlay
        muted
        loop
        src="https://project11301826-developer.s3.amazonaws.com/public/5e0fd611-a1e8-45b8-ba88-8f828f625caf.mp4"
      /> */}
      {/* <ContentEditable
        placeholder="Whatcha wanna say?"
        className="Post-Input-Alt"
        id="Post-Input-Alt"
        tagName="div"
        html={inputHTML}
        onChange={(e: any) => setInputHTML(e.target.value)}
      /> */}
      <SliderToggle status={true} />
      <div id="menu" className="menu" role="listbox"></div>
      <input id="Dev-Upload-Input" type="file" accept="image/* video/*" onChange={createEmote} hidden />
    </div>
  )
}
