import imageCompression from 'browser-image-compression'
import { ChangeEvent, useContext } from 'react'
import { CardinalStateContext } from '../../../context/cardinal'
import { ActionType, EditorDispatchContext } from '../../../context/editorContext'
import { MiniProfile } from '../display/miniProfile'
import { MiniProfileDefaultBackground } from '../display/miniProfileDefaultBackground'
import './styles/miniProfileEditor.css'
import { imageCompressionOptions } from '../../../utils/constants'

export function MiniProfileEditor() {
  const cardinalState = useContext(CardinalStateContext)
  const dispatch = useContext(EditorDispatchContext)

  return (
    <>
      <h1 className="Tab-Header-Mobile">MINI PROFILE</h1>
      <div className="Mini-Profile-Editor-Container">
        <div className="Mini-Profile-Editor-Preview-Container">
          <div className="Mini-Profile-Editor-Preview">
            <MiniProfile editing />
          </div>
          <div className="Mini-Profile-Editor-Right">
            <h1>MINI PROFILE</h1>
            <h2>Choose a background to show on your mini profile (socials can be changed on the socials tab)</h2>
            <div className="Upload-Mini-Profile-Background-Button">
              <button onClick={() => document.getElementById('Mini-Profile-Background-Upload-Input')?.click()}>
                <span>Upload a background</span>
              </button>
              <input
                id="Mini-Profile-Background-Upload-Input"
                type="file"
                accept="image/* video/*"
                onChange={e => backgroundUpload(e)}
                hidden
              />
            </div>
          </div>
        </div>
        {cardinalState.user?.profile_media?.past_mini_backgrounds && (
          <div className="Past-Mini-Background-Container">
            <h2>Your Backgrounds</h2>
            <div className="Past-Mini-Background-Container-Inner">
              <div
                className="Past-Mini-Background-Item"
                onClick={() => dispatch({ type: ActionType.setMiniProfileBackground, payload: '' })}>
                <MiniProfileDefaultBackground />
              </div>
              {cardinalState.user.profile_media.past_mini_backgrounds?.map(
                (backgroundVideo, key) =>
                  backgroundVideo.length > 0 && (
                    <div className="Past-Mini-Background-Item" key={key}>
                      <video
                        className="Past-Mini-Background-Item-Video"
                        playsInline
                        muted
                        loop
                        disablePictureInPicture
                        src={backgroundVideo ? backgroundVideo + '#t=0.1' : undefined}
                        onMouseEnter={e => e.currentTarget.play()}
                        onMouseLeave={e => e.currentTarget.pause()}
                        onTouchStart={e => {
                          e.currentTarget.play()
                          e.currentTarget.focus()
                        }}
                        onBlur={e => e.currentTarget.pause()}
                        onClick={() => {
                          if (backgroundVideo)
                            dispatch({ type: ActionType.setMiniProfileBackground, payload: backgroundVideo })
                        }}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )

  async function backgroundUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files != null) {
      if (e.target.files[0].type.includes('image')) {
        let blob = e.target.files[0]
        const compressedFile = await imageCompression(blob, imageCompressionOptions)
        let output = URL.createObjectURL(compressedFile)
        dispatch({
          type: ActionType.setMiniProfileBackground,
          payload: output,
        })
        dispatch({
          type: ActionType.updateMiniProfileBackgroundBlob,
          payload: compressedFile,
        })

        e.target.value = ''
      } else {
        let blob = e.target.files[0]
        let output = URL.createObjectURL(e.target.files[0])
        dispatch({
          type: ActionType.setMiniProfileBackground,
          payload: output,
        })
        dispatch({
          type: ActionType.updateMiniProfileBackgroundBlob,
          payload: blob,
        })
        e.target.value = ''
      }
    }
    return
  }
}
