import './styles/privacyPolicy.css'

export function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy-Container">
      <div className="PrivacyPolicy-Text-Container">
        <h1>Privacy Policy for Sayso</h1>
        <p>
          We take the privacy of our users very seriously. This privacy policy outlines how we collect, use, and protect
          your personal information when you use our website. By using our website, you consent to the practices
          described in this policy.
        </p>
        <ol>
          <li>
            <h3>Information We Collect:</h3>
            <p>
              We may collect personal information from you, such as your name, email address, and other contact
              information, when you register for an account on our website or when you contact us. We may also collect
              information about your use of our website, including your IP address, browser type, and other technical
              information.
            </p>
          </li>
          <li>
            <h3>Use of Information:</h3>
            <p>
              We use your personal information to provide you with our services, to respond to your inquiries and
              requests, and to communicate with you about our website and any updates or changes to our policies. We may
              also use your information to improve our website and to analyze how users interact with our website.
            </p>
          </li>
          <li>
            <h3>Sharing of Information:</h3>
            <p>
              We may share your personal information with our service providers, who help us operate our website and
              provide our services to you. We may also share your information in response to a legal process, such as a
              court order or subpoena. We do not sell or rent your personal information to third parties for their
              marketing purposes.
            </p>
          </li>
          <li>
            <h3>Cookies and Other Tracking Technologies:</h3>
            <p>
              We may use cookies and other tracking technologies to collect information about your use of our website.
              Cookies are small data files that are stored on your computer or mobile device when you visit our website.
              We use cookies to remember your preferences and to analyze how users interact with our website. You can
              disable cookies in your browser settings, but this may limit your ability to use certain features of our
              website.
            </p>
          </li>
          <li>
            <h3>Security:</h3>
            <p>
              We take reasonable measures to protect your personal information from unauthorized access, disclosure, or
              destruction. However, no security system is completely secure, and we cannot guarantee the security of
              your personal information.
            </p>
          </li>
          <li>
            <h3>Children's Privacy:</h3>
            <p>
              Our website is not intended for use by children under the age of 13. We do not knowingly collect personal
              information from children under the age of 13. If we learn that we have collected personal information
              from a child under the age of 13, we will delete that information as soon as possible.
            </p>
          </li>
          <li>
            <h3>Changes to This Privacy Policy:</h3>
            <p>
              We may update this privacy policy from time to time. Any changes will be posted on this page, and we will
              notify you of any material changes by email or by posting a notice on our website.
            </p>
          </li>
          <li>
            <h3>Contact Us:</h3>
            <p>
              If you have any questions or concerns about this privacy policy or our website, please contact us at
              support@sayso.gg.
            </p>
          </li>
        </ol>
        <p>
          By using our website, you acknowledge that you have read and understood this privacy policy, and agree to the
          collection, use, and sharing of your personal information as described in this policy.
        </p>
        <p>Thank you for using Sayso!</p>
      </div>
    </div>
  )
}
