import { useEffect } from 'react'

interface ClickAwayListenerProps {
  children: JSX.Element
  onClickAway: () => void
  disable?: boolean
  className?: string
  id: string
}

export function ClickAwayListener({ children, onClickAway, disable, className, id }: ClickAwayListenerProps) {
  useEffect(() => {
    let drag = false
    let startOnModal = true

    function trackClick(e: any) {
      if (e.target.closest('#Listener-Element-' + id) == null && !drag) {
        onClickAway()
      }
      startOnModal = true
    }
    function setDragTrue() {
      if (startOnModal) {
        drag = true
      }
    }
    function setDragFalse(e: any) {
      drag = false
      if (e.target.closest('#Listener-Element-' + id) == null) {
        startOnModal = false
      }
    }
    function trackActiveElement() {
      if (document.activeElement?.tagName == 'IFRAME') {
        onClickAway()
      }
    }

    if (!disable) {
      window.addEventListener('blur', trackActiveElement, true)
      document.addEventListener('mousedown', setDragFalse, true)
      document.addEventListener('mousemove', setDragTrue, true)
      document.addEventListener('mouseup', trackClick, true)
    }

    return () => {
      window.removeEventListener('blur', trackActiveElement, true)
      document.removeEventListener('mousedown', setDragFalse, true)
      document.removeEventListener('mousemove', setDragTrue, true)
      document.removeEventListener('mouseup', trackClick, true)
    }
  }, [disable])

  return disable ? (
    children
  ) : (
    <div className={className} id={'Listener-Element-' + id}>
      {children}
    </div>
  )
}
