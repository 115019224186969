import * as React from 'react'

import { ClassNames } from '../../DomUtils/classNames'
import { Categories, CategoryConfig, categoryFromCategoryConfig } from '../../config/categoryConfig'
import {
  useCategoriesConfig,
  useEmojiStyleConfig,
  useGetEmojiUrlConfig,
  useLazyLoadEmojisConfig,
  useSkinTonesDisabledConfig,
} from '../../config/useConfig'
import { emojisByCategory, emojiUnified } from '../../dataUtils/emojiSelectors'
import { useIsEmojiDisallowed } from '../../hooks/useDisallowedEmojis'
import { useIsEmojiHidden } from '../../hooks/useIsEmojiHidden'
import { useActiveSkinToneState, useIsPastInitialLoad } from '../context/PickerContext'
import { ClickableEmoji, CustomClickableEmoji } from '../emoji/Emoji'

import { EmojiCategory } from './EmojiCategory'
import { Suggested } from './Suggested'

import './EmojiList.css'

export function EmojiList() {
  const categories = useCategoriesConfig()

  return (
    <ul className={ClassNames.emojiList}>
      {categories.map((categoryConfig, index) => {
        const category = categoryFromCategoryConfig(categoryConfig)

        if (category === Categories.SUGGESTED) {
          return <Suggested key={categoryConfig.name} categoryConfig={categoryConfig} />
        }

        return (
          <RenderCategory key={categoryConfig.name} index={index} category={category} categoryConfig={categoryConfig} />
        )
      })}
    </ul>
  )
}

function RenderCategory({
  index,
  category,
  categoryConfig,
}: {
  index: number
  category: Categories
  categoryConfig: CategoryConfig
}) {
  const isEmojiHidden = useIsEmojiHidden()
  const lazyLoadEmojis = useLazyLoadEmojisConfig()
  const emojiStyle = useEmojiStyleConfig()
  const isPastInitialLoad = useIsPastInitialLoad()
  const [activeSkinTone] = useActiveSkinToneState()
  const isEmojiDisallowed = useIsEmojiDisallowed()
  const getEmojiUrl = useGetEmojiUrlConfig()
  const showVariations = !useSkinTonesDisabledConfig()

  // Small trick to defer the rendering of all emoji categories until the first category is visible
  // This way the user gets to actually see something and not wait for the whole picker to render.
  const emojisToPush = !isPastInitialLoad && index > 1 ? [] : emojisByCategory(category)
  const customEmojisToPush = !isPastInitialLoad && index > 1 ? [] : categoryConfig.images

  let hiddenCounter = 0

  const emojis = React.useMemo(() => {
    return customEmojisToPush && categoryConfig.category === Categories.CUSTOM
      ? customEmojisToPush.map((emoji, index) => {
          return (
            <CustomClickableEmoji
              key={index}
              emoji={emoji}
              hidden={false}
              hiddenOnSearch={false}
              lazyLoad={lazyLoadEmojis}
            />
          )
        })
      : emojisToPush.map((emoji, index) => {
          const unified = emojiUnified(emoji, activeSkinTone)
          const { failedToLoad, filteredOut, hidden } = isEmojiHidden(emoji)

          const isDisallowed = isEmojiDisallowed(emoji)

          if (hidden || isDisallowed) {
            hiddenCounter++
          }

          if (isDisallowed) {
            return null
          }

          return (
            <ClickableEmoji
              showVariations={showVariations}
              key={index}
              emoji={emoji}
              unified={unified}
              hidden={failedToLoad}
              hiddenOnSearch={filteredOut}
              emojiStyle={emojiStyle}
              lazyLoad={lazyLoadEmojis}
              getEmojiUrl={getEmojiUrl}
            />
          )
        })
  }, [customEmojisToPush, emojisToPush])

  return (
    <EmojiCategory
      key={index}
      categoryConfig={categoryConfig}
      // Indicates that there are no visible emojis
      // Hence, the category should be hidden
      hidden={hiddenCounter === emojis.length}>
      {emojis}
    </EmojiCategory>
  )
}
