export const FIFTY_MINUTES_IN_MS = 3000000

export const sanitizeHtmlConf = {
  allowedTags: ['img', 'p', 'span', 'br', 'a'],
  allowedAttributes: {
    img: ['src', 'alt', 'class', 'title'],
    span: ['class'],
    a: ['class', 'href', 'data-seiso-miniprofile', 'target', 'rel'],
  },
}
export const sanitizeHtmlConfLessDataAttribute = {
  allowedTags: ['img', 'p', 'span', 'br', 'a'],
  allowedAttributes: {
    img: ['src', 'alt', 'class', 'title'],
    span: ['class'],
    a: ['class', 'href', 'target', 'rel'],
  },
}

export const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
}
