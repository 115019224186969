import { CreatePostVoteMutation, DeletePostVoteMutation } from './generated/graphql'

export const insertPostVoteOptimisticResponse = (
  postId: string,
  ownerHandle: string,
  voteCount: number
): CreatePostVoteMutation => {
  return {
    insert_post_vote_one: {
      __typename: 'post_vote',
      owner_handle: ownerHandle,
      post_id: postId,
      post: {
        __typename: 'post',
        id: postId,
        owner_handle: ownerHandle,
        votes: [
          {
            __typename: 'post_vote',
            owner_handle: ownerHandle,
            post_id: postId,
          },
        ],
        votes_aggregate: {
          __typename: 'post_vote_aggregate',
          aggregate: {
            __typename: 'post_vote_aggregate_fields',
            count: voteCount + 1,
          },
        },
      },
    },
  }
}

export const deletePostVoteOptimisticResponse = (postId: string, voteCount: number): DeletePostVoteMutation => {
  return {
    delete_post_vote_by_pk: {
      __typename: 'post_vote',
      owner_handle: '',
      post_id: postId,
      post: {
        __typename: 'post',
        owner_handle: '',
        id: postId,
        votes: [],
        votes_aggregate: {
          __typename: 'post_vote_aggregate',
          aggregate: {
            __typename: 'post_vote_aggregate_fields',
            count: voteCount - 1,
          },
        },
      },
    },
  }
}
