import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp'
import SquareSharpIcon from '@mui/icons-material/SquareSharp'
import ViewAgendaSharpIcon from '@mui/icons-material/ViewAgendaSharp'
import { useContext, useEffect, useState } from 'react'
import { ActionType, EditorDispatchContext } from '../../../../context/editorContext'
import { ImageShowcase } from '../../../../gql/scalars'
import '../styles/imageBlock.css'
import { useLocation, useNavigate } from 'react-router-dom'

type ImageBlockProps = {
  showcase: ImageShowcase
  keyProp: number
  tab: string
}

export function ImageBlock(props: ImageBlockProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const editorDispatch = useContext(EditorDispatchContext)
  const [showcaseDisplayCount, setShowcaseDisplayCount] = useState<number>()

  useEffect(() => {
    props.showcase?.items ? setShowcaseDisplayCount(props.showcase.items.length) : setShowcaseDisplayCount(1)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    props.showcase?.items ? setShowcaseDisplayCount(props.showcase.items.length) : setShowcaseDisplayCount(1)
  }, [props.showcase])

  useEffect(() => {
    let showcaseParams = props.showcase?.items ? props.showcase?.items : []
    if (showcaseParams && showcaseDisplayCount) {
      while (showcaseDisplayCount > showcaseParams.length) {
        // TODO: FIX THIS!showcaseParams = [...showcaseParams, null]
        showcaseParams = [
          ...showcaseParams,
          {
            images: [],
            link_text: '',
            link: '',
            description: '',
          },
        ]
      }
      if (showcaseDisplayCount < showcaseParams.length) {
        showcaseParams = showcaseParams.slice(0, showcaseDisplayCount)
      }

      const showcase = props.showcase

      if (showcase) {
        const showcaseUpdate = Object.assign({}, showcase, {
          items: showcaseParams,
        })

        editorDispatch({
          type: ActionType.secondLevelUpdate,
          payload: showcaseUpdate,
          firstLevelKey: props.tab,
          secondLevelKey: props.keyProp,
        })
      }
    }
    // eslint-disable-next-line
  }, [showcaseDisplayCount])

  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Display-Count-Options">
          <button
            className={`Display-Count-Option ${showcaseDisplayCount === 1 ? 'Display-Count-Option-Active' : ''}`}
            onClick={() => setShowcaseDisplayCount(1)}>
            <div>
              <SquareSharpIcon />
            </div>
          </button>
          <button
            className={`Display-Count-Option ${showcaseDisplayCount === 2 ? 'Display-Count-Option-Active' : ''}`}
            onClick={() => setShowcaseDisplayCount(2)}>
            <div>
              <ViewAgendaSharpIcon style={{ transform: 'rotate(90deg)' }} />
            </div>
          </button>
          <button
            className={`Display-Count-Option ${
              showcaseDisplayCount && showcaseDisplayCount >= 3 ? 'Display-Count-Option-Active' : ''
            }`}
            onClick={() => setShowcaseDisplayCount(4)}>
            <div>
              <GridViewSharpIcon />
            </div>
          </button>
        </div>

        {props.showcase?.items && (
          <div className={props.showcase.items.length === 1 ? 'Image-Block-Container-Alt' : 'Image-Block-Container'}>
            {props.showcase.items.map((showcase, key) =>
              showcase && showcase.images.length !== 0 ? (
                <div
                  className={
                    props.showcase?.items && props.showcase.items.length === 1
                      ? 'Image-Block-Item-Alt'
                      : 'Image-Block-Item'
                  }
                  key={key}>
                  {showcase?.link_text && (
                    <a
                      className="Image-Block-Link"
                      href={showcase.link ? showcase.link : '#'}
                      rel="noreferrer"
                      target="_blank">
                      {showcase.link_text}
                    </a>
                  )}
                  {showcase?.description && <div className="Image-Block-Desc">{showcase.description}</div>}
                  <div
                    className={
                      showcase?.description || showcase?.link_text ? 'Image-Block-Margin' : 'Image-Block-Margin-Alt'
                    }>
                    <div
                      className={
                        showcase?.images?.length === 1 ? 'Image-Block-Item-Image-NoGrid' : 'Image-Block-Item-Image-Grid'
                      }>
                      {showcase?.images?.slice(0, 4).map((image, imageKey) => (
                        <div
                          className={
                            (showcase.images?.length === 3 && imageKey > 0) || showcase.images?.length === 4
                              ? 'Image-Block-Item-Image-Container-Alt'
                              : 'Image-Block-Item-Image-Container'
                          }
                          key={imageKey}>
                          <img className="Image-Block-Item-Image" alt="" src={image ? image : ''} draggable />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className="Image-Block-Item-Editbutton"
                    onClick={() =>
                      navigate('/showcase/image-showcase-editor', {
                        state: {
                          blockKey: props.keyProp,
                          itemKey: key,
                          tab: props.tab,
                          showcase: props.showcase,
                          background: location,
                        },
                      })
                    }>
                    <EditIcon />
                  </div>
                </div>
              ) : (
                <div
                  className="Image-Editor-Item-Add"
                  onClick={() =>
                    navigate('/showcase/image-showcase-editor', {
                      state: {
                        blockKey: props.keyProp,
                        itemKey: key,
                        tab: props.tab,
                        showcase: props.showcase,
                        background: location,
                      },
                    })
                  }
                  key={key}>
                  <AddIcon />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  )
}
