import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import Twemoji from 'react-twemoji'
import { useDebouncedCallback } from 'use-debounce'
import { CardinalStateContext } from '../../context/cardinal'
import { User, useSearchUserLazyQuery } from '../../gql/generated/graphql'
import { ProfileAvatar } from '../profile/display/profileAvatar'
import { VerifiedCheck } from '../profile/display/verifiedCheck'
import './newMessageModal.css'
import { sanitizeHtmlConf } from '../../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { ListProfileItem } from '../profile/display/listProfileItem'

interface NewMessageModalProps {
  closeModal: () => void
}

export function NewMessageModal({ closeModal }: NewMessageModalProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useContext(CardinalStateContext)
  const [searchUser] = useSearchUserLazyQuery()
  const [userResults, setUserResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [showNoResults, setShowNoResults] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  const runSearch = useDebouncedCallback(async () => {
    let userData: any = await searchUser({
      variables: {
        searchInput: searchInput,
        currentUserHandle: user?.handle ?? '',
      },
    })
    setUserResults(userData.data.user)

    if (userData.data.user.length === 0) setShowNoResults(true)

    setLoading(false)
  }, 300)

  useEffect(() => {
    runSearch()
  }, [searchInput])

  useEffect(() => {
    let drag = false
    let startOnModal = true

    function trackClick(e: any) {
      if (e.target.closest('#Modal-Inner') == null && !drag) {
        closeModal()
      }
      startOnModal = true
    }
    function setDragTrue() {
      if (startOnModal) {
        drag = true
      }
    }
    function setDragFalse(e: any) {
      drag = false
      if (e.target.closest('#Modal-Inner') == null) {
        startOnModal = false
      }
    }

    document.addEventListener('mousedown', setDragFalse, true)
    document.addEventListener('mousemove', setDragTrue, true)
    document.addEventListener('mouseup', trackClick, true)
    return () => {
      document.removeEventListener('mousedown', setDragFalse, true)
      document.removeEventListener('mousemove', setDragTrue, true)
      document.removeEventListener('mouseup', trackClick, true)
    }
  }, [])

  return (
    <div id="Modal" className={'New-Message-Modal-Container-BG'}>
      <div className="New-Message-Modal-Container" id="Modal-Inner">
        <h2>Create DM</h2>
        <input
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search Users"
          className="New-Message-Modal-Search"
        />
        <div className="New-Message-Modal-Results-Container">
          <div className="Search-Output-Users">
            <div className="Search-Output-Users-Header">Users:</div>
            {userResults.map((item: User, key: number) =>
              item.handle === user?.handle ? null : (
                <div
                  className="User-Output-Item"
                  id="User-Output-Item"
                  onClick={e => {
                    let sel = window.getSelection()
                    const id = [user?.handle, item.handle].sort().join('-')
                    if (sel?.type === 'Caret') {
                      navigate(`/messages/${id}`)
                      closeModal()
                    }
                  }}
                  key={key}>
                  <ListProfileItem user={item} index={key} disableSocialLinks disableFollowButton />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
