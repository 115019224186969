import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ChangeEvent, useContext } from 'react'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import {
  GalleryShowcase,
  ImageShowcase,
  InfoShowcase,
  PanelShowcase,
  Showcase,
  ShowcaseType,
  VideoShowcase,
  SocialsShowcase,
} from '../../../gql/scalars'
import { GalleryBlock } from './blocks/galleryBlock'
import { ImageBlock } from './blocks/imageBlock'
import { InfoBlock } from './blocks/infoBlock'
import { PanelBlock } from './blocks/panelBlock'
import { VideoBlock } from './blocks/videoBlock'
import { SocialsBlock } from './blocks/socialsBlock'
import './styles/blockEditor.css'

type BlockEditorProps = {
  showcase: Showcase
  keyProp: number
  tab: string
  tabCount: number
}

export function BlockEditor(props: BlockEditorProps) {
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)

  return (
    <>
      <div className="Editor-Block">
        <section
          className={`Editor-Block-Section-Top-Level Editor-Block-Section Editor-Block-Section-Open`}
          id={`Editor-Block-Section-Top-Level-${props.tab}-${props.keyProp}`}>
          <div
            className="Selection-Header"
            onClick={() => ToggleSection(`Editor-Block-Section-Top-Level-${props.tab}-${props.keyProp}`)}>
            <ChevronRightIcon className="Selection-Header-Chevron" />

            <span>{`${props.showcase.type} Showcase` + ' - ' + props.showcase.title}</span>
            <div className="Section-Options">
              {props.keyProp !== 0 && (
                <button
                  className="Section-Options-Button"
                  onClick={e => {
                    changeShowcasePosition(props.keyProp - 1)
                    e.stopPropagation()
                  }}>
                  <span>Move up</span>
                  <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M12 10L8 6L4 10"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              )}
              {props.keyProp !== props.tabCount - 1 && (
                <button
                  className="Section-Options-Button"
                  onClick={e => {
                    changeShowcasePosition(props.keyProp + 1)
                    e.stopPropagation()
                  }}>
                  <span>Move down</span>
                  <div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ transform: 'rotate(180deg)' }}>
                      <path
                        d="M12 10L8 6L4 10"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              )}
              <button
                className="Section-Options-Button"
                onClick={e => {
                  e.stopPropagation()
                  deleteShowcase()
                }}>
                <span>Remove</span>
                <div>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M13 3L3 13M3 3L13 13"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>

          <div className="Block-Custom-Header">
            <section
              className={`Editor-Block-Section-Alt`}
              id={`Editor-Block-Section-${props.tab}-${props.keyProp}-Title`}>
              <div
                className="Selection-Header-Alt"
                onClick={() => ToggleSection(`Editor-Block-Section-${props.tab}-${props.keyProp}-Title`)}>
                <ChevronRightIcon className="Selection-Header-Chevron" />
                <span>{`Title`}</span>
              </div>
              <textarea
                placeholder="Add a title for your showcase"
                className="Block-Text"
                value={props.showcase.title ? props.showcase.title : ''}
                onChange={onChangeTitleTextarea}
              />
            </section>
            <section
              className={`Editor-Block-Section-Alt`}
              id={`Editor-Block-Section-${props.tab}-${props.keyProp}-Desc`}>
              <div
                className="Selection-Header-Alt"
                onClick={() => ToggleSection(`Editor-Block-Section-${props.tab}-${props.keyProp}-Desc`)}>
                <ChevronRightIcon className="Selection-Header-Chevron" />
                <span>{`Description`}</span>
              </div>
              <textarea
                placeholder="Add a description for your showcase"
                className="Block-Text"
                value={props.showcase.description ? props.showcase.description : ''}
                onChange={onChangeDescTextarea}
              />
            </section>
          </div>

          {props.showcase.type === ShowcaseType.Images && (
            <ImageBlock keyProp={props.keyProp} showcase={props.showcase as ImageShowcase} tab={props.tab} />
          )}
          {props.showcase.type === ShowcaseType.Video && (
            <VideoBlock keyProp={props.keyProp} showcase={props.showcase as VideoShowcase} tab={props.tab} />
          )}
          {props.showcase.type === ShowcaseType.Info && (
            <InfoBlock keyProp={props.keyProp} showcase={props.showcase as InfoShowcase} tab={props.tab} />
          )}
          {props.showcase.type === ShowcaseType.Gallery && (
            <GalleryBlock keyProp={props.keyProp} showcase={props.showcase as GalleryShowcase} tab={props.tab} />
          )}
          {props.showcase.type === ShowcaseType.Panel && (
            <PanelBlock keyProp={props.keyProp} showcase={props.showcase as PanelShowcase} tab={props.tab} />
          )}
          {props.showcase.type === ShowcaseType.Socials && (
            <SocialsBlock keyProp={props.keyProp} showcase={props.showcase as SocialsShowcase} tab={props.tab} />
          )}
        </section>
      </div>
    </>
  )

  function ToggleSection(sectionId: string) {
    let section = document.getElementById(sectionId)
    if (section) {
      section.classList.toggle('Editor-Block-Section-Open')
    }
  }

  function deleteShowcase() {
    dispatch({
      type: ActionType.secondLevelDelete,
      firstLevelKey: props.tab,
      secondLevelKey: props.keyProp,
    })
  }

  function changeShowcasePosition(indexToReplace: number) {
    let tabIndex = state.profileTabs?.findIndex(tab => tab?.name === props.tab)

    if (tabIndex !== -1) {
      let showcaseToReplace =
        state.profileTabs![tabIndex!] && state.profileTabs![tabIndex!]!.showcases![indexToReplace]
          ? state.profileTabs![tabIndex!]!.showcases![indexToReplace]
          : null

      if (showcaseToReplace) {
        dispatch({
          type: ActionType.secondLevelUpdatePosition,
          payload: props.showcase,
          keyToReplace: indexToReplace,
          firstLevelKey: props.tab,
          secondLevelKey: props.keyProp,
        })

        dispatch({
          type: ActionType.secondLevelUpdatePosition,
          payload: showcaseToReplace,
          keyToReplace: props.keyProp,
          firstLevelKey: props.tab,
          secondLevelKey: props.keyProp,
        })
      }
    }
  }

  function onChangeTitleTextarea(e: ChangeEvent<HTMLTextAreaElement>) {
    dispatch({
      type: ActionType.setBlockTitle,
      payload: e.target.value,
      blockKey: props.keyProp,
      tab: props.tab,
    })
  }
  function onChangeDescTextarea(e: ChangeEvent<HTMLTextAreaElement>) {
    dispatch({
      type: ActionType.setBlockDesc,
      payload: e.target.value,
      blockKey: props.keyProp,
      tab: props.tab,
    })
  }
}
