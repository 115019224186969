import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import { CardinalStateContext } from '../../../context/cardinal'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import { cropPreview } from '../../../utils/cropImagePreview'
import './styles/bannerEditor.css'

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export function BannerEditor() {
  const cardinalState = useContext(CardinalStateContext)
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)

  const [imgSrc, setImgSrc] = useState('')
  const [imageBlob, setImageBlob] = useState<File>()
  const previewCanvasRef = useRef<HTMLCanvasElement | undefined>(undefined)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(4 / 1)

  useEffect(() => {
    changePreview()
  }, [completedCrop, scale, rotate])

  useEffect(() => {
    dispatch({
      type: ActionType.updateBannerBlob,
      payload: imageBlob,
    })
  }, [imageBlob])

  useEffect(() => {
    let pastBannerIndex = cardinalState.user?.profile_media?.past_banners?.findIndex(item => item === state.banner)

    if (state.banner === cardinalState.user?.banner || pastBannerIndex !== -1) {
      setImgSrc('')
      setImageBlob(undefined)
    }
  }, [state.banner])

  const changePreview = useCallback(
    debounce(
      () => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current) {
          cropPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate).then(newImage => {
            dispatch({
              type: ActionType.setBanner,
              payload: newImage.previewUrl,
            })
            dispatch({
              type: ActionType.updateBannerBlob,
              payload: newImage.newBlob,
            })
          })
        }
      },
      50,
      { trailing: true }
    ),
    [completedCrop, scale, rotate]
  )

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      setImageBlob(e.target.files[0])
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  return (
    <>
      <h1 className="Tab-Header-Mobile">BANNER</h1>
      <div className="Banner-Editor-Container">
        <div className="Banner-Editor-Flex">
          <div className="Upload-Banner-Info">
            <div>
              <h1>BANNER</h1>
              <h2>Choose a banner or upload a new one (aspect-ratio 3.5/1)</h2>
            </div>
            <div className="Upload-Avatar-Button Upload-Banner-Button">
              <button onClick={() => document.getElementById('Banner-Upload-Input')?.click()}>
                <span>Upload a banner</span>
              </button>

              <input id="Banner-Upload-Input" type="file" accept="image/*" onChange={onSelectFile} hidden />
            </div>
          </div>
        </div>
        {state.banner !== undefined && (
          <div className="Banner-Preview-Container">
            <div className="Banner-Preview" style={{ backgroundImage: `url(${state.banner})` }} />
          </div>
        )}
        {Boolean(imgSrc) && (
          <div className="Avatar-Editor-Crop-Image-Container">
            <div className="Avatar-Editor-Crop-Image-Inner-Container">
              <ReactCrop
                ruleOfThirds
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                aspect={aspect}>
                <img
                  className="Avatar-Editor-Crop-Image-Preview"
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          </div>
        )}

        {cardinalState.user?.profile_media?.past_banners && (
          <div className="Past-Banner-Container">
            <h2>Your Past Banners</h2>
            <div className="Past-Banner-Container-Inner">
              {[...cardinalState.user.profile_media.past_banners].reverse().map((item, key) => (
                <div
                  className="Past-Banner-Item"
                  key={key}
                  style={{
                    backgroundImage: `url(${item})`,
                  }}
                  onClick={() => {
                    if (item)
                      dispatch({
                        type: ActionType.setBanner,
                        payload: item,
                      })
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
