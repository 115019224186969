import { getProviderIcon } from '../../../utils/statusUtils'
import { SocialsShowcase as GraphqlSocialsShowcase } from '../../../gql/scalars'
import './styles/socialsShowcase.css'

type SocialsShowcaseProps = {
  socialsShowcase: GraphqlSocialsShowcase
}

export function SocialsShowcase(props: SocialsShowcaseProps) {
  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Socials-Showcase-Container">
          {props.socialsShowcase.items.map((item: any, key: number) => (
            <a href={`${item?.url}`} target="_blank" className="Socials-Showcase-Item" key={key} rel="noreferrer">
              <img className="Socials-Showcase-Item-Img" src={getProviderIcon(item?.provider)} alt="" />
              <div className="Socials-Showcase-Item-Info">
                <h2>{item?.customName ? item?.customName : item?.provider}</h2>
                <p>{item?.url}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
