import { useContext, useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CardinalStateContext } from '../context/cardinal'
import {
  useGetHashtagLazyQuery,
  useFollowHashtagMutation,
  useUnFollowHashtagMutation,
  User,
  Post,
} from '../gql/generated/graphql'
import './styles/hashtag.css'
import { PostFilterType, useListPosts } from '../gql'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'

export function Hashtag() {
  const { hashtag_id } = useParams()
  const navigate = useNavigate()
  const { user, browsingState, homeScrollPosition } = useContext(CardinalStateContext)
  const [followHashtag] = useFollowHashtagMutation()
  const [unFollowHashtag] = useUnFollowHashtagMutation()
  const [followingHashtag, setFollowingHashtag] = useState(false)
  const [getHashtag, { data: hashtagData }] = useGetHashtagLazyQuery()
  const { data, loading, fetchMore } = useListPosts(PostFilterType.ByHashtag, hashtag_id)

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  useEffect(() => {
    if (hashtag_id && user?.handle) {
      getHashtag({
        variables: {
          currentUserHandle: user.handle,
          hashtagName: hashtag_id,
        },
      })
    }
  }, [browsingState, getHashtag, user?.handle])

  useEffect(() => {
    if (hashtagData)
      setFollowingHashtag(
        hashtagData.hashtag.length > 0 &&
          hashtagData?.hashtag[0]?.hashtag_followers[0]?.follower_handle === user?.handle
      )
  }, [hashtagData])

  async function toggleFollowHashtag() {
    if (followingHashtag) {
      await unFollowHashtag({
        variables: {
          currentUserHandle: user?.handle,
          tagToUnFollow: hashtag_id,
        },
      })
      setFollowingHashtag(false)
    } else {
      await followHashtag({
        variables: {
          currentUserHandle: user?.handle,
          tagToFollow: hashtag_id,
        },
      })
      setFollowingHashtag(true)
    }
  }

  const hashtagItems = useMemo(() => {
    return data?.post.map((post, index) => {
      if (post.repost_post) {
        return (
          <FeedItem
            id={post.repost_post.id}
            timestamp={post.repost_post.created_at}
            owner={post.repost_post.owner as User}
            text={post.repost_post.text}
            gifUrl={post.repost_post.gif_url}
            imageUrls={post.repost_post.image_urls}
            videoUrl={post.repost_post.video_url}
            replyCount={post.repost_post.replies_aggregate.aggregate?.count}
            voteCount={post.repost_post.votes_aggregate.aggregate?.count}
            shareCount={
              (post.repost_post.quote_reposts_aggregate.aggregate?.count ?? 0) +
              (post.repost_post.reposts_aggregate.aggregate?.count ?? 0)
            }
            voted={post.repost_post.votes.length > 0 && post.repost_post.votes[0]?.owner_handle === user?.handle}
            bookmarked={
              post.repost_post.bookmarked_posts.length > 0 &&
              post.repost_post.bookmarked_posts[0]?.owner_handle === user?.handle
            }
            itemType={FeedType.Post}
            key={index}
            sharedBy={post.owner as User}
          />
        )
      } else {
        return (
          <FeedItem
            id={post.id}
            timestamp={post.created_at}
            owner={post.owner as User}
            text={post.text}
            gifUrl={post.gif_url}
            imageUrls={post.image_urls}
            videoUrl={post.video_url}
            replyCount={post.replies_aggregate.aggregate?.count}
            voteCount={post.votes_aggregate.aggregate?.count}
            shareCount={
              (post.quote_reposts_aggregate.aggregate?.count ?? 0) + (post.reposts_aggregate.aggregate?.count ?? 0)
            }
            voted={post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle}
            bookmarked={post.bookmarked_posts.length > 0 && post.bookmarked_posts[0]?.owner_handle === user?.handle}
            itemType={FeedType.Post}
            key={index}
            quoteRepostData={post.quote_repost_post as Post}
          />
        )
      }
    })
  }, [data])

  return (
    <div className="Thread-Container">
      {/* <div className={`Hashtag-Follow-Button`} onClick={toggleFollowHashtag}>{`${
          followingHashtag ? 'Unfollow Hashtag' : 'Follow Hashtag'
        }`}</div> */}
      <NavBackButtonHeader title={`#${hashtag_id}`} />
      <Feed
        scrollPosition={homeScrollPosition}
        loading={loading}
        items={hashtagItems}
        feedType={FeedType.Post}
        fetchMore={fetchMore}
        disableCreateFeedItem
        noItemsMessage="There are no posts under this hashtag yet, be the first!"
      />
    </div>
  )
}
