import { Masonry } from '@mui/lab'
import { GalleryShowcase as GraphqlGalleryShowcase } from '../../../gql/scalars'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './styles/galleryShowcase.css'

type GalleryShowcaseProps = {
  galleryShowcase: GraphqlGalleryShowcase
}

export function GalleryShowcase(props: GalleryShowcaseProps) {
  // const theme = createTheme({
  //   breakpoints: {
  //     values: {
  //       xs: 0,
  //       sm: 630,
  //       md: 960,
  //       lg: 1100,
  //       xl: 1500,
  //     },
  //   },
  // })
  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Gallery-Showcase-Container">
          <div className="Gallery-Showcase-Container-Inner" id="Gallery-Showcase">
            {props.galleryShowcase?.images && (
              <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={0.5}>
                {props.galleryShowcase.images.map((item, key) => (
                  <div key={key}>
                    <img className="Gallery-Showcase-Item-Image" src={item ? item : ''} alt="" />
                  </div>
                ))}
              </Masonry>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
