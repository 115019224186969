export enum PathNames {
  Authentication = '/login',
  DevTools = '/dev',
  Home = '/',
  Profile = '/profile/:profile_id',
  ProfileTab = '/profile/:profile_id/:profile_tab',
  Following = '/following/:profile_id',
  Followers = '/followers/:profile_id',
  Followers_You_Follow = '/followers_you_follow/:profile_id',
  Settings = '/settings/:settings_type',
  SettingsTab = '/settings/:settings_type/:showcase_tab',
  Thread = '/thread/:owner_id/:thread_id',
  Post = '/post/:owner_id/:post_id',
  RoadMap = '/roadmap',
  Explore = '/explore/:explore_tab',
  Notifications = '/notifications/:notification_type',
  Messages = '/messages',
  MessagesId = '/messages/:chat_id',
  Bookmarks = '/bookmarks',
  Search = '/search',
  SearchId = '/search/:search_field',
  Hashtag = '/hashtag/:hashtag_id',
  Browse = '/browse',
  TOS = '/tos',
  Privacy = '/privacy',
}

export enum ModalPathNames {
  SetStatus = '/status',
  CreatePost = '/create/post',
  PostImage = '/post/:owner_id/:post_id/image/:image_key',
  OpenImage = '/image/preview/:open_image_link',
  ShowcaseImageEditor = '/showcase/image-showcase-editor',
  ShowcaseVideoEditor = '/showcase/video-showcase-editor',
  MobileProfileNav = '/nav/mobile-view',
}

export const PathNameRoutes = [
  PathNames.Authentication,
  PathNames.DevTools,
  PathNames.Home,
  PathNames.Profile,
  PathNames.ProfileTab,
  PathNames.Following,
  PathNames.Followers,
  PathNames.Followers_You_Follow,
  PathNames.Settings,
  PathNames.SettingsTab,
  PathNames.Thread,
  PathNames.Post,
  PathNames.RoadMap,
  PathNames.Explore,
  PathNames.Notifications,
  PathNames.Messages,
  PathNames.MessagesId,
  PathNames.Bookmarks,
  PathNames.Search,
  PathNames.SearchId,
  PathNames.Hashtag,
  PathNames.Browse,
  PathNames.TOS,
  PathNames.Privacy,

  ModalPathNames.SetStatus,
  ModalPathNames.CreatePost,
  ModalPathNames.PostImage,
  ModalPathNames.OpenImage,
  ModalPathNames.ShowcaseImageEditor,
  ModalPathNames.ShowcaseVideoEditor,
  ModalPathNames.MobileProfileNav,
]
