export enum tags {
  video = 'video',
  image = 'image',
}

export const types = new Map([
  ['jpg', tags.image],
  ['jpeg', tags.image],
  ['gif', tags.image],
  ['png', tags.image],
  ['mp4', tags.video],
  ['3gp', tags.video],
  ['webm', tags.video],
])

export function numberToLetterNotation(value: number | undefined): string {
  if (!value) {
    return '0'
  }

  let isNegative = value < 0 ? true : false
  let str = Math.abs(value).toString()
  let count = str.length
  let result = ''
  let i = 0

  switch (count) {
    case 4:
    case 5:
    case 6:
      for (i; i < count - 3; i++) {
        result += str.charAt(i)
      }
      result += '.' + str.charAt(i) + 'K'
      return isNegative ? '-' + result : result
    case 7:
    case 8:
    case 9:
      for (i; i < count - 6; i++) {
        result += str.charAt(i)
      }
      result += '.' + str.charAt(i) + 'M'
      return isNegative ? '-' + result : result
    default:
      return isNegative ? '-' + str : str
  }
}

export function linkToYoutubeId(url: string): string {
  console.log(url)
  var videoId = url.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/shorts\/|\/embed\/|\/|)([^\s&]+)/
  )
  if (videoId && videoId.length > 1) {
    return videoId[1]
  }

  console.log('The youtube url is not valid.')
  return 'The youtube url is not valid.'
}

// TODO: Make Youtube Json into a type.
export async function linkToYoutubeJson(url: string) {
  let videoInfo
  await fetch(`https://www.youtube.com/oembed?url=${url}&format=json`)
    .then(response => {
      videoInfo = response.json()
    })
    .catch(function (error) {
      console.log('Error fetching Youtube data: ' + error.message)
    })

  return videoInfo
}

export function checkBackgroundType(url: string) {
  const splitLink = url.split('.')
  const extension = splitLink[splitLink.length - 1]

  return types.get(extension.toLocaleLowerCase())
}
