import moment from 'moment'
import { NavLink, useNavigate } from 'react-router-dom'
import Twemoji from 'react-twemoji'
import sanitizeHTML from 'sanitize-html'
import { ProfileAvatar } from '../../profile/display/profileAvatar'
import { User } from '../../../gql/generated/graphql'
import { VerifiedCheck } from '../../profile/display/verifiedCheck'

interface QuoteRepostItemProps {
  id: string
  timestamp: string
  owner?: User
  text?: string | null
  gifUrl?: string | null
  imageUrls?: (string | null)[]
  videoUrl?: string | null
  replyToHandle?: string
  quotePost?: boolean
}

export function QuoteRepostItem({
  id,
  timestamp,
  owner,
  text,
  gifUrl,
  imageUrls,
  videoUrl,
  replyToHandle,
  quotePost,
}: QuoteRepostItemProps) {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })
  const sanitizeConf = {
    allowedTags: ['img', 'p', 'span', 'br', 'a'],
    allowedAttributes: {
      img: ['src', 'alt', 'class', 'title'],
      span: ['class'],
      a: ['class', 'href', 'data-seiso-miniprofile'],
    },
  }
  const navigate = useNavigate()

  return (
    <>
      <div className={`Post-Container ${quotePost ? 'Quote-Post-Container' : 'Focused-Post-Container'}`}>
        <div
          className="Post-Container-Inner"
          onClick={(e: any) => {
            e.stopPropagation()
            navigate(`/post/${owner?.handle}/${id}`)
          }}>
          <div className="Post-Body">
            <div className="Post-Left">
              <ProfileAvatar size="Small" user={owner} />
            </div>
            <div className="Post-Right">
              <div className="Post-Header">
                <div className="Post-Info">
                  <div className="Post-User-Info">
                    <span className="Post-Displayname">
                      <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                        {owner?.username}
                        {owner?.is_verified && <VerifiedCheck />}
                      </Twemoji>
                    </span>
                    <span data-seiso-miniprofile={owner?.handle} className="Post-Username">
                      @{owner?.handle}
                    </span>
                  </div>
                </div>
                <div className="Post-Timestamp">
                  <time dateTime={`${timestamp}`}>
                    {moment().diff(moment(timestamp), 'hours') >= 23
                      ? moment(timestamp).format('MMM Do')
                      : moment(timestamp).fromNow()}
                  </time>
                </div>
              </div>
              {replyToHandle && (
                <div className="Reply-Indicator">
                  Replying to{' '}
                  <NavLink
                    data-seiso-miniprofile={replyToHandle}
                    onClick={e => e.stopPropagation()}
                    to={`/profile/${replyToHandle}`}>
                    @{`${replyToHandle}`}
                  </NavLink>
                </div>
              )}
              <div className="Post-Content">
                <div className="Post-Text">
                  <Twemoji options={{ className: 'Post-Emoji' }}>
                    <div dangerouslySetInnerHTML={{ __html: text ? sanitizeHTML(text, sanitizeConf) : '' }} />
                  </Twemoji>
                </div>
                {((imageUrls && imageUrls.length > 0) || videoUrl || gifUrl) && (
                  <div className="Post-Non-Text">
                    {imageUrls && imageUrls?.length > 0 && (
                      <>
                        {imageUrls.length === 1 ? (
                          imageUrls?.map((image, key) => (
                            <img key={key} className="Post-Image" src={image ? image : ''} alt="" />
                          ))
                        ) : (
                          <div className="Post-Images-Grid">
                            {imageUrls?.slice(0, 4).map((image, key) => (
                              <div
                                className={
                                  imageUrls?.length === 2
                                    ? 'Post-Images-Grid-Item'
                                    : imageUrls?.length === 3 && key === 0
                                    ? 'Post-Images-Grid-Item'
                                    : imageUrls?.length === 3 && key > 0
                                    ? 'Post-Images-Grid-Item-Alt'
                                    : 'Post-Images-Grid-Item-Alt'
                                }
                                key={key}>
                                <img className="Post-Image-Alt" src={image ? image : ''} alt="" />
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                    {videoUrl && (
                      <div className="Post-Video-Container">
                        <video
                          className="Post-Video"
                          controls
                          autoPlay
                          playsInline
                          muted
                          loop
                          src={videoUrl}
                          onClick={e => {
                            e.stopPropagation()
                          }}
                        />
                      </div>
                    )}
                    {gifUrl && <img className="Post-Image" src={gifUrl} draggable />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
