import './loading.css'

export function Loading() {
  return (
    <div className="Loading-Cubes-Container">
      <div className="Loading-Cubes">
        <div className="Loading-Cube Loading-Cube-1"></div>
        <div className="Loading-Cube Loading-Cube-2"></div>
      </div>
      <h2>Loading..</h2>
    </div>
  )
}
