import { StreamProvider } from '../../../gql/scalars'
import { StreamSettings } from './streamContainer'
import './streamChat.css'

interface StreamChatProps {
  streamProvider: any
  streamSettings: StreamSettings | null | undefined
}

export function StreamChat(props: StreamChatProps) {
  const isDev = process.env.NODE_ENV === 'development' ? true : false
  let localhost = 'localhost'
  let domain = 'test-4ch.pages.dev'
  return (
    <>
      <div className="Chat-Container" id="Chat-Container">
        {props.streamProvider === StreamProvider.Twitch ? (
          <iframe
            title="TwitchChat"
            name="TwitchChat"
            src={`https://twitch.tv/embed/${props.streamSettings?.twitchID}/chat?no-mobile-redirect=true&parent=${
              isDev ? localhost : domain
            }&darkpopout=true`}
            style={{ height: '100%', width: '100%', border: 'none' }}
          />
        ) : (
          <iframe
            title="YoutubeChat"
            name="YoutubeChat"
            style={{ height: '100%', width: '100%', border: 'none' }}
            src={`https://www.youtube.com/live_chat?v=${props.streamSettings?.youtubeID}&embed_domain=${
              isDev ? localhost : domain
            }`}
          />
        )}
      </div>
    </>
  )
}
