import React, { useRef, useState, useEffect } from 'react'

interface AutoScrollingDivProps {
  children: React.ReactNode
}

function AutoScrollingDiv({ children }: AutoScrollingDivProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isUserScrolling, setIsUserScrolling] = useState<boolean>(false)

  const scrollToBottom = () => {
    if (!isUserScrolling && containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current

      // Calculate the difference between scrollHeight and scrollTop before adding content
      const initialDifference = scrollHeight - containerRef.current.scrollTop

      // Scroll to the bottom
      containerRef.current.scrollTop = scrollHeight

      // Calculate the difference between scrollHeight and scrollTop after adding content
      const finalDifference = scrollHeight - containerRef.current.scrollTop

      // Calculate the difference in content height
      const contentDifference = finalDifference - initialDifference

      // Adjust the scroll position to maintain the same relative position
      containerRef.current.scrollTop += contentDifference
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current

      if (scrollTop < scrollHeight - clientHeight) {
        setIsUserScrolling(true)
      } else {
        setIsUserScrolling(false)
      }
    }
  }

  useEffect(() => {
    // Scroll to the bottom when the component first renders
    scrollToBottom()
  }, [])

  useEffect(() => {
    // Scroll to the bottom whenever the content changes
    scrollToBottom()
  }, [children])

  useEffect(() => {
    // Scroll to the bottom after the new children have been added and rendered
    const timer = setTimeout(() => {
      scrollToBottom()
    }, 0)

    return () => {
      clearTimeout(timer)
    }
  }, [children])

  return (
    <div
      className="Message-Chatbox-List Active-Scrollbar"
      ref={containerRef}
      style={{ height: '100%', overflowY: 'scroll' }}
      onScroll={handleScroll}>
      {children}
    </div>
  )
}

export default AutoScrollingDiv
