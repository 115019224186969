import ReactMarkdown from 'react-markdown'
import Twemoji from 'react-twemoji'
import { InfoShowcase as GraphqlInfoShowcase } from '../../../gql/scalars'
import './styles/infoShowcase.css'

type InfoShowcaseProps = {
  infoShowcase: GraphqlInfoShowcase
}

export function InfoShowcase(props: InfoShowcaseProps) {
  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Info-Showcase-Text-Container">
          <div className="Info-Showcase-Text">
            <Twemoji options={{ className: 'Create-Post-Emoji' }}>
              <ReactMarkdown children={props.infoShowcase?.text ? props.infoShowcase.text : ''} />
            </Twemoji>
          </div>
        </div>
      </div>
    </>
  )
}
