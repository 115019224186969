import { useState } from 'react'

import './styles/sliderToggle.css'

interface SliderToggleProps {
  status?: boolean
}

export function SliderToggle({ status = false }: SliderToggleProps) {
  const [toggleButton, setToggleButton] = useState(status)

  return (
    <button
      className={`Slider-Toggle ${toggleButton && 'Slider-Toggle-Active'}`}
      onClick={() => setToggleButton(!toggleButton)}>
      <span className="Slider-Toggle-Inner" />
    </button>
  )
}
