import { useParams } from 'react-router-dom'

export function OpenImageModal() {
  const { open_image_link } = useParams()
  return (
    <div className="Open-Image-Modal-Container-BG">
      <div className="Open-Image-Modal-Container">
        <img src={open_image_link} alt="" />
      </div>
    </div>
  )
}
