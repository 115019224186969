import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

let previewUrl = ''
let newBlob: File | undefined = undefined

type ResolveType = {
  previewUrl: string
  newBlob: File
}

export async function cropPreview(
  image: HTMLImageElement,
  canvasRef: HTMLCanvasElement | undefined,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const canvas = canvasRef ? canvasRef : document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)
  ctx.restore()

  return new Promise<ResolveType>((resolve, reject) => {
    canvas.toBlob(blob => {
      // returning an error
      if (!blob) {
        reject(new Error('Canvas is empty'))
        return
      }

      // blob.name = fileName;
      // creating a Object URL representing the Blob object given

      // if (previewUrl) {
      //   URL.revokeObjectURL(previewUrl)
      // }
      previewUrl = URL.createObjectURL(blob)
      newBlob = new File([blob], 'name', {
        type: 'image/png',
      })

      let combine = {
        previewUrl,
        newBlob,
      }

      resolve(combine)
    }, 'image/jpeg')
  })
}
