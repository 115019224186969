import clsx from 'clsx'
import * as React from 'react'
import { useState } from 'react'
import './CategoryNavigation.css'

import { ClassNames } from '../../DomUtils/classNames'
import { Categories, categoryFromCategoryConfig, categoryNameFromCategoryConfig } from '../../config/categoryConfig'
import { useCategoriesConfig } from '../../config/useConfig'
import { useActiveCategoryScrollDetection } from '../../hooks/useActiveCategoryScrollDetection'
import useIsSearchMode from '../../hooks/useIsSearchMode'
import { useScrollCategoryIntoView } from '../../hooks/useScrollCategoryIntoView'
import { Button } from '../atoms/Button'
import { useCategoryNavigationRef } from '../context/ElementRefContext'

export function CategoryNavigation() {
  const [activeCategory, setActiveCategory] = useState<string | null>(null)
  const scrollCategoryIntoView = useScrollCategoryIntoView()
  useActiveCategoryScrollDetection(setActiveCategory)
  const isSearchMode = useIsSearchMode()

  const categoriesConfig = useCategoriesConfig()
  const CategoryNavigationRef = useCategoryNavigationRef()

  function getProviderIcon(categoryProvider: undefined | Categories) {
    switch (categoryProvider) {
      case Categories.SUGGESTED:
        return 'https://twemoji.maxcdn.com/v/14.0.1/72x72/23f0.png'
      case Categories.FOOD_DRINK:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/1f346.png'
      case Categories.ANIMALS_NATURE:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/1f436.png'
      case Categories.SMILEYS_PEOPLE:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/1f61c.png'
      case Categories.FLAGS:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/1f3f4-200d-2620-fe0f.png'
      case Categories.TRAVEL_PLACES:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/2708.png'
      case Categories.OBJECTS:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/1f4a1.png'
      case Categories.SYMBOLS:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/26a0.png'
      case Categories.ACTIVITIES:
        return 'https://twemoji.maxcdn.com/v/14.0.2/72x72/26bd.png'
      default:
        return ''
    }
  }

  return (
    <div className="epr-category-nav" ref={CategoryNavigationRef}>
      {categoriesConfig.map(categoryConfig => {
        const category = categoryFromCategoryConfig(categoryConfig)
        const categoryIcon = categoryConfig.icon ? categoryConfig.icon : getProviderIcon(category)
        return (
          <Button
            tabIndex={isSearchMode ? -1 : 0}
            className={clsx('epr-cat-btn', `epr-icn-${category}`, {
              [ClassNames.active]: category === activeCategory,
            })}
            style={{
              background: `url(${categoryIcon})`,
            }}
            key={categoryConfig.name}
            onClick={() => {
              setActiveCategory(category)
              scrollCategoryIntoView(categoryConfig.name)
            }}
            aria-label={categoryNameFromCategoryConfig(categoryConfig)}
          />
        )
      })}
    </div>
  )
}
