import AddIcon from '@mui/icons-material/Add'
import { Masonry } from '@mui/lab'
import imageCompression from 'browser-image-compression'
import { ChangeEvent, useContext } from 'react'
import { ActionType, BlobType, EditorDispatchContext, ShowcaseContext } from '../../../../context/editorContext'
import { PanelShowcase } from '../../../../gql/scalars'
import '../styles/panelBlock.css'

type PanelBlockProps = {
  showcase: (PanelShowcase & ShowcaseContext) | null | undefined
  keyProp: number
  tab: string
}

export function PanelBlock(props: PanelBlockProps) {
  const editorDispatch = useContext(EditorDispatchContext)

  const imageCompressionOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }

  function AddPanel() {
    const showcase = props.showcase
    console.log(props.showcase)
    let newPanel: any = {
      image: '',
      image_link: '',
      text: '',
    }

    if (showcase) {
      const showcaseUpdate = Object.assign({}, showcase, {
        items: showcase.items ? [...showcase.items, newPanel] : [newPanel],
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }

  function EditPanelText(e: ChangeEvent<HTMLTextAreaElement>, key: number) {
    const showcase = props.showcase

    if (showcase && showcase.items && showcase.items[key]) {
      let update = showcase.items[key]
      let panelUpdate: any = {
        image: update?.image,
        image_link: update?.image_link,
        text: e.target.value,
      }

      const showcaseUpdate = Object.assign({}, showcase, {
        items: [...showcase.items.slice(0, key), panelUpdate, ...showcase.items.slice(key + 1)],
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }

  function EditPanelLink(e: ChangeEvent<HTMLInputElement>, key: number) {
    const showcase = props.showcase

    if (showcase && showcase.items && showcase.items[key]) {
      let update = showcase.items[key]
      let panelUpdate: any = {
        image: update?.image,
        image_link: e.target.value,
        text: update?.text,
      }

      const showcaseUpdate = Object.assign({}, showcase, {
        items: [...showcase.items.slice(0, key), panelUpdate, ...showcase.items.slice(key + 1)],
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }

  function RemovePanel(key: number) {
    const showcase = props.showcase

    if (showcase && showcase.items && showcase.items[key]) {
      const showcaseUpdate = Object.assign({}, showcase, {
        items: [...showcase.items.slice(0, key), ...showcase.items.slice(key + 1)],
        blobs:
          showcase.blobs &&
          showcase.blobs
            .filter(item => item.imagePosition !== key)
            .map(item =>
              item.imagePosition && item.imagePosition > key
                ? { ...item, imagePosition: item.imagePosition - 1 }
                : { ...item }
            ),
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }

  async function imageUpload(e: ChangeEvent<HTMLInputElement>, key: number) {
    if (e.target.files != null && e.target.files[0].type.includes('image')) {
      let blob = e.target.files[0]
      const compressedFile = await imageCompression(blob, imageCompressionOptions)
      let output = URL.createObjectURL(compressedFile)
      let newBlob: BlobType = {
        blob: compressedFile,
        imagePosition: key,
        itemKey: undefined,
      }
      const showcase = props.showcase
      if (showcase && showcase.items && showcase.items[key]) {
        let update = showcase.items[key]
        let panelUpdate: any = {
          image: output,
          image_link: update?.image_link,
          text: update?.text,
        }

        const showcaseUpdate = Object.assign({}, showcase, {
          items: [...showcase.items.slice(0, key), panelUpdate, ...showcase.items.slice(key + 1)],
          blobs: showcase.blobs ? [...showcase.blobs.filter(item => item.imagePosition !== key), newBlob] : [newBlob],
        })

        editorDispatch({
          type: ActionType.secondLevelUpdate,
          payload: showcaseUpdate,
          firstLevelKey: props.tab,
          secondLevelKey: props.keyProp,
        })
      }
      e.target.value = ''
    }

    return
  }

  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Panel-Showcase-Container Panel-Block-Editor-Container">
          <Masonry columns={{ sm: 1, md: 2, lg: 3, xl: 3 }} sx={{ alignContent: 'center' }} spacing={2}>
            {props.showcase?.items &&
              props.showcase?.items.map(
                (item, key) =>
                  item && (
                    <div key={key} className="Panel-Showcase-Item">
                      <input
                        id={`Panel-Upload-Input-${props.tab}-${props.keyProp}-${key}`}
                        type="file"
                        accept="image/*"
                        onChange={e => imageUpload(e, key)}
                        hidden
                      />

                      {!item.image && (
                        <button
                          className="Add-Panel-Image-Button"
                          onClick={() =>
                            document.getElementById(`Panel-Upload-Input-${props.tab}-${props.keyProp}-${key}`)?.click()
                          }>
                          Add Image
                        </button>
                      )}
                      {item.image && (
                        <img
                          className="Panel-Showcase-Img-Editor"
                          src={item.image}
                          alt=""
                          onClick={() =>
                            document.getElementById(`Panel-Upload-Input-${props.tab}-${props.keyProp}-${key}`)?.click()
                          }
                        />
                      )}
                      <div className="Panel-Showcase-Text">
                        <span>
                          <b>Image Links To?</b>
                        </span>
                        <input
                          className="Panel-Showcase-Link-Input"
                          onChange={e => EditPanelLink(e, key)}
                          value={item.image_link ? item.image_link : ''}
                          placeholder="Link"
                        />
                        <span>
                          <b>Description</b> - Supports Markdown
                        </span>
                        <textarea
                          className="Panel-Showcase-Desc-Input Active-Scrollbar"
                          onChange={e => EditPanelText(e, key)}
                          value={item.text ? item.text : ''}
                          placeholder="Description"
                        />
                      </div>
                      <div className="Remove-Panel-Button-Container">
                        <button className="Remove-Panel-Button" onClick={() => RemovePanel(key)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  )
              )}
            <div className="Panel-Editor-Item-Add" onClick={AddPanel}>
              <AddIcon />
            </div>
          </Masonry>
        </div>
      </div>
    </>
  )
}
