import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../../context/editorContext'
import { VideoShowcase } from '../../../../gql/scalars'
import { linkToYoutubeId, linkToYoutubeJson } from '../../../../utils/converterUtils'
import './videoEmbedModal.css'

interface VideoEmbedModalProps {
  blockKey: number
  itemKey: number
  closeModal: () => void
  tab: string
  showcase: VideoShowcase
}

export function VideoEditorModal(props: VideoEmbedModalProps) {
  const editorState = useContext(EditorStateContext)
  const editorDispatch = useContext(EditorDispatchContext)
  const [videoLink, setVideoLink] = useState('https://www.youtube.com/watch?v=krlr_ucg2YU')
  const [videoID, setVideoID] = useState('krlr_ucg2YU')
  const [embedTitle, setEmbedTitle] = useState('')
  const [embedDesc, setEmbedDesc] = useState('')

  let youtube = true

  useEffect(() => {
    let item = props.showcase?.items && props.showcase?.items[props.itemKey]

    if (item) {
      item.video_id && setVideoID(item.video_id)
      item.embed_link && setVideoLink(item.embed_link)
      item.description && setEmbedDesc(item.description)
      item.title && setEmbedTitle(item.title)
    }
    // eslint-disable-next-line
  }, [editorState])

  const changeVideoInfo = useDebouncedCallback(async e => {
    let videoInfo: any = await linkToYoutubeJson(e.target.value)
    console.log(videoInfo)
    if (videoInfo) {
      setEmbedTitle(videoInfo.title)
      setEmbedDesc(videoInfo.author_name)
    }
  }, 1000)

  async function onLinkChange(e: ChangeEvent<HTMLInputElement>) {
    setVideoLink(e.target.value)
    setVideoID(linkToYoutubeId(e.target.value))

    if (e.target.value !== '') changeVideoInfo(e)
  }
  function onTitleChange(e: ChangeEvent<HTMLInputElement>) {
    setEmbedTitle(e.target.value)
  }
  function onDescChange(e: ChangeEvent<HTMLInputElement>) {
    setEmbedDesc(e.target.value)
  }

  function SaveShowcase() {
    let newShowcaseItem: any = {
      ...props.showcase,

      embed_link: videoLink,
      video_id: videoID,
      thumbnail: `http://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
      title: embedTitle,
      description: embedDesc,
      type: 'Youtube',
    }

    const showcase = props.showcase

    if (showcase && showcase.items) {
      const showcaseUpdate = Object.assign({}, showcase, {
        items: [...showcase.items.slice(0, props.itemKey), newShowcaseItem, ...showcase.items.slice(props.itemKey + 1)],
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.blockKey,
      })
    }

    props.closeModal()
  }

  function ToggleSection(sectionId: string) {
    let section = document.getElementById(sectionId)
    if (section) {
      section.classList.toggle('Editor-Block-Section-Open')
    }
  }

  /*Modal Controls*/
  /* ************************************************************************** */
  useEffect(() => {
    let drag = false
    let startOnModal = true

    function trackClick(e: any) {
      if (e.target.closest('#Modal-Inner') == null && !drag) {
        props.closeModal()
      }
      startOnModal = true
    }
    function setDragTrue() {
      if (startOnModal) {
        drag = true
      }
    }
    function setDragFalse(e: any) {
      drag = false
      if (e.target.closest('#Modal-Inner') == null) {
        startOnModal = false
      }
    }

    document.addEventListener('mousedown', setDragFalse, true)
    document.addEventListener('mousemove', setDragTrue, true)
    document.addEventListener('mouseup', trackClick, true)
    return () => {
      document.removeEventListener('mousedown', setDragFalse, true)
      document.removeEventListener('mousemove', setDragTrue, true)
      document.removeEventListener('mouseup', trackClick, true)
    }
    // eslint-disable-next-line
  }, [])

  /* ************************************************************************** */

  return (
    <>
      <div className="Image-Embed-Modal-Container-BG">
        <div id="Modal" className={'Modal-Container'}>
          <div className="Video-Embed-Modal-Container Active-Scrollbar" id="Modal-Inner">
            <div className="Video-Embed-Modal-Container-Inner">
              <h1>Video Embed Editor</h1>

              <section
                className={`Video-Modal-Editor-Section Editor-Block-Section-Open`}
                id={`Editor-Block-Section-Video-Editor-Modal-Title-Link`}>
                <div
                  className="Selection-Header-Alt"
                  onClick={() => ToggleSection(`Editor-Block-Section-Video-Editor-Modal-Title-Link`)}>
                  <ChevronRightIcon className="Selection-Header-Chevron" />
                  <span>{`Video Link`}</span>
                </div>
                <input
                  className="Video-Embed-Modal-Video-Link"
                  type="text"
                  placeholder="Set a link for the title text"
                  value={videoLink}
                  onChange={onLinkChange}
                />
              </section>

              <section className={`Video-Modal-Editor-Section`} id={`Editor-Block-Section-Video-Editor-Modal-Title`}>
                <div
                  className="Selection-Header-Alt"
                  onClick={() => ToggleSection(`Editor-Block-Section-Video-Editor-Modal-Title`)}>
                  <ChevronRightIcon className="Selection-Header-Chevron" />
                  <span>{`Title`}</span>
                </div>
                <input
                  placeholder="Add a title for your showcase"
                  className="Video-Embed-Modal-Video-Link"
                  value={embedTitle}
                  onChange={onTitleChange}
                />
              </section>

              <section
                className={`Video-Modal-Editor-Section`}
                id={`Editor-Block-Section-Video-Editor-Modal-Description`}>
                <div
                  className="Selection-Header-Alt"
                  onClick={() => ToggleSection(`Editor-Block-Section-Video-Editor-Modal-Description`)}>
                  <ChevronRightIcon className="Selection-Header-Chevron" />
                  <span>{`Description`}</span>
                </div>
                <input
                  className="Video-Embed-Modal-Video-Link"
                  type="text"
                  placeholder="Put a link here to embed a youtube or twitch video"
                  value={embedDesc}
                  onChange={onDescChange}
                />
              </section>

              <div className={`Video-Showcase-Item ${'Video-Showcase-Item-Youtube'}`}>
                <a className="Video-Showcase-Link" rel="noreferrer" href={`${videoLink}`} target="_blank">
                  {embedTitle}
                </a>
                <div className="Video-Showcase-Desc">{embedDesc}</div>

                <>
                  {youtube ? (
                    <iframe
                      className="Video-Showcase-Item-Embed"
                      src={`https://www.youtube.com/embed/${videoID}?&autoplay=1`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : !youtube ? (
                    <iframe
                      className="Video-Showcase-Item-Embed"
                      title="TwitchStream"
                      src={``}
                      allowFullScreen
                      scrolling="no"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    />
                  ) : (
                    <div>Video Embed Not Found</div>
                  )}
                </>
              </div>
            </div>
            <div className="Quick-Options-Button-Container">
              <div className="Quick-Options-Buttons">
                <button className="Quick-Options-Button-1" onClick={props.closeModal}>
                  Cancel
                </button>
                <button className="Quick-Options-Button-2" onClick={SaveShowcase}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
