import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useUpdateEmojiMutation, useDeleteEmojiMutation } from '../../../gql/generated/graphql'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface EmojiEditorItemProps {
  index: number
  url: string
  name: string
  itemId: string
  itemOwnerKey: string
  deleteEmoji: (key: string) => void
}

export function EmojiEditorItem(props: EmojiEditorItemProps) {
  const [updateEmojiMutation] = useUpdateEmojiMutation()
  const [deleteEmojiMutation] = useDeleteEmojiMutation()
  const [itemKey, setItemKey] = useState<string>('')
  const [itemName, setItemName] = useState<string>('')
  const [usedName, setUsedName] = useState<boolean>(false)

  const updateEmoji = useDebouncedCallback(async () => {
    let response = await updateEmojiMutation({
      variables: {
        key: (props.itemOwnerKey + itemName.charAt(0).toUpperCase() + itemName.slice(1)).trim().replaceAll(' ', '_'),
        searchKey: itemKey,
        name: itemName,
      },
    }).catch(err => {
      if (err.message.includes('Uniqueness violation.')) {
        setUsedName(true)
      }
    })
    if (response) {
      setUsedName(false)
      setItemKey(
        response.data?.update_emoji_by_pk?.key ??
          (props.itemOwnerKey + itemName.charAt(0).toUpperCase() + itemName.slice(1)).trim().replaceAll(' ', '_')
      )
    }
  }, 250)

  useEffect(() => {
    setItemKey(props.itemId)
  }, [props.itemId])

  useEffect(() => {
    setItemName(props.name)
  }, [props.name])

  async function deleteEmoji() {
    let response = await deleteEmojiMutation({
      variables: {
        searchKey: itemKey,
      },
    }).catch(err => {
      console.log(err)
    })
    if (response) {
      props.deleteEmoji(response.data?.delete_emoji_by_pk?.key ?? '')
    }
  }

  return (
    <>
      <div className="Emoji-Editor-Flex-Item" key={props.index}>
        <div className="Emoji-Editor-Flex-Item-Left">
          <img className="Emoji-Editor-Flex-Item-Image" src={props.url} alt="" />
        </div>
        <div className="Emoji-Editor-Flex-Item-ID Emoji-Editor-Flex-Item-Center">
          <input
            maxLength={15}
            className="Emoji-Editor-Input"
            spellCheck={false}
            value={itemName}
            onChange={e => {
              setItemName(e.target.value)
              updateEmoji()
            }}
          />
          {usedName && <span className="Emoji-Editor-Usedname">This name has been used</span>}
        </div>
        <div className="Emoji-Editor-Flex-Item-Right">
          :{(props.itemOwnerKey + itemName.charAt(0).toUpperCase() + itemName.slice(1)).trim().replaceAll(' ', '_')}:
          <div className="Emoji-Editor-Delete-Button" onClick={() => deleteEmoji()}>
            <DeleteOutlineIcon />
          </div>
        </div>
      </div>
    </>
  )
}
