import { useContext } from 'react'
import { CardinalStateContext } from '../../context/cardinal'

type ThemeManagerProps = {
  children: JSX.Element
}

export function ThemeManager({ children }: ThemeManagerProps) {
  const state = useContext(CardinalStateContext)
  return (
    <div
      className={`Theme-Default Theme-I-Like-Squares ${
        state.user?.app_theme_accent ? 'Theme-Accent-' + state.user?.app_theme_accent : ''
      }`}>
      {children}
    </div>
  )
}
