import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useState } from 'react'
import { VideoShowcase as GraphqlVideoShowcase } from '../../../gql/scalars'
import './styles/videoShowcase.css'

type VideoShowcaseProps = {
  videoShowcase: GraphqlVideoShowcase
}

export function VideoShowcase(props: VideoShowcaseProps) {
  const [embed0, setEmbed0] = useState(false)
  const [embed1, setEmbed1] = useState(false)
  const [embed2, setEmbed2] = useState(false)
  const [embed3, setEmbed3] = useState(false)

  function EmbedManager(embedKey: number) {
    switch (embedKey) {
      case 0:
        setEmbed0(true)
        break
      case 1:
        setEmbed1(true)
        break
      case 2:
        setEmbed2(true)
        break
      case 3:
        setEmbed3(true)
        break
      default:
        console.log(`%c Notice: Error embed with key ${embedKey} not found`, 'color: red')
    }
  }

  return (
    <>
      <div className="Profile-Custom-Block">
        <div>
          <div
            className={
              props.videoShowcase.items?.length === 1 ? `Video-Block-Container-Alt` : `Video-Showcase-Container`
            }>
            {props.videoShowcase?.items?.map(
              (showcase, key) =>
                showcase.video_id && (
                  <div
                    className={`Video-Showcase-Item ${
                      showcase?.type === 'Twitch'
                        ? 'Video-Showcase-Item-Twitch'
                        : showcase?.type === 'Youtube'
                        ? 'Video-Showcase-Item-Youtube'
                        : ''
                    }`}
                    key={key}>
                    <a
                      className="Video-Showcase-Link"
                      href={showcase?.embed_link ? showcase?.embed_link : ''}
                      target="_blank">
                      {showcase?.title}
                    </a>
                    <div className="Video-Showcase-Desc">{showcase?.description}</div>
                    {(embed0 && key === 0) ||
                    (embed1 && key === 1) ||
                    (embed2 && key === 2) ||
                    (embed3 && key === 3) ? (
                      <>
                        {showcase?.type === 'Youtube' ? (
                          <iframe
                            className="Video-Showcase-Item-Embed"
                            src={`https://www.youtube.com/embed/${showcase.video_id}?&autoplay=1`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        ) : showcase?.type === 'Twitch' ? (
                          <iframe
                            className="Video-Showcase-Item-Embed"
                            title="TwitchStream"
                            src={showcase?.embed_link ? showcase?.embed_link : ''}
                            allowFullScreen
                            scrolling="no"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                          />
                        ) : (
                          <div>Video Embed Not Found</div>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          className="Video-Showcase-Item-Image"
                          src={showcase?.thumbnail ? showcase?.thumbnail : ''}
                          onClick={() => EmbedManager(key)}
                        />
                        <div className="Video-Showcase-Item-Playbutton" onClick={() => EmbedManager(key)}>
                          <PlayArrowIcon />
                        </div>
                      </>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </>
  )
}
