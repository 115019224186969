import { SocialLink, SocialProvider } from '../gql/scalars'
import DiscordIcon from '../svg/discord.svg'
import FacebookIcon from '../svg/facebook.svg'
import InstagramIcon from '../svg/instagram.svg'
import KoFiIcon from '../svg/ko-fi.svg'
import LinkChainIcon from '../svg/linkchain.svg'
import PatreonIcon from '../svg/patreon.svg'
import SpotifyIcon from '../svg/spotify.svg'
import StreamElementsIcon from '../svg/streamelements.svg'
import TiktokIcon from '../svg/tiktok.svg'
import TwitchIcon from '../svg/twitch.svg'
import TwitterIcon from '../svg/twitter.svg'
import YoutubeIcon from '../svg/youtube.svg'

export function buildSocialLink(url: string): SocialLink {
  const provider = getProviderFromUrl(url)
  const icon = getProviderIcon(provider)

  return {
    url: url,
    icon: icon,
    provider: provider,
  }
}

function getProviderFromUrl(url: string): SocialProvider {
  for (const provider in SocialProvider) {
    if (url.toLocaleLowerCase().includes(provider.toLocaleLowerCase())) {
      return provider as SocialProvider
    }
  }

  return SocialProvider.Other
}

export function getProviderIcon(socialProvider: SocialProvider | undefined) {
  switch (socialProvider) {
    case SocialProvider.Youtube:
      return YoutubeIcon
    case SocialProvider.Twitch:
      return TwitchIcon
    case SocialProvider.Discord:
      return DiscordIcon
    case SocialProvider.TikTok:
      return TiktokIcon
    case SocialProvider.Twitter:
      return TwitterIcon
    case SocialProvider.Facebook:
      return FacebookIcon
    case SocialProvider.StreamElements:
      return StreamElementsIcon
    case SocialProvider.Spotify:
      return SpotifyIcon
    case SocialProvider.Patreon:
      return PatreonIcon
    case SocialProvider.KoFi:
      return KoFiIcon
    case SocialProvider.Instagram:
      return InstagramIcon
    case SocialProvider.Other:
      return LinkChainIcon
    default:
      return ''
  }
}
