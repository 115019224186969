import { useContext, useMemo } from 'react'
import {
  NewPostNotificationSubscription,
  NewPostNotificationSubscriptionVariables,
  Order_By,
  useNewPostNotificationSubscription,
} from '../../generated/graphql'
import { BrowsingState, CardinalStateContext } from '../../../context/cardinal'
import { SubscriptionHookOptions } from '@apollo/client'
import { PostFilterType } from '../../filter'

export function useNewPostNotification(filterType: PostFilterType, filterValue?: string) {
  const { user, browsingState } = useContext(CardinalStateContext)

  const subscriptionOptions: SubscriptionHookOptions<
    NewPostNotificationSubscription,
    NewPostNotificationSubscriptionVariables
  > = useMemo(() => {
    const commonOptions: SubscriptionHookOptions<
      NewPostNotificationSubscription,
      NewPostNotificationSubscriptionVariables
    > = {
      skip: browsingState !== BrowsingState.Authenticated,
      variables: {
        limit: 1,
        offset: 0,
        order_by: { created_at: Order_By.Desc },
      },
    }
    switch (filterType) {
      case PostFilterType.Following:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: user?.handle
              ? {
                  _or: [
                    { owner: { followers: { follower_handle: { _eq: user?.handle } } }, type: { _eq: 'post' } },
                    {
                      hashtags: { hashtagName: { hashtag_followers: { follower_handle: { _eq: user?.handle } } } },
                      type: { _eq: 'post' },
                    },
                  ],
                }
              : {},
          },
        }
      default:
        return commonOptions
    }
  }, [user?.handle, filterType, filterValue])

  return useNewPostNotificationSubscription(subscriptionOptions)
}
