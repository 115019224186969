import './styles/search.css'

import { useSearchUserLazyQuery, useSearchPostsByUserLazyQuery, User } from '../gql/generated/graphql'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import Twemoji from 'react-twemoji'
import { FeedItem } from '../components/feed/feedItem'
import { FeedType } from '../components/feed/feed'
import { CardinalStateContext } from '../context/cardinal'
import { Loading } from '../components/loading/loadingExports'
import { ListProfileItem } from '../components/profile/display/listProfileItem'

export function Search() {
  const navigate = useNavigate()
  const { search_field } = useParams()
  const { user } = useContext(CardinalStateContext)
  const [searchUser] = useSearchUserLazyQuery()
  const [searchPostsByUser, { data }] = useSearchPostsByUserLazyQuery()
  const [searchInput, setSearchInput] = useState('')
  const [userResults, setUserResults] = useState([])
  const [postResults, setPostResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [showNoResults, setShowNoResults] = useState(false)

  async function search() {
    let userData: any = await searchUser({
      variables: {
        searchInput: searchInput,
        currentUserHandle: user?.handle ?? '',
      },
    })
    setUserResults(userData.data.user)

    let postData: any = await searchPostsByUser({
      variables: {
        searchInput: searchInput,
        currentUserHandle: user?.handle ? user.handle : '',
      },
    })
    setPostResults(postData.data.post)

    if (postData.data.post.length === 0 && userData.data.user.length === 0) setShowNoResults(true)

    setLoading(false)
  }

  const runSearch = useDebouncedCallback(() => {
    if (searchInput.length > 0) {
      search()
      setLoading(true)
      setShowNoResults(false)
    } else {
      setUserResults([])
      setPostResults([])
    }
  }, 200)

  useEffect(() => {
    if (search_field) {
      setSearchInput(search_field)
      runSearch()
    }
  }, [search_field, runSearch])

  useEffect(() => {
    document.getElementById('Search-Input')?.focus()
  }, [])

  const searchPostItems = useMemo(() => {
    return data?.post.map((post, index) => {
      return (
        <FeedItem
          id={post.id}
          timestamp={post.created_at}
          owner={post.owner as User}
          text={post.text}
          gifUrl={post.gif_url}
          imageUrls={post.image_urls}
          videoUrl={post.video_url}
          replyCount={post.replies_aggregate.aggregate?.count}
          voteCount={post.votes_aggregate.aggregate?.count}
          shareCount={0} //TODO: Grab from shared.
          voted={post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle}
          bookmarked={post.bookmarked_posts.length > 0 && post.bookmarked_posts[0]?.owner_handle === user?.handle}
          itemType={FeedType.Post}
          key={index}
        />
      )
    })
  }, [data, user?.handle])

  return (
    <div className="Search-Container">
      <div className="Search-Input-Container">
        <input
          value={searchInput}
          autoComplete="off"
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search.."
          className="Search-Input"
          id="Search-Input"
          type="search"
          onKeyDown={e => {
            let key = e.code || e.key
            if (key === 'Enter' || key === 'NumpadEnter')
              navigate(searchInput.length > 0 ? `/search/${searchInput}` : '/search', { replace: true })
          }}
          spellCheck={false}
        />
      </div>
      {loading && <Loading />}
      {!loading && (
        <div className="Search-Output-Container">
          {showNoResults && (
            <div className="Search-Output-No-Results">
              <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                Sorry, no results were found. Try searching for something else or perhaps git gud.{' '}
                <span className="emoji">
                  <img
                    src="https://project11301826-developer.s3.amazonaws.com/public/kappa.gif"
                    className="post-img"
                    alt=""
                  />
                </span>
              </Twemoji>
            </div>
          )}
          {userResults.length > 0 && (
            <div className="Search-Output-Users">
              <div className="Search-Output-Users-Header">Users:</div>
              {userResults.map((item: User, key: number) => (
                <div key={key} className="Search-Output-User-Item">
                  <ListProfileItem user={item} index={key} disableSocialLinks />
                </div>
              ))}
            </div>
          )}
          {postResults.length > 0 && (
            <div className="Search-Output-Posts">
              <div className="Search-Output-Users-Header">Related Posts:</div>
              {searchPostItems}
              {/* {postResults.map((item: any, key: number) => (
                <FeedItem
                  id={item.id}
                  timestamp={item.created_at}
                  owner={item.owner as User}
                  text={item.text}
                  gifUrl={item.gif_url}
                  imageUrls={item.image_urls}
                  videoUrl={item.video_url}
                  commentCount={item.comments_aggregate.aggregate?.count}
                  voteCount={item.votes_aggregate.aggregate?.count}
                  shareCount={0} //TODO: Grab from shared.
                  voted={item.votes.length > 0 && item.votes[0]?.owner_handle === user?.handle}
                  bookmarked={
                    item.bookmarked_posts.length > 0 && item.bookmarked_posts[0]?.owner_handle === user?.handle
                  }
                  itemType={FeedType.Post}
                  key={key}
                />
              ))} */}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
