import './emojiPicker.css'
import CloseIcon from '@mui/icons-material/Close'
import { useContext, useMemo } from 'react'
import { CardinalStateContext } from '../../../context/cardinal'
import EmojiPickerElement, {
  Categories,
  EmojiStyle,
  SkinTonePickerLocation,
  SuggestionMode,
  Theme,
} from '../../../utils/emojiPicker'
import { CategoryConfig } from '../../../utils/emojiPicker/config/categoryConfig'
import { ClickAwayListener } from '../../modal/clickAwayListener'

interface EmojiPickerProps {
  addEmoji: any
  modalPosition?: any
  closeModal: () => void
}

export function EmojiPicker({ addEmoji, modalPosition, closeModal }: EmojiPickerProps) {
  const { isMobileView, customEmoteCategories } = useContext(CardinalStateContext)

  let height: number = window.innerHeight
  let width: number = window.innerWidth
  let modalTop =
    height / 2 > modalPosition?.height ? `${modalPosition?.height + 40}px` : `${modalPosition?.height - 501}px`
  let modalLeft = width / 2 > modalPosition?.width ? `${modalPosition?.width}px` : `${modalPosition?.width - 461}px`

  const picker = useMemo(() => {
    return (
      <EmojiPickerElement
        onEmojiClick={addEmoji}
        suggestedEmojisMode={SuggestionMode.FREQUENT}
        height="100%"
        width="100%"
        lazyLoadEmojis={false}
        autoFocusSearch={!isMobileView}
        theme={Theme.DARK}
        emojiStyle={EmojiStyle.TWITTER}
        skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
        categories={[
          {
            name: 'Recently Used',
            category: Categories.SUGGESTED,
          },
          ...(customEmoteCategories.sort((a, b) => a.name.localeCompare(b.name)) as CategoryConfig[]),
          {
            name: 'Smiles & Emotions',
            category: Categories.SMILEYS_PEOPLE,
          },
          {
            name: 'Animals & Nature',
            category: Categories.ANIMALS_NATURE,
          },
          {
            name: 'Food & Drink',
            category: Categories.FOOD_DRINK,
          },
          {
            name: 'Travel & Places',
            category: Categories.TRAVEL_PLACES,
          },
          {
            name: 'Activities',
            category: Categories.ACTIVITIES,
          },
          {
            name: 'Objects',
            category: Categories.OBJECTS,
          },
          {
            name: 'Symbols',
            category: Categories.SYMBOLS,
          },
          {
            name: 'Flags',
            category: Categories.FLAGS,
          },
        ]}
      />
    )
  }, [customEmoteCategories])

  return (
    <>
      <ClickAwayListener onClickAway={() => closeModal()} id="Emoji-Picker">
        <div
          className="Sayso-Emoji-Picker-Container"
          style={{
            position: 'fixed',
            top: isMobileView ? '50px' : modalTop,
            left: isMobileView ? '0px' : modalLeft,
          }}>
          <>
            <div className="Exit-Gif-Modal" onClick={() => closeModal()}>
              <CloseIcon />
            </div>
            {picker}
          </>
        </div>
      </ClickAwayListener>
    </>
  )
}
