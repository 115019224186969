import { SocialLink } from '../../../gql'
import { getProviderIcon } from '../../../utils/statusUtils'
import './styles/profileQuickSocials.css'

interface ProfileQuickSocialsProps {
  socialLinks: SocialLink[]
  size?: number
}

export function ProfileQuickSocials({ socialLinks, size }: ProfileQuickSocialsProps) {
  return (
    <div className="Profile-Socials">
      {socialLinks.slice(0, 5).map((socialLink: SocialLink, key: number) => (
        <a
          style={{ width: size, height: size }}
          href={socialLink?.url}
          target="_blank"
          rel="noreferrer"
          className="Social-Icon-Container"
          key={key}>
          <div className={`Social-Icon ${socialLink?.provider}-Icon`}>
            <img alt="" src={getProviderIcon(socialLink?.provider) ?? socialLink?.icon} />
          </div>
        </a>
      ))}
    </div>
  )
}
