import { LexicalComposer } from '@lexical/react/LexicalComposer'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import Twemoji from 'react-twemoji'
import { User } from '../../../gql/generated/graphql'
import { useSettings } from '../../../utils/lexical/context/SettingsContext'
import SeisoNodes from '../../../utils/lexical/nodes/SeisoNodes'
import SeisoEditorTheme from '../../../utils/lexical/themes/SeisoEditorTheme'
import { ProfileAvatar } from '../../profile/display/profileAvatar'
import { CreateFeedItemForm } from '../createFeedItemForm'
import { FeedType } from '../feed'
import sanitizeHTML from 'sanitize-html'
import { sanitizeHtmlConf } from '../../../utils/constants'
import './createPostModal.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { QuoteRepostItem } from '../utils/quoteRepostItem'
import { VerifiedCheck } from '../../profile/display/verifiedCheck'
import { ClickAwayListener } from '../../modal/clickAwayListener'
import CloseIcon from '@mui/icons-material/Close'

interface CreatePostModalProps {
  closeModal: () => void
  threadId?: string
  feedType: FeedType
  replyData?: { id: string; timestamp: string; owner?: User; text?: string | null }
  quoteRepost?: boolean
  quoteRepostData?: {
    id: string
    timestamp: string
    owner?: User
    text?: string | null
    image_urls?: (string | null)[]
    video_url?: string | null
    gif_url?: string | null
  }
}

export function CreatePostRouteModal() {
  const location = useLocation()
  const navigate = useNavigate()

  const ref = useRef()
  const replyData = (location.state && location.state.replyData) ?? undefined
  const quoteRepostData = (location.state && location.state.quoteRepostData) ?? undefined
  const feedType = (location.state && location.state.feedType) ?? FeedType.Post

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })

  useEffect(() => {
    console.log(location.state)
    if (location.state === null || location.state.background === null) {
      navigate(`/`, { state: { from: location } })
    }
  }, [])

  const {
    settings: { emptyEditor },
  } = useSettings()

  const initialConfig = {
    editorState: emptyEditor ? undefined : undefined,
    namespace: 'Seiso-Modal-Create-Post',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <>
      <div id="Modal" className="Create-Post-Modal-Container-BG">
        <ClickAwayListener onClickAway={() => navigate(-1)} id="Create-Post-Route-Modal">
          <div className="Create-Post-Modal-Container">
            <div onClick={() => navigate(-1)} className="Create-Post-Modal-Mobile-Close">
              <CloseIcon />
            </div>

            {replyData && (
              <div className="Reply-Post-Container">
                <div className="Post-Left Reply-Post-Left">
                  <ProfileAvatar disableStatus disableLink user={replyData?.owner} />
                  <div className="Reply-Line-Indicator" />
                </div>
                <div className="Post-Right">
                  <div className="Post-Header">
                    <div className="Post-Info">
                      <div className="Post-User-Info">
                        <span className="Post-Displayname">
                          <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                            {replyData?.owner?.username} <VerifiedCheck />
                          </Twemoji>
                        </span>
                        <span className="Post-Username">@{replyData?.owner?.handle}</span>
                      </div>
                    </div>
                    <div className="Post-Timestamp">
                      <time dateTime={`${replyData?.timestamp}`}>
                        {moment().diff(moment(replyData?.timestamp), 'days') >= 1
                          ? moment(replyData?.timestamp).format('MMM Do')
                          : moment(replyData?.timestamp).fromNow()}
                      </time>
                    </div>
                  </div>

                  <div className="Post-Content">
                    <div className="Post-Text">
                      <Twemoji options={{ className: 'Post-Emoji' }}>
                        {replyData?.text && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: replyData?.text ? sanitizeHTML(replyData?.text, sanitizeHtmlConf) : '',
                            }}
                          />
                        )}
                      </Twemoji>
                    </div>
                  </div>
                  {replyData && (
                    <div className="Reply-Indicator Modal-Reply-Indicator">
                      Replying to{' '}
                      <NavLink onClick={e => e.stopPropagation()} to={`/profile/${replyData.owner?.handle}`}>
                        @{replyData.owner?.handle}
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            )}

            <LexicalComposer initialConfig={initialConfig}>
              <CreateFeedItemForm
                quoteRepostId={quoteRepostData ? quoteRepostData.id : null}
                quoteRepostModalItem={
                  quoteRepostData ? (
                    <QuoteRepostItem
                      id={quoteRepostData?.id}
                      timestamp={quoteRepostData.timestamp}
                      text={quoteRepostData.text}
                      imageUrls={quoteRepostData.image_urls}
                      gifUrl={quoteRepostData.gif_url}
                      videoUrl={quoteRepostData.video_url}
                      owner={quoteRepostData.owner}
                    />
                  ) : null
                }
                modal
                feedType={feedType}
                closeFormModal={() => navigate(-1)}
                threadId={(replyData && replyData.id) ?? undefined}
              />
            </LexicalComposer>
          </div>
        </ClickAwayListener>
      </div>
    </>
  )
}
