import type {
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
} from 'lexical'

import { TextNode } from 'lexical'

export type SerializedEmojiNode = Spread<
  {
    emojiID: string
    emojiRef: string
    type: 'emoji'
  },
  SerializedTextNode
>

export class EmojiNode extends TextNode {
  __emojiID: string
  __emojiRef: string

  static getType(): string {
    return 'emoji'
  }

  static clone(node: EmojiNode): EmojiNode {
    return new EmojiNode(node.__emojiID, node.__emojiRef, node.__key)
  }

  constructor(emojiID: string, emojiRef: string, key?: NodeKey) {
    super(emojiID, key)
    this.__emojiID = emojiID
    this.__emojiRef = emojiRef
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('span')
    const img = document.createElement('img')
    img.src = this.__emojiRef
    img.className = 'post-img'
    img.alt = this.__emojiID
    img.title = this.__emojiID
    dom.className = 'emoji'
    dom.appendChild(img)
    return dom
  }

  // updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
  //   const inner = dom.firstChild
  //   console.log(inner)
  //   if (inner === null) {
  //     console.log('true')
  //     return true
  //   }
  //   console.log('false')
  //   super.updateDOM(prevNode, inner as HTMLElement, config)
  //   return false
  // }

  static importJSON(serializedNode: SerializedEmojiNode): EmojiNode {
    const node = $createEmojiNode(serializedNode.emojiID, serializedNode.emojiID)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => {
        if (node instanceof HTMLImageElement && node.className === 'post-img') {
          return { conversion: convertEmojiElement, priority: 2 }
        }
        return null
      },
    }
  }

  exportJSON(): SerializedEmojiNode {
    return {
      ...super.exportJSON(),
      emojiRef: this.getEmojiRef(),
      emojiID: this.getEmojiID(),
      type: 'emoji',
    }
  }

  getEmojiID(): string {
    const self = this.getLatest()
    return self.__emojiID
  }
  getEmojiRef(): string {
    const self = this.getLatest()
    return self.__emojiRef
  }
}
function convertEmojiElement(domNode: Node): DOMConversionOutput {
  let node = null
  if (domNode instanceof HTMLImageElement) {
    if (domNode.className === 'post-img')
      node = $createEmojiNode(domNode.getAttribute('alt') || '', domNode.getAttribute('src') || '')
  }

  return { node }
}

export function $isEmojiNode(node: any): node is EmojiNode {
  return node instanceof EmojiNode
}

export function $createEmojiNode(emojiID: string, emojiRef: string): EmojiNode {
  return new EmojiNode(emojiID, emojiRef).setMode('token')
}
