import { useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import './styles/explore.css'
export function Explore() {
  const { explore_tab } = useParams()

  useEffect(() => {
    const element = document.getElementById('App-Browser')
    if (element) {
      element.className = 'App-Browser App-Browser-Width-Large'
    }
  }, [])
  return (
    <div className="Explore-Page-Container">
      <div className="Explore-Tabs-Container">
        <div className="Explore-Tabs-Container-Inner">
          <NavLink to="/explore/for-you" className={`Explore-Tab${explore_tab === 'for-you' ? '-Active' : ''}`}>
            For you
          </NavLink>
          <NavLink to="/explore/trending" className={`Explore-Tab${explore_tab === 'trending' ? '-Active' : ''}`}>
            Trending
          </NavLink>
          <NavLink to="/explore/streaming" className={`Explore-Tab${explore_tab === 'streaming' ? '-Active' : ''}`}>
            Streaming
          </NavLink>
          <NavLink to="/explore/game-news" className={`Explore-Tab${explore_tab === 'game-news' ? '-Active' : ''}`}>
            Game News
          </NavLink>
        </div>
      </div>
    </div>
  )
}
