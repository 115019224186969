import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp'
import SquareSharpIcon from '@mui/icons-material/SquareSharp'
import ViewAgendaSharpIcon from '@mui/icons-material/ViewAgendaSharp'
import { useContext, useEffect, useState } from 'react'
import { ActionType, EditorDispatchContext } from '../../../../context/editorContext'
import { VideoShowcase } from '../../../../gql/scalars'
import { VideoEditorModal } from '../editorModals/videoEditorModal'
import '../../showcases/styles/videoShowcase.css'
import '../styles/videoBlock.css'
import { useLocation, useNavigate } from 'react-router-dom'

type VideoBlockProps = {
  showcase: VideoShowcase
  keyProp: number
  tab: string
}

export function VideoBlock(props: VideoBlockProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const editorDispatch = useContext(EditorDispatchContext)

  const [modalShown, setModalShown] = useState<boolean>(false)
  const [modalKey, setModalKey] = useState<number>(0)

  const [showcaseDisplayCount, setShowcaseDisplayCount] = useState<number>()

  useEffect(() => {
    props.showcase?.items ? setShowcaseDisplayCount(props.showcase.items.length) : setShowcaseDisplayCount(1)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    props.showcase?.items ? setShowcaseDisplayCount(props.showcase.items.length) : setShowcaseDisplayCount(1)
  }, [props.showcase])

  useEffect(() => {
    let showcaseParams = props.showcase?.items ? props.showcase?.items : []
    if (showcaseParams && showcaseDisplayCount) {
      while (showcaseDisplayCount > showcaseParams.length) {
        // TODO: FIX THIS showcaseParams = [...showcaseParams, null]
        showcaseParams = [
          ...showcaseParams,
          {
            link: '',
            type: 'Youtube',
            title: '',
            video_id: '',
            thumbnail: '',
            embed_link: '',
            description: '',
          },
        ]
      }
      if (showcaseDisplayCount < showcaseParams.length) {
        showcaseParams = showcaseParams.slice(0, showcaseDisplayCount)
      }

      const showcase = props.showcase

      if (showcase) {
        const showcaseUpdate = Object.assign({}, showcase, {
          items: showcaseParams,
        })

        editorDispatch({
          type: ActionType.secondLevelUpdate,
          payload: showcaseUpdate,
          firstLevelKey: props.tab,
          secondLevelKey: props.keyProp,
        })
      }
    }
    // eslint-disable-next-line
  }, [showcaseDisplayCount])

  return (
    <>
      <div className="Profile-Custom-Block">
        {modalShown && (
          <VideoEditorModal
            closeModal={CloseModal}
            blockKey={props.keyProp}
            itemKey={modalKey}
            tab={props.tab}
            showcase={props.showcase}
          />
        )}
        <div className="Display-Count-Options">
          <button
            className={`Display-Count-Option ${showcaseDisplayCount === 1 ? 'Display-Count-Option-Active' : ''}`}
            onClick={() => setShowcaseDisplayCount(1)}>
            <div>
              <SquareSharpIcon />
            </div>
          </button>
          <button
            className={`Display-Count-Option ${showcaseDisplayCount === 2 ? 'Display-Count-Option-Active' : ''}`}
            onClick={() => setShowcaseDisplayCount(2)}>
            <div>
              <ViewAgendaSharpIcon style={{ transform: 'rotate(90deg)' }} />
            </div>
          </button>
          <button
            className={`Display-Count-Option ${showcaseDisplayCount === 4 ? 'Display-Count-Option-Active' : ''}`}
            onClick={() => setShowcaseDisplayCount(4)}>
            <div>
              <GridViewSharpIcon />
            </div>
          </button>
        </div>
        <div
          className={
            props.showcase?.items && props.showcase.items?.length === 1
              ? `Video-Block-Container-Alt`
              : `Video-Showcase-Container`
          }>
          {props.showcase?.items &&
            props.showcase?.items.map((showcase, key) =>
              showcase && showcase.embed_link ? (
                <div
                  className={`Video-Showcase-Item ${
                    showcase?.type === 'Twitch'
                      ? 'Video-Showcase-Item-Twitch'
                      : showcase?.type === 'Youtube'
                      ? 'Video-Showcase-Item-Youtube'
                      : ''
                  }`}
                  key={key}>
                  <a
                    className="Video-Showcase-Link"
                    href={showcase?.embed_link ? showcase.embed_link : ''}
                    rel="noreferrer"
                    target="_blank">
                    {showcase?.title}
                  </a>
                  <div className="Video-Showcase-Desc">{showcase?.description}</div>
                  <img
                    className="Video-Showcase-Item-Image"
                    alt=""
                    src={showcase?.thumbnail ? showcase.thumbnail : ''}
                  />
                  <div
                    className="Video-Block-Item-Editbutton"
                    onClick={() =>
                      navigate('/showcase/video-showcase-editor', {
                        state: {
                          blockKey: props.keyProp,
                          itemKey: key,
                          tab: props.tab,
                          showcase: props.showcase,
                          background: location,
                        },
                      })
                    }>
                    <EditIcon />
                  </div>
                </div>
              ) : (
                <div
                  className="Video-Editor-Item-Add"
                  onClick={() =>
                    navigate('/showcase/video-showcase-editor', {
                      state: {
                        blockKey: props.keyProp,
                        itemKey: key,
                        tab: props.tab,
                        showcase: props.showcase,
                        background: location,
                      },
                    })
                  }
                  key={key}>
                  <AddIcon />
                </div>
              )
            )}
        </div>
      </div>
    </>
  )

  function CloseModal() {
    setModalShown(false)
  }

  function OpenModal(itemKey: number) {
    setModalKey(itemKey)
    setModalShown(true)
  }
}
