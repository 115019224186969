import React, { useState, useRef, useEffect } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { cropPreview } from './cropImagePreview'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import CropLandscapeIcon from '@mui/icons-material/CropLandscape'
import CropPortraitIcon from '@mui/icons-material/CropPortrait'
import CloseIcon from '@mui/icons-material/Close'

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

interface CropImageModalProps {
  image: string | null
  imageKey: number
  updateFunction: (image: string, key: number, blob: File) => void
  closeModal: () => void
}

export function CropImageModal(props: CropImageModalProps) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement | undefined>(undefined)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const scale = 1
  const rotate = 0

  const [aspect, setAspect] = useState<number>(1 / 1)
  const [currentAspect, setCurrentAspect] = useState<number | undefined>(1 / 1)
  // const [previewImg, setPreviewImg] = useState<any>(undefined)

  // function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     setCrop(undefined)
  //     const reader = new FileReader()
  //     reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useEffect(() => {
    fetch(props.image!)
      .then(res => res.blob())
      .then(blob => {
        let objectURL = URL.createObjectURL(blob)
        setImgSrc(objectURL)
      })
      .catch(_error => {
        console.log('Notice - Unable to obtain image file(going with backup method this may or may not work)')
        props.image && setImgSrc(props.image)
      })
  }, [props.image])

  function aspectManager(aspectKey: number) {
    if (imgRef.current) {
      const { width, height } = imgRef.current
      switch (aspectKey) {
        case 0:
          setCurrentAspect(1 / 1)
          setAspect(1 / 1)
          setCrop(centerAspectCrop(width, height, 1 / 1))
          break
        case 1:
          setCurrentAspect(16 / 9)
          setAspect(16 / 9)
          setCrop(centerAspectCrop(width, height, 16 / 9))
          break
        case 2:
          setCurrentAspect(9 / 16)
          setAspect(9 / 16)

          setCrop(centerAspectCrop(width, height, 9 / 16))

          break
        default:
          console.log(`%c Notice: Error aspect ratio with key ${aspectKey} not found`, 'color: red')
      }
    }
  }

  function saveCrop() {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      cropPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate).then(newImage => {
        if (props.updateFunction) {
          props.updateFunction(newImage.previewUrl, props.imageKey, newImage.newBlob)
        }
      })
    }
  }

  function handleToggleAspectClick() {
    if (currentAspect) {
      setCurrentAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setCurrentAspect(aspect)
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }
  return (
    <div className="Crop-Image-Modal-Container" id="Modal-Inner">
      <CloseIcon className="Modal-Close-Icon" onClick={props.closeModal} />
      <div className="Crop-Controls">
        <button onClick={handleToggleAspectClick} style={{ marginRight: '10px' }}>{`Toggle aspect ${
          currentAspect ? 'off' : 'on'
        }`}</button>
        <button onClick={saveCrop} style={{ marginRight: '10px' }}>
          Save
        </button>
      </div>

      {Boolean(imgSrc) && (
        <div className="Image-Cropper">
          <ReactCrop
            ruleOfThirds
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => setCompletedCrop(c)}
            aspect={currentAspect}>
            <img
              className="Crop-Image-Modal-Preview"
              ref={imgRef}
              alt=""
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      )}
      <div className="Crop-Icons-Container">
        <div onClick={() => aspectManager(0)}>
          <CropSquareIcon className={currentAspect === 1 / 1 ? 'Crop-Icon-Active' : 'Crop-Icon'} />
        </div>
        <div onClick={() => aspectManager(1)}>
          <CropLandscapeIcon className={currentAspect === 16 / 9 ? 'Crop-Icon-Active' : 'Crop-Icon'} />
        </div>
        <div onClick={() => aspectManager(2)}>
          <CropPortraitIcon className={currentAspect === 9 / 16 ? 'Crop-Icon-Active' : 'Crop-Icon'} />
        </div>
      </div>
    </div>
  )
}
