import { Masonry } from '@mui/lab'
import './styles/panelShowcase.css'
// import { ThemeProvider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ReactMarkdown from 'react-markdown'
import { PanelShowcase as GraphqlPanelShowcase } from '../../../gql/scalars'

type PanelShowcaseProps = {
  panelShowcase: GraphqlPanelShowcase
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
    xxxl: true
  }
}

export function PanelShowcase(props: PanelShowcaseProps) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 450,
        md: 600,
        lg: 1100,
        xl: 1500,
        xxl: 2000,
        xxxl: 2560,
      },
    },
  })

  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Panel-Showcase-Container">
          {props.panelShowcase && props.panelShowcase.items && props.panelShowcase.items.length > 0 && (
            <ThemeProvider theme={theme}>
              <Masonry
                columns={{ xs: 1, sm: 2, md: 2, lg: 3, xxl: 4 }}
                defaultColumns={4}
                sx={{ alignContent: 'center', width: 'auto' }}
                spacing={2}>
                {props.panelShowcase.items.map(
                  (item, key) =>
                    item &&
                    (item.image || item.image_link || item.text) && (
                      <div key={key} className="Panel-Showcase-Item">
                        {item.image && !item.image_link && (
                          <img className="Panel-Showcase-Img" src={item.image} alt="" />
                        )}
                        {item.image && item.image_link && (
                          <a className="Panel-Showcase-Img-Link" href={item.image_link} target="_blank">
                            <img className="Panel-Showcase-Img" src={item.image} alt="" />
                          </a>
                        )}
                        <div className="Panel-Showcase-Text">
                          <ReactMarkdown children={item.text ? item.text : ''} />
                        </div>
                      </div>
                    )
                )}
              </Masonry>
            </ThemeProvider>
          )}
        </div>
      </div>
    </>
  )
}
