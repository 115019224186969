import { useEffect } from 'react'
import { ROADMAP_TEXT } from '../constants/tempData'
import './styles/roadMap.css'
import ReactMarkdown from 'react-markdown'
export function RoadMap() {
  useEffect(() => {
    const element = document.getElementById('App-Browser')
    if (element) {
      element.className = 'App-Browser App-Browser-Width-Small'
    }
  }, [])
  return (
    <div className="Roadmap-Container">
      <div className="Roadmap-Text-Container">
        <div className="Roadmap-Text">
          <ReactMarkdown children={ROADMAP_TEXT.text} />
        </div>
      </div>
    </div>
  )
}
