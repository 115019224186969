import './repostMenu.css'
import { useContext } from 'react'

import { Post_Insert_Input, useCreatePostMutation } from '../../../gql/generated/graphql'
import { CardinalStateContext } from '../../../context/cardinal'
import { PostType } from '../feed'
import { ClickAwayListener } from '../../modal/clickAwayListener'

interface RepostMenuProps {
  closeMenuFunction: () => void
  openQuotePostModal: () => void
  postId: string
}

export function RepostMenu({ closeMenuFunction, openQuotePostModal, postId }: RepostMenuProps) {
  const { user } = useContext(CardinalStateContext)
  const [createPostMutation] = useCreatePostMutation()

  async function handleRepost(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      const input: Post_Insert_Input = {
        owner_handle: user.handle,
        repost_id: postId,
        type: PostType.Post,
      }

      await createPostMutation({ variables: { object: input, hashtagObjects: [] } }).catch(err => console.log(err))
    }
  }

  return (
    <>
      <div className="Modal-Block-Interaction-BG" />
      <ClickAwayListener className="Repost-Menu" onClickAway={() => closeMenuFunction()} id="Repost-Menu">
        <div className="Repost-Menu-Options">
          <div
            className="Repost-Menu-Option"
            onClick={e => {
              e.stopPropagation()
              handleRepost(e)
              closeMenuFunction()
            }}>
            Share
          </div>
          <div
            className="Repost-Menu-Option"
            onClick={e => {
              e.stopPropagation()
              closeMenuFunction()
              openQuotePostModal()
            }}>
            Quote Repost
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}
