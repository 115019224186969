import { useContext, useEffect, useMemo } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './styles/notifications.css'
import { useNavigate, useParams } from 'react-router'
import {
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  useGetNotificationsLazyQuery,
  useSetNotificationSeenMutation,
  User,
} from '../gql/generated/graphql'
import { LazyQueryHookOptions } from '@apollo/client'
import { CardinalStateContext } from '../context/cardinal'
import { ProfileAvatar } from '../components/profile/display/profileAvatar'
import Twemoji from 'react-twemoji'
import moment from 'moment'
import sanitizeHTML from 'sanitize-html'
import { sanitizeHtmlConf } from '../utils/constants'
import { Loading } from '../components/loading/loadingExports'
import { NavLink } from 'react-router-dom'
import { NavTabs, TabType } from '../navigation/navTabs'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'

export function Notifications() {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })

  const { notification_type } = useParams()
  const navigate = useNavigate()
  const { user } = useContext(CardinalStateContext)
  const [getNotifications, { data, loading }] = useGetNotificationsLazyQuery()
  const [setSeen] = useSetNotificationSeenMutation()

  let notificationType =
    notification_type === 'all'
      ? {}
      : {
          _eq:
            notification_type === 'mentions'
              ? 'mention'
              : notification_type === 'messages'
              ? 'message'
              : notification_type === 'comments'
              ? 'comment'
              : '',
        }

  let queryOptions: LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables> = {
    variables: {
      notificationFilter: {
        owner_handle: { _eq: user?.handle },
        type: notificationType,
      },
      currentUserHandle: user?.handle ?? '',
    },
  }

  useEffect(() => {
    if (user) getNotifications(queryOptions)
    // eslint-disable-next-line
  }, [notification_type, user])

  const mentionPostItems = useMemo(() => {
    return data?.user_notifications.map((item, index) => {
      if (item.mentionPost) {
        return (
          <div
            className={`Notification-Mention-Post-Container ${
              !item.seen && 'Notification-Mention-Post-Container-Unread'
            }`}
            key={index}
            onClick={() => {
              setSeen({ variables: { notification_id: item.id } })
              navigate(`/post/${item.mentionPost?.owner_handle}/${item.mentionPost?.id}`)
            }}>
            <div className="Post-Left">
              {item.mentionPost.owner && (
                <ProfileAvatar disableStatus disableLink user={item.mentionPost.owner as User} />
              )}
            </div>
            <div className="Post-Right">
              <div className="Post-Header">
                <div className="Post-Info">
                  <div className="Post-User-Info">
                    <span className="Post-Displayname">
                      <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                        {item.mentionPost.owner?.username}
                      </Twemoji>
                    </span>
                    <span className="Post-Username">@{item.mentionPost.owner?.handle}</span>
                  </div>
                </div>
                <div className="Post-Timestamp">
                  <time dateTime={`${item.created_at}`}>
                    {moment().diff(moment(item.created_at), 'days') >= 1
                      ? moment(item.mentionPost.created_at).format('MMM Do')
                      : moment(item.mentionPost.created_at).fromNow()}
                  </time>
                </div>
              </div>

              <div className="Post-Content">
                <div className="Post-Text">
                  <Twemoji options={{ className: 'Post-Emoji' }}>
                    {item.mentionPost.text && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.mentionPost.text ? sanitizeHTML(item.mentionPost.text, sanitizeHtmlConf) : '',
                        }}
                      />
                    )}
                  </Twemoji>
                </div>
              </div>
            </div>
          </div>
        )
      } else return null
    })
    // eslint-disable-next-line
  }, [data])

  const tabs: TabType[] = [
    {
      path: `/notifications/all`,
      tabName: 'All',
      tabID: 'all',
    },
    {
      path: `/notifications/mentions`,
      tabName: 'Mentions',
      tabID: 'mentions',
    },
    {
      path: `/notifications/replies`,
      tabName: 'Replies',
      tabID: 'replies',
    },
  ]
  console.log(notification_type)
  return (
    <div className="Notifications-Container">
      <div className="Notifications-Header">
        <NavBackButtonHeader title={'Notifications'} />

        <NavTabs tabs={tabs} activeTab={notification_type ?? ''} />
      </div>
      {loading ? (
        <Loading />
      ) : data && data?.user_notifications.length > 0 ? (
        <div className="Notifications-Body">{mentionPostItems}</div>
      ) : (
        <div className="Notifications-No-Items-Message">No Notifications</div>
      )}
    </div>
  )
}
