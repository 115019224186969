import './styles/termsOfService.css'

export function TermsOfService() {
  return (
    <div className="TermsOfService-Container">
      <div className="TermsOfService-Text-Container">
        <h1>Terms of Service for Sayso</h1>
        <p>
          Welcome to Sayso, a social networking website that allows users to share their thoughts and ideas with the
          world. These Terms of Service ("Terms" or "ToS") govern your use of Sayso's website and services
          (collectively, the "Service"). By using the Service, you agree to be bound by these Terms, as well as Sayso's
          Privacy Policy.
        </p>
        <ol>
          <li>
            <h3>Use of the Service</h3>
            <p>
              You may use the Service only for lawful purposes and in accordance with these Terms. You are responsible
              for all of your activity in connection with the Service. Sayso reserves the right to terminate your access
              to the Service at any time without notice for any reason, including without limitation your violation of
              these Terms.
            </p>
          </li>
          <li>
            <h3>User Content</h3>
            <p>
              The Service allows you to post content, including text, photos, videos, and other materials. You retain
              all rights in, and are solely responsible for, the content you post on the Service. By posting content on
              the Service, you grant Sayso a non-exclusive, transferable, sub-licensable, royalty-free, worldwide
              license to use, copy, modify, create derivative works based on, distribute, publicly display, publicly
              perform, and otherwise exploit in any manner such content in all formats and distribution channels now
              known or hereafter devised (including in connection with the Service and Sayso's business and on
              third-party sites and services), without further notice to or consent from you, and without the
              requirement of payment to you or any other person or entity.
            </p>
          </li>
          <li>
            <h3>Prohibited Conduct</h3>
            <p>You agree that you will not use the Service to:</p>
            <ul>
              <li>violate any law or regulation;</li>
              <li>infringe any intellectual property or other right of any third party;</li>
              <li>
                transmit any material that is unlawful, obscene, defamatory, libelous, threatening, harassing, abusive,
                or hateful;
              </li>
              <li>transmit any unsolicited or unauthorized advertising, promotional materials, or spam;</li>
              <li>
                impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with any
                person or entity;
              </li>
              <li>
                engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Service, or
                which, as determined by Sayso, may harm Sayso or users of the Service or expose them to liability.
              </li>
            </ul>
          </li>
          <li>
            <h3>Intellectual Property Rights</h3>
            <p>
              The Service and its entire contents, features, and functionality (including but not limited to all
              information, software, text, displays, images, video, and audio, and the design, selection, and
              arrangement thereof) are owned by Sayso, its licensors, or other providers of such material and are
              protected by United States and international copyright, trademark, patent, trade secret, and other
              intellectual property or proprietary rights laws.
            </p>
          </li>
          <li>
            <h3>Disclaimer of Warranties</h3>
            <p>
              THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, NON-INFRINGEMENT, OR OTHER VIOLATION OF RIGHTS.
            </p>
          </li>
          <li>
            <h3>Limitation of Liability</h3>
            <p>
              IN NO EVENT SHALL SAYSO, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
              OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
              CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
              SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
              LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
              CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
            </p>
          </li>
          <li>
            <h3>Indemnification</h3>
            <p>
              You agree to indemnify, defend, and hold harmless Sayso, its affiliates, licensors, and service providers,
              and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
              successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses,
              costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your
              violation of these Terms or your use of the Service.
            </p>
          </li>
          <li>
            <h3>Termination</h3>
            <p>
              Sayso may terminate these Terms or suspend or terminate your access to the Service at any time, with or
              without notice, for any reason or no reason, including if Sayso believes that you have violated or acted
              inconsistently with these Terms. Upon any such termination, your right to access and use the Service will
              immediately cease.
            </p>
          </li>
          <li>
            <h3>Governing Law and Dispute Resolution</h3>
            <p>
              These Terms and your use of the Service are governed by and construed in accordance with the laws of the
              State of California, without giving effect to any principles of conflicts of law. Any dispute arising out
              of or relating to these Terms or the Service will be resolved through binding arbitration in San
              Francisco, California, in accordance with the rules of the American Arbitration Association. The
              arbitration will be conducted by a single arbitrator, and judgment on the award rendered by the arbitrator
              may be entered in any court having jurisdiction thereof.
            </p>
          </li>
          <li>
            <h3>Miscellaneous</h3>
            <p>
              These Terms constitute the entire agreement between you and Sayso with respect to the Service and
              supersede all prior or contemporaneous communications and proposals, whether oral or written, between you
              and Sayso. Sayso's failure to enforce any right or provision of these Terms shall not constitute a waiver
              of such right or provision. If any provision of these Terms is found to be unenforceable or invalid, that
              provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall
              otherwise remain in full force and effect and enforceable.
            </p>
          </li>

          <li>
            <h3>Changes to the Terms</h3>
            <p>
              Sayso reserves the right to modify these Terms at any time, effective upon posting of the modified Terms
              on the Service. Your continued use of the Service after any such changes shall constitute your consent to
              such changes.
            </p>
          </li>
          <li>
            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about these Terms or our website, please contact us at
              support@sayso.gg.
            </p>
          </li>
        </ol>
        <p>
          The headings and section titles in these Terms are for convenience only and have no legal or contractual
          effect. These Terms are binding upon and will inure to the benefit of both parties and their respective
          successors and assigns. You may not assign or transfer these Terms, by operation of law or otherwise, without
          Sayso's prior written consent. Sayso may assign or transfer these Terms, in whole or in part, without
          restriction.
        </p>
        <p>Thank you for using Sayso!</p>
      </div>
    </div>
  )
}
