import { User } from '../gql/generated/graphql'
import { Categories } from '../utils/emojiPicker'

export type CustomEmoteCategoryType = {
  category: Categories
  name: string
  icon: string
  images: {
    name: string
    search: string[]
    imageUrl: string
  }[]
}

export function mergeEmojis(user: User) {
  let emojiArr: any[] = []
  let categories: CustomEmoteCategoryType[] = []

  if (user?.owned_emojis) {
    for (let j = 0; j < user?.owned_emojis.length; j++) {
      emojiArr.push(user?.owned_emojis[j])
    }
  }
  if (user?.following) {
    for (let i = 0; i < user?.following.length; i++) {
      if (user?.following[i].following_user && user?.following[i].following_user.owned_emojis) {
        for (let j = 0; j < user?.following[i].following_user.owned_emojis.length; j++) {
          emojiArr.push(user?.following[i].following_user.owned_emojis[j])
        }
      }
    }
  }
  if (emojiArr) {
    let category: CustomEmoteCategoryType
    for (let i = 0; i < emojiArr.length; i++) {
      if (emojiArr[i] && categories.findIndex(item => item.name === emojiArr[i].owner.handle) === -1) {
        category = {
          name: emojiArr[i].owner.handle,
          category: Categories.CUSTOM,
          icon: emojiArr[i].owner.profile_image,
          images: [],
        }
        categories.push(category)
      }
    }

    categories = [...new Set(categories)]
  }
  if (categories.length > 0) {
    for (let i = 0; i < categories.length; i++) {
      emojiArr.map(item => {
        if (item.owner.handle === categories[i].name) {
          categories[i].images.push({
            imageUrl: item.url,
            name: item.key,
            search: [item.key],
          })
        }
      })
    }
  }

  return categories
}
