import type { Klass, LexicalNode } from 'lexical'

// import { HashtagNode } from '@lexical/hashtag'
// import { AutoLinkNode, LinkNode } from '@lexical/link'
import { MarkNode } from '@lexical/mark'
import { OverflowNode } from '@lexical/overflow'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'

import { EmojiNode } from './EmojiNode'
import { TwemojiNode } from './TwemojiNode'

import { KeywordNode } from './KeywordNode'
import { MentionNode } from './MentionNode'
import { HashtagNode } from './HashtagNode'
import { LinkNode, AutoLinkNode } from './LinkNode'

const SeisoNodes: Array<Klass<LexicalNode>> = [
  HashtagNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  MentionNode,
  EmojiNode,
  TwemojiNode,
  KeywordNode,
  HorizontalRuleNode,
  MarkNode,
]

export default SeisoNodes
