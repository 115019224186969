import { LazyQueryHookOptions } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ActionType, BrowsingState, CardinalDispatchContext, CardinalStateContext } from '../context/cardinal'
import {
  ListBookmarksQuery,
  ListBookmarksQueryVariables,
  useListBookmarksLazyQuery,
  User,
} from '../gql/generated/graphql'
import './styles/bookmarks.css'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'

export function Bookmarks() {
  const navigate = useNavigate()
  const dispatch = useContext(CardinalDispatchContext)
  const { user, browsingState, homeScrollPosition } = useContext(CardinalStateContext)
  const [listBookmarks, { data, loading, fetchMore }] = useListBookmarksLazyQuery()
  let queryOptions: LazyQueryHookOptions<ListBookmarksQuery, ListBookmarksQueryVariables> = {
    variables: {
      limit: 20,
      postFilter: { id: { _eq: 'c2851e42-a926-4f54-ae3e-ce2508aa31ad' } },
      offset: data?.bookmarked_posts.length ?? 0,
      currentUserHandle: browsingState === BrowsingState.Authenticated ? user?.handle : '',
    },
  }

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      listBookmarks(queryOptions)
    }
  }, [browsingState, listBookmarks])

  useEffect(() => {
    let appScrollableElement = document.getElementById('App')
    function handleOnScrollFeed() {
      if (appScrollableElement) {
        dispatch({
          type: ActionType.SetHomeScrollPosition,
          payload: appScrollableElement.scrollTop,
        })
      }
    }
    appScrollableElement?.addEventListener('scroll', handleOnScrollFeed)
    return () => {
      appScrollableElement?.removeEventListener('scroll', handleOnScrollFeed)
    }
  }, [dispatch])

  const bookmarkItems = useMemo(() => {
    return data?.bookmarked_posts.map((postData, index) => {
      let post = postData.post
      return (
        <FeedItem
          id={post.id}
          timestamp={post.created_at}
          owner={post.owner as User}
          text={post.text}
          gifUrl={post.gif_url}
          imageUrls={post.image_urls}
          videoUrl={post.video_url}
          replyCount={post.replies_aggregate.aggregate?.count}
          voteCount={post.votes_aggregate.aggregate?.count}
          shareCount={0} //TODO: Grab from shared.
          voted={post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle}
          bookmarked={post.bookmarked_posts.length > 0 && post.bookmarked_posts[0]?.owner_handle === user?.handle}
          itemType={FeedType.Post}
          key={index}
        />
      )
    })
  }, [data])

  return (
    <div className="Thread-Container">
      <NavBackButtonHeader title="Bookmarks" />
      <Feed
        scrollPosition={homeScrollPosition}
        loading={loading}
        items={bookmarkItems}
        feedType={FeedType.Post}
        fetchMore={() => fetchMore(queryOptions)}
        disableCreateFeedItem
        noItemsMessage="You don't have any bookmarks."
      />
    </div>
  )
}
