import './postMenu.css'
import { useState } from 'react'
import { ReportModal } from '../../reports/reportModal'
import { ClickAwayListener } from '../../modal/clickAwayListener'

interface PostMenuProps {
  closeMenuFunction: () => void
  deletePostFunction: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  user: any
  postOwner: string
  postId: string
}

export function PostMenu({ closeMenuFunction, deletePostFunction, user, postOwner, postId }: PostMenuProps) {
  const [reportModalOpen, setReportModalOpen] = useState(false)

  return (
    <>
      <div className="Modal-Block-Interaction-BG" />
      <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Post-Menu">
        <div className="Post-Menu" id="Post-Menu">
          {reportModalOpen && <ReportModal post postId={postId} closeModal={closeMenuFunction} />}
          <div className="Post-Menu-Options">
            {postOwner === user.handle ? (
              <div
                className="Post-Menu-Option Post-Menu-Option-Red"
                onClick={e => {
                  deletePostFunction(e)
                  closeMenuFunction()
                }}>
                Delete Post
              </div>
            ) : (
              <>
                <div className="Post-Menu-Option" onClick={() => closeMenuFunction()}>
                  Block (@{postOwner})
                </div>
                <div className="Post-Menu-Option Post-Menu-Option-Red" onClick={() => setReportModalOpen(true)}>
                  Report Post
                </div>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}
