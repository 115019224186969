import { Auth } from 'aws-amplify'
import { useContext, useEffect, useRef, useState } from 'react'
import { FIFTY_MINUTES_IN_MS } from '../utils/constants'
import { AuthStateContext } from '../context/authContext'

interface AuthorizationHeader {
  authorization: String
}

export function useAuthHeader() {
  const [header, setHeader] = useState<AuthorizationHeader | null>()
  const { cognitoUser } = useContext(AuthStateContext)
  const refreshTimer = useRef<NodeJS.Timer | undefined>()

  async function setHeaderWithToken() {
    try {
      const session = await Auth.currentSession()
      setHeader({ authorization: `Bearer ${session.getIdToken().getJwtToken()}` })

      return session
    } catch (error) {
      setHeader(null)
      console.log(error)
    }
  }

  useEffect(() => {
    if (cognitoUser) {
      setHeaderWithToken().then(session => {
        if (refreshTimer.current) {
          console.log('Stoping token refresh interval')
          clearInterval(refreshTimer.current)
        }

        if (session) {
          console.log('Starting token refresh interval')
          refreshTimer.current = setInterval(() => {
            setHeaderWithToken().then(() => {
              console.log('Refreshing expired token')
            })
          }, FIFTY_MINUTES_IN_MS)
        }
      })
    }

    return () => {
      if (refreshTimer.current) {
        console.log('Stoping token refresh interval')
        clearInterval(refreshTimer.current)
      }
    }
  }, [cognitoUser, refreshTimer])

  return header
}
