import { useMemo, useState } from 'react'
import { AuthenticationFormField, ITabFormField } from './auth_interfaces'

export function AuthFormField({ formFieldName, type, placeholder }: ITabFormField) {
  const [inputText, setInputText] = useState<string>('')

  const inputId = useMemo(() => {
    // Set an id for password fields to use for validation
    if (
      formFieldName === AuthenticationFormField.Password ||
      formFieldName === AuthenticationFormField.ConfirmPassword
    ) {
      return formFieldName
    }

    return undefined
  }, [formFieldName])

  // TODO: Styles
  return (
    <>
      <p className="Auth-Body-Placeholder-Text">{placeholder}</p>
      <input
        value={inputText}
        autoComplete="off"
        onChange={e => setInputText(e.target.value)}
        // placeholder={placeholder}
        type={type}
        spellCheck={false}
        name={formFieldName}
        id={inputId}
        required
      />
    </>
  )
}
