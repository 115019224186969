import './styles/saveIndicator.css'
import { useEffect } from 'react'

export interface SaveIndicatorPropTypes {
  show?: boolean
  reset?: any
  save?: any
}

export function SaveIndicator(props: SaveIndicatorPropTypes) {
  let element = document.getElementById('Profile-Options-Save-Indicator-Inner')

  const updateClass = () => {
    element!.classList.remove('Shake-Warning-Indicator')
  }

  useEffect(() => {
    if (element) element.addEventListener('animationend', updateClass)

    return () => element?.removeEventListener('animationend', updateClass)
  }, [element])

  return (
    <>
      <div className={`Profile-Options-Save-Indicator ${props.show ? 'Profile-Options-Save-Indicator-Slide-In' : ''}`}>
        <div className="Profile-Options-Save-Indicator-Inner" id="Profile-Options-Save-Indicator-Inner">
          <p>Notice - you appear to have unsaved changes!</p>
          <div className="Profile-Options-Save-Indicator-Buttons">
            <button className="Profile-Options-Save-Indicator-Button-1" onClick={props.reset}>
              Reset
            </button>
            <button className="Profile-Options-Save-Indicator-Button-2" onClick={props.save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
