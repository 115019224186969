import React, { useEffect, useReducer } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { AuthenticationFlowState } from '../components/authentication'

export enum AuthenticationState {
  Authenticated = 'authenticated',
  Anonymous = 'anonymous',
  Loading = 'loading',
}

const AuthState: {
  cognitoUser: CognitoUser | undefined
  flowState: AuthenticationFlowState
  authenticationState: AuthenticationState
} = {
  cognitoUser: undefined,
  flowState: AuthenticationFlowState.None,
  authenticationState: AuthenticationState.Loading,
}

export enum AuthContextActionType {
  SetCognitoUser = 'setCognitoUser',
  SetFlowState = 'setFlowState',
  SetAuthenticationState = 'setAuthenticationState',
}

type Action =
  | { type: AuthContextActionType.SetCognitoUser; payload: typeof AuthState.cognitoUser }
  | { type: AuthContextActionType.SetFlowState; payload: typeof AuthState.flowState }
  | { type: AuthContextActionType.SetAuthenticationState; payload: typeof AuthState.authenticationState }

export const AuthStateContext = React.createContext(AuthState)
export const AuthDispatchContext = React.createContext((_payload: Action) => {})

export const AuthReducer = (state: typeof AuthState, action: Action) => {
  switch (action.type) {
    case AuthContextActionType.SetCognitoUser:
      return {
        ...state,
        cognitoUser: action.payload,
      }
    case AuthContextActionType.SetFlowState:
      return {
        ...state,
        flowState: action.payload,
      }
    case AuthContextActionType.SetAuthenticationState:
      return {
        ...state,
        authenticationState: action.payload,
      }
    default:
      console.error(`Notice: Unhandled action: ${action}`)
      return state
  }
}

type AuthProps = {
  children: JSX.Element
}

export const AuthProvider = ({ children }: AuthProps) => {
  const [state, dispatch] = useReducer(AuthReducer, AuthState)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        dispatch({ type: AuthContextActionType.SetCognitoUser, payload: user })
        dispatch({ type: AuthContextActionType.SetAuthenticationState, payload: AuthenticationState.Authenticated })
      })
      .catch(() => {
        dispatch({ type: AuthContextActionType.SetCognitoUser, payload: undefined })
        dispatch({ type: AuthContextActionType.SetAuthenticationState, payload: AuthenticationState.Anonymous })
      })

    Hub.listen('auth', ({ payload }) => {
      const { event } = payload
      switch (event) {
        case 'signIn':
        case 'autoSignIn':
          const user = payload.data as CognitoUser
          dispatch({ type: AuthContextActionType.SetCognitoUser, payload: user })
          dispatch({ type: AuthContextActionType.SetAuthenticationState, payload: AuthenticationState.Authenticated })
          break
        case 'autoSignIn_failure':
          dispatch({ type: AuthContextActionType.SetFlowState, payload: AuthenticationFlowState.FailedAutoSignUp })
          break
        case 'signOut':
          dispatch({
            type: AuthContextActionType.SetCognitoUser,
            payload: undefined,
          })
          dispatch({ type: AuthContextActionType.SetAuthenticationState, payload: AuthenticationState.Anonymous })
          break
        default:
          break
      }
    })
  }, [])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}
