import AddIcon from '@mui/icons-material/Add'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'
import { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import { ProfileTab, Showcase, ShowcaseType } from '../../../gql/scalars'
import { TabNameModal } from './editorModals/tabNameModal'
import { BlockEditor } from './blockEditor'
import './styles/profileShowcaseEditor.css'

export function ProfileShowcaseEditor() {
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)
  const [displayAddOptions, setDisplayAddOptions] = useState<boolean>()
  const [displayTabNameModal, setDisplayTabNameModal] = useState<boolean>()
  const [tabKey, setTabKey] = useState<number | undefined>()
  const [modalPosition, setModalPosition] = useState<object>({})
  const { showcase_tab } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !state.profileTabs?.filter(tab => tab?.name?.toLocaleLowerCase() === showcase_tab?.toLocaleLowerCase())[0] &&
      showcase_tab?.toLocaleLowerCase() !== 'home'
    ) {
      navigate('/settings/showcases')
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (
      !state.profileTabs?.filter(tab => tab?.name?.toLocaleLowerCase() === showcase_tab?.toLocaleLowerCase())[0] &&
      showcase_tab
    ) {
      navigate('/settings/showcases')
    }
    // eslint-disable-next-line
  }, [state])

  return (
    <>
      <h1 className="Tab-Header-Mobile">SHOWCASES</h1>
      <div className="Profile-Manager">
        {displayTabNameModal && (
          <TabNameModal
            modalFunction={tabKey && state.profileTabs && state.profileTabs[tabKey] ? EditTab : AddNewTab}
            closeModal={() => {
              setTabKey(undefined)
              setDisplayTabNameModal(false)
            }}
            modalPosition={modalPosition}
            preExistingName={
              tabKey && state.profileTabs && state.profileTabs[tabKey] ? state.profileTabs![tabKey]!.name : undefined
            }
          />
        )}
        <div className="Profile-Content-Container-Editor">
          <div className="Profile-Editor-Tabs-Container">
            <div className="Profile-Editor-Tabs-Container-Inner">
              <NavLink
                to={`/settings/showcases`}
                className={!showcase_tab ? 'Profile-Editor-Tab-Active' : 'Profile-Editor-Tab'}>
                Home
              </NavLink>
              {state &&
                state.profileTabs
                  ?.filter(tab => tab?.name !== 'Home')
                  .map((item, key) => (
                    <div className="Profile-Editor-Tabs-List-Item" key={key}>
                      <NavLink
                        to={`/settings/showcases/${item?.name?.toLocaleLowerCase()}`}
                        className={
                          showcase_tab === item?.name?.toLocaleLowerCase()
                            ? 'Profile-Editor-Tab-Active'
                            : 'Profile-Editor-Tab'
                        }>
                        {item?.name}
                      </NavLink>
                      <div className="Profile-Editor-Tabs-List-Item-Options">
                        {key > 0 && (
                          <div
                            className="Profile-Editor-Tabs-List-Item-Option"
                            onClick={() => changeTabPosition(key, key + 1)}>
                            <ChevronLeftIcon />
                          </div>
                        )}
                        <div
                          className="Profile-Editor-Tabs-List-Item-Option"
                          onClick={e => {
                            setTabKey(key + 1)
                            let currentTargetRect: DOMRect = e.currentTarget.getBoundingClientRect()
                            setModalPosition({
                              height: currentTargetRect.y,
                              width: currentTargetRect.x,
                            })
                            setDisplayTabNameModal(true)
                          }}>
                          <CreateIcon style={{ fontSize: '1.7rem', marginBottom: '1px' }} />
                        </div>
                        <div className="Profile-Editor-Tabs-List-Item-Option" onClick={() => DeleteTab(key + 1)}>
                          <CloseIcon />
                        </div>
                        {state.profileTabs?.length && key < state.profileTabs?.length - 2 && (
                          <div
                            className="Profile-Editor-Tabs-List-Item-Option"
                            onClick={() => changeTabPosition(key + 2, key + 1)}>
                            <ChevronRightIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              <div
                className="Edit-Tabs-Icon"
                onClick={e => {
                  let currentTargetRect: DOMRect = e.currentTarget.getBoundingClientRect()
                  setModalPosition({
                    height: currentTargetRect.y,
                    width: currentTargetRect.x,
                  })
                  setDisplayTabNameModal(true)
                }}>
                <AddIcon />
              </div>
            </div>
          </div>
          {state.profileTabs &&
            state.profileTabs
              ?.filter(
                tab =>
                  tab?.name?.toLocaleLowerCase() === showcase_tab ||
                  (!showcase_tab && tab?.name?.toLocaleLowerCase() === 'home')
              )
              .map(tab =>
                tab?.showcases?.map((showcase: any, key: number) => (
                  <BlockEditor
                    showcase={showcase}
                    key={key}
                    keyProp={key}
                    tab={tab!.name!}
                    tabCount={tab.showcases ? tab.showcases.length : 0}
                  />
                ))
              )}

          {state.profileTabs &&
            state.profileTabs?.filter(
              tab =>
                tab?.name?.toLocaleLowerCase() === showcase_tab ||
                (!showcase_tab && tab?.name?.toLocaleLowerCase() === 'home')
            )[0]?.showcases &&
            state.profileTabs?.filter(
              tab =>
                tab?.name?.toLocaleLowerCase() === showcase_tab ||
                (!showcase_tab && tab?.name?.toLocaleLowerCase() === 'home')
            )[0]!.showcases?.length! <= 8 && (
              <div
                className={displayAddOptions ? 'Add-New-Showcase-Button-Alt' : 'Add-New-Showcase-Button'}
                onClick={() => (displayAddOptions ? null : setDisplayAddOptions(true))}>
                {displayAddOptions ? (
                  <div className="Add-New-Showcase-Options">
                    <button onClick={() => AddNewShowcase(ShowcaseType.Info)}>Add Info Showcase</button>
                    <button onClick={() => AddNewShowcase(ShowcaseType.Images)}>Add Image Showcase</button>
                    <button onClick={() => AddNewShowcase(ShowcaseType.Video)}>Add Video Embed Showcase</button>
                    <button onClick={() => AddNewShowcase(ShowcaseType.Gallery)}>Add Gallery Showcase</button>
                    <button onClick={() => AddNewShowcase(ShowcaseType.Panel)}>Add Panel Showcase</button>
                    <button onClick={() => AddNewShowcase(ShowcaseType.Socials)}>Add Socials Showcase</button>
                    <button onClick={() => setDisplayAddOptions(false)}>Cancel</button>
                  </div>
                ) : (
                  <AddIcon />
                )}
              </div>
            )}
        </div>
      </div>
    </>
  )

  function changeTabPosition(indexToReplace: number, index: number) {
    if (state.profileTabs && state.profileTabs[index]! && state.profileTabs[indexToReplace]) {
      let showcaseToReplace = state.profileTabs[indexToReplace]

      if (showcaseToReplace) {
        dispatch({
          type: ActionType.firstLevelUpdatePosition,
          payload: state.profileTabs[index]!,
          keyToReplace: indexToReplace,
        })

        dispatch({
          type: ActionType.firstLevelUpdatePosition,
          payload: showcaseToReplace,
          keyToReplace: index,
        })
      }
    }
  }

  function AddNewTab(tabName: string) {
    if (tabName.length > 0) {
      setDisplayTabNameModal(false)
      let checkedName = tabName
      let counter = 1

      while (state.profileTabs?.filter(tab => tab?.name === checkedName)[0]) {
        checkedName = tabName + counter
        counter++
      }

      let newTab: ProfileTab = {
        name: checkedName,
        showcases: [],
      }
      dispatch({
        type: ActionType.firstLevelAdd,
        payload: newTab,
      })
    }
  }

  function EditTab(tabName: string) {
    setDisplayTabNameModal(false)
    if (tabKey && state.profileTabs) {
      let checkedName = tabName
      let counter = 1

      while (
        state.profileTabs?.filter(tab => tab?.name === checkedName)[0] &&
        state.profileTabs[tabKey] &&
        state.profileTabs[tabKey]!.name !== tabName
      ) {
        checkedName = tabName + counter
        counter++
      }
      const tab = state.profileTabs[tabKey]
      const tabUpdate = Object.assign({}, tab, {
        name: checkedName.toLocaleLowerCase(),
      })

      dispatch({
        type: ActionType.firstLevelUpdate,
        payload: tabUpdate,
        firstLevelKey: tabKey,
      })

      if (showcase_tab?.toLocaleLowerCase() === state.profileTabs[tabKey]!.name?.toLocaleLowerCase()) {
        navigate(`/settings/showcases/${tabName.toLocaleLowerCase()}`)
      }
      setTabKey(undefined)
    }
  }

  function DeleteTab(key: number) {
    if (state.profileTabs && showcase_tab?.toLocaleLowerCase() === state.profileTabs[key]!.name?.toLocaleLowerCase()) {
      navigate(`/settings/showcases`)
    }
    dispatch({
      type: ActionType.firstLevelDelete,
      firstLevelKey: key,
    })
  }

  function AddNewShowcase(type: ShowcaseType) {
    setDisplayAddOptions(false)
    let newShowcase: Showcase = {
      title: '',
      description: '',
      type: type,
    }
    dispatch({
      type: ActionType.secondLevelAdd,
      payload: newShowcase,
      firstLevelKey: showcase_tab ? showcase_tab : 'Home',
    })
  }
}
