import { ChangeEvent, useContext, useEffect } from 'react'
import { ActionType, EditorDispatchContext } from '../../../../context/editorContext'
import { InfoShowcase } from '../../../../gql/scalars'
import '../styles/infoBlock.css'

type InfoBlockProps = {
  showcase: InfoShowcase | null | undefined
  keyProp: number
  tab: string
}

export function InfoBlock(props: InfoBlockProps) {
  const editorDispatch = useContext(EditorDispatchContext)

  useEffect(() => {
    let item = document.getElementById(`Profile-Info-Block-${props.keyProp}`)
    item?.setAttribute('style', 'height: auto;')
    item?.setAttribute('style', 'height:' + item?.scrollHeight + 'px;overflow-y:hidden;')
  }, [])

  return (
    <>
      <div className="Profile-Custom-Block">
        <div>
          <span className="Info-Header-Text">
            <b>Info</b> - Supports Markdown
          </span>
          <textarea
            id={`Profile-Info-Block-${props.keyProp}`}
            onChange={onChangeTextarea}
            className="Info-Block-Text"
            value={props.showcase?.text ? props.showcase.text : ''}
          />
        </div>
      </div>
    </>
  )

  function onChangeTextarea(e: ChangeEvent<HTMLTextAreaElement>) {
    console.log('running')
    e.currentTarget.setAttribute('style', 'height: auto;')
    e.currentTarget.setAttribute('style', 'height:' + e.currentTarget.scrollHeight + 'px;overflow-y:hidden;')

    const showcase = props.showcase

    if (showcase) {
      console.log(showcase)
      const showcaseUpdate = Object.assign({}, showcase, {
        text: e.target.value,
      })

      console.log(showcaseUpdate)

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }
}
