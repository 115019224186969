import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { CardinalStateContext } from '../../../context/cardinal'
import { EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import imageCompression from 'browser-image-compression'
import { EmojiEditorItem } from './emojiEditorItem'
import { uploadToStorage } from '../../../utils/cloudflareR2'
import { useCreateEmojiMutation, Emoji_Insert_Input } from '../../../gql/generated/graphql'
import { imageCompressionOptions } from '../../../utils/constants'
import { LoadingModal } from '../../loading/loadingModal'

import './styles/emojiEditor.css'

export function EmojiEditor() {
  const [createEmojiMutation] = useCreateEmojiMutation()
  const [emojiUniqueKey, setEmojiUniqueKey] = useState<string>('')
  const [emojiList, setEmojiList] = useState<any[]>([])
  const [uploading, setUploading] = useState<boolean>(false)
  let limit = 10
  const cardinalState = useContext(CardinalStateContext)

  useEffect(() => {
    setEmojiUniqueKey(cardinalState.user?.handle ?? '')
    setEmojiList(cardinalState.user?.owned_emojis ?? [])
  }, [])

  function emojiDelete(key: string) {
    let index = emojiList.findIndex(item => item.key === key)
    setEmojiList([...emojiList.slice(0, index), ...emojiList.slice(index + 1)])
  }

  async function emojiUpload(e: ChangeEvent<HTMLInputElement>) {
    setUploading(true)
    if (e.target.files != null) {
      if (e.target.files[0].type.includes('image')) {
        let blob = e.target.files[0]
        const compressedFile = e.target.files[0].type.includes('image/gif')
          ? null
          : await imageCompression(blob, imageCompressionOptions)
        let imageLink = compressedFile ? await uploadToStorage([compressedFile]) : await uploadToStorage([blob])
        if (imageLink) {
          let randomName = crypto.randomUUID().slice(0, 5)
          const input: Emoji_Insert_Input = {
            url: imageLink[0],
            key: emojiUniqueKey + randomName,
            owner_handle: cardinalState.user?.handle,
            name: randomName,
          }

          let data = await createEmojiMutation({ variables: { object: input } }).catch(err => {
            console.log(err)
          })
          if (data) {
            setEmojiList([
              ...emojiList,
              {
                __typename: 'emoji',
                key: emojiUniqueKey + randomName,
                owner_handle: cardinalState.user?.handle,
                url: imageLink[0],
                name: randomName,
              },
            ])
          }
        }
        setUploading(false)
        e.target.value = ''
      }
    }
    setUploading(false)
    return
  }

  return (
    <>
      <h1 className="Tab-Header-Mobile">EMOJI</h1>
      {uploading && <LoadingModal header="Uploading.." subheader="Preparing your files. Please wait." />}
      <input id="Emoji-Editor-Upload-Input" type="file" accept={'image/*'} onChange={e => emojiUpload(e)} hidden />
      <div className="Emoji-Editor-Container">
        <h1>EMOJI</h1>
        <div className="Emoji-Editor-Subheader">
          Add up to {limit} custom emoji that anyone who follows you can use. Animated emoji can be unlocked after 100
          followers along with more slots. (or verified status)
        </div>

        <div className="Emoji-Editor-Requirements-Header">Emoji Requirements:</div>
        <ul className="Emoji-Editor-Requirements-List">
          <li>File type: JPEG, PNG, GIF</li>
          <li>
            Naming: Emoji names must be 3 characters long and can only contain alpanumeric characters and underscores.
            These names will be preceded by a unique id made by you.
          </li>
        </ul>
        <div className="Emoji-Editor-Subheader Emoji-Editor-Subheader-Margin-Top Emoji-Editor-Unique-Id">
          Emoji unique id:
          <input
            style={{ marginLeft: '10px' }}
            className="Emoji-Editor-Input"
            spellCheck={false}
            value={emojiUniqueKey}
            onChange={e => setEmojiUniqueKey(e.target.value)}
          />
        </div>
        <button
          onClick={() => {
            if (emojiList.length < limit) document.getElementById('Emoji-Editor-Upload-Input')?.click()
          }}
          className={`Emoji-Editor-Upload-Button ${
            emojiList.length >= limit ? 'Emoji-Editor-Upload-Button-Disabled' : ''
          }`}>
          Upload Emoji
        </button>
        <div className="Emoji-Editor-Subheader Emoji-Editor-Subheader-Margin-Top">
          Emoji - {limit - (emojiList.length ?? 0)} slots available
        </div>
        <div className="Emoji-Editor-Flex-Container">
          <div className="Emoji-Editor-Flex-Item">
            <div className="Emoji-Editor-Flex-Item-Left">IMAGE</div>
            <div className="Emoji-Editor-Flex-Item-Center">NAME</div>
            <div className="Emoji-Editor-Flex-Item-Right">ID</div>
          </div>
          {emojiList
            .slice()
            .reverse()
            .map((item, key) => (
              <div key={key}>
                <EmojiEditorItem
                  deleteEmoji={emojiDelete}
                  index={key}
                  url={item.url ?? ''}
                  itemId={item.key ?? ''}
                  itemOwnerKey={emojiUniqueKey ?? ''}
                  name={item.name ?? ''}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
