import { ImageShowcase as GraphqlImageShowcase } from '../../../gql/scalars'
import './styles/imageShowcase.css'

type ImageShowcaseProps = {
  imageShowcase: GraphqlImageShowcase
}

export function ImageShowcase(props: ImageShowcaseProps) {
  return (
    <>
      <div className="Profile-Custom-Block">
        <div className={props.imageShowcase.items.length === 1 ? 'Image-Block-Container-Alt' : 'Image-Block-Container'}>
          {props.imageShowcase?.items?.map(
            (showcase, key) =>
              showcase !== null && (
                <div
                  className={props.imageShowcase?.items?.length === 1 ? 'Image-Block-Item-Alt' : 'Image-Block-Item'}
                  key={key}>
                  {showcase.link_text && (
                    <a className="Image-Block-Link" href={showcase.link ? showcase.link : '#'} target="_blank">
                      {showcase.link_text}
                    </a>
                  )}
                  {showcase?.description && <div className="Image-Block-Desc">{showcase.description}</div>}
                  <div
                    className={
                      showcase?.description || showcase?.link_text ? 'Image-Block-Margin' : 'Image-Block-Margin-Alt'
                    }>
                    <div
                      className={
                        showcase.images?.filter(item => item !== null && item !== undefined && item !== '').length === 1
                          ? 'Image-Block-Item-Image-NoGrid'
                          : 'Image-Block-Item-Image-Grid'
                      }>
                      {showcase?.images
                        ?.filter(item => item !== null && item !== undefined && item !== '')
                        .slice(0, 4)
                        .map((image, imageKey) => (
                          <div
                            className={
                              (showcase.images?.filter(item => item !== null && item !== undefined && item !== '')
                                .length === 3 &&
                                imageKey > 0) ||
                              showcase.images?.filter(item => item !== null && item !== undefined && item !== '')
                                .length === 4
                                ? 'Image-Block-Item-Image-Container-Alt'
                                : 'Image-Block-Item-Image-Container'
                            }
                            key={imageKey}>
                            <img
                              className="Image-Block-Item-Image"
                              alt="No Image Found"
                              src={image ? image : ''}
                              draggable
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  )
}
