import React, { useContext, useEffect, useReducer } from 'react'
import { GetUserDocument, User, useGetUserLazyQuery } from '../gql/generated/graphql'
import { AuthStateContext, AuthenticationState } from './authContext'
import { CustomEmoteCategoryType, mergeEmojis } from './cardinalHelper'
import { useApolloClient } from '@apollo/client'
import { Auth } from 'aws-amplify'

export enum BrowsingState {
  Authenticated = 'authenticated',
  Anonymous = 'anonymous',
  Loading = 'loading',
}

const CardinalState: {
  user: User | undefined
  currentProfile: User | undefined
  homeScrollPosition: number
  lastDirectChat: string
  isMobileView: boolean
  browsingState: BrowsingState
  customEmoteCategories: CustomEmoteCategoryType[]
} = {
  user: undefined,
  currentProfile: undefined,
  homeScrollPosition: 0,
  lastDirectChat: '',
  isMobileView: false,
  browsingState: BrowsingState.Loading,
  customEmoteCategories: [],
}

export enum ActionType {
  SetUser = 'setUser',
  SetBrowsingState = 'setBrowsingState',
  SetCurrentProfile = 'setCurrentProfile',
  SetHomeScrollPosition = 'setHomeScrollPosition',
  SetLastDirectChat = 'setLastDirectChat',
  SetIsMobileView = 'setIsMobileView',
  SetCustomEmoteCategories = 'setCustomEmoteCategories',
}

type Action =
  | { type: ActionType.SetUser; payload: typeof CardinalState.user }
  | { type: ActionType.SetBrowsingState; payload: typeof CardinalState.browsingState }
  | { type: ActionType.SetCurrentProfile; payload: typeof CardinalState.currentProfile }
  | { type: ActionType.SetHomeScrollPosition; payload: typeof CardinalState.homeScrollPosition }
  | { type: ActionType.SetLastDirectChat; payload: typeof CardinalState.lastDirectChat }
  | { type: ActionType.SetIsMobileView; payload: typeof CardinalState.isMobileView }
  | { type: ActionType.SetCustomEmoteCategories; payload: typeof CardinalState.customEmoteCategories }

export const CardinalStateContext = React.createContext(CardinalState)
export const CardinalDispatchContext = React.createContext((_payload: Action) => {})

export const CardinalReducer = (state: typeof CardinalState, action: Action) => {
  switch (action.type) {
    case ActionType.SetUser:
      return {
        ...state,
        user: action.payload,
      }
    case ActionType.SetBrowsingState:
      return {
        ...state,
        browsingState: action.payload,
      }
    case ActionType.SetCurrentProfile:
      return {
        ...state,
        currentProfile: action.payload,
      }
    case ActionType.SetHomeScrollPosition:
      return {
        ...state,
        homeScrollPosition: action.payload,
      }
    case ActionType.SetLastDirectChat:
      return {
        ...state,
        lastDirectChat: action.payload,
      }
    case ActionType.SetIsMobileView:
      return {
        ...state,
        isMobileView: action.payload,
      }
    case ActionType.SetCustomEmoteCategories:
      return {
        ...state,
        customEmoteCategories: action.payload,
      }
    default:
      console.error(`Notice: Unhandled action: ${action}`)
      return state
  }
}

type CardinalProps = {
  children: JSX.Element
}

export const Cardinal = ({ children }: CardinalProps) => {
  const [state, dispatch] = useReducer(CardinalReducer, CardinalState)
  const { cognitoUser, authenticationState } = useContext(AuthStateContext)
  const [getUser] = useGetUserLazyQuery()
  const client = useApolloClient()

  useEffect(() => {
    if (state.user?.handle) {
      const observable = client
        .watchQuery({
          query: GetUserDocument,
          variables: { handle: state.user?.handle, currentUserHandle: '' },
        })
        .subscribe({
          next: value => {
            console.log(value)
            dispatch({
              type: ActionType.SetUser,
              payload: value.data.user_by_pk as User,
            })
          },
        })

      return () => {
        observable.unsubscribe()
      }
    }
  }, [client, state.user?.handle])

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth < 751) {
        dispatch({
          type: ActionType.SetIsMobileView,
          payload: true,
        })
      } else {
        dispatch({
          type: ActionType.SetIsMobileView,
          payload: false,
        })
      }
    }

    checkWindowSize()
    window.addEventListener('resize', checkWindowSize)
  }, [])

  useEffect(() => {
    if (authenticationState === AuthenticationState.Loading) {
      dispatch({
        type: ActionType.SetBrowsingState,
        payload: BrowsingState.Loading,
      })
      return
    }

    if (authenticationState === AuthenticationState.Anonymous) {
      dispatch({
        type: ActionType.SetBrowsingState,
        payload: BrowsingState.Anonymous,
      })
      dispatch({
        type: ActionType.SetUser,
        payload: undefined,
      })
      return
    }
  }, [authenticationState, dispatch])

  useEffect(() => {
    cognitoUser?.getUserAttributes((error, userAttributes) => {
      if (error) {
        console.log(error)
        return
      }

      const username = (userAttributes?.filter(n => n.Name === 'preferred_username') ?? [])[0].Value
      if (username) {
        console.log('Getting user information...')

        getUser({ variables: { handle: username, currentUserHandle: '' } }).then(response => {
          if (response.data) {
            console.log('Successfully retrieved user information...')
            dispatch({
              type: ActionType.SetUser,
              payload: response.data.user_by_pk as User,
            })
            dispatch({
              type: ActionType.SetCustomEmoteCategories,
              payload: mergeEmojis(response.data.user_by_pk as User),
            })
            dispatch({
              type: ActionType.SetBrowsingState,
              payload: BrowsingState.Authenticated,
            })
          } else {
            console.log('Failed to retrieve user information. Signing user out...')
            Auth.signOut()
          }
        })
      }
    })
  }, [cognitoUser, dispatch, getUser])

  return (
    <CardinalStateContext.Provider value={state}>
      <CardinalDispatchContext.Provider value={dispatch}>{children}</CardinalDispatchContext.Provider>
    </CardinalStateContext.Provider>
  )
}
