import sanitizeHTML from 'sanitize-html'
import { sanitizeHtmlConf } from '../../constants'

export function sanitizeText(text: string) {
  let sanitizedText = text.replaceAll('<br>', '\n').replaceAll('<br />', '\n')

  sanitizedText = sanitizeHTML(sanitizedText, sanitizeHtmlConf)
  return sanitizedText
}

export function saveSelection() {
  let sel: any
  if (window.getSelection) {
    sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0)
    }
  }
  return null
}
export function restoreSelection(range: any, elementId: string) {
  document.getElementById(elementId)!.focus()
  let sel: any
  if (range) {
    if (window.getSelection) {
      sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}
