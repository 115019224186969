import AddIcon from '@mui/icons-material/Add'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { useContext, useEffect, useState } from 'react'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import { SocialLink, SocialProvider } from '../../../gql/scalars'
import { buildSocialLink } from '../../../utils/statusUtils'
import './styles/socialsEditor.css'

export function SocialsEditor() {
  const editorState = useContext(EditorStateContext)
  const editorDisptach = useContext(EditorDispatchContext)
  const [editing, setEditing] = useState<boolean>(false)
  const [linkTitle, setLinkTitle] = useState<string>('')
  const [linkUrl, setLinkUrl] = useState<string>('')
  const [socialLink, setSocialLink] = useState<SocialLink>({ url: '', icon: '', provider: SocialProvider.Other })

  useEffect(() => {
    setSocialLink(buildSocialLink(linkUrl))
  }, [linkUrl])

  function Add() {
    editorDisptach({
      type: ActionType.addSocialLink,
      payload: socialLink,
    })
    Reset()
  }

  function Remove(key: number) {
    editorDisptach({
      type: ActionType.deleteSocialLink,
      key: key,
    })
  }

  function Reset() {
    setLinkTitle('')
    setLinkUrl('')
  }

  return (
    <>
      <h1 className="Tab-Header-Mobile">SOCIALS</h1>
      <div className="Socials-Editor-Container">
        <div className="Upload-Socials-Info">
          <h1>SOCIALS</h1>
          <h2>
            Update socials to display on your mini profile & profile header. (Max of 5 will display but you can add
            more)
          </h2>
        </div>
        <div className="Socials-Editor-Inner-Container">
          {editorState.socialLinks &&
            editorState.socialLinks.length > 0 &&
            editorState.socialLinks.map((item, key) => (
              <div className="Socials-Editor-Item" key={key}>
                <img className="Socials-Editor-Item-Img" src={item?.icon} alt="" />
                <div className="Socials-Editor-Item-Info">
                  <h2>{item?.provider}</h2>
                  <p>{item?.url}</p>
                </div>
                <div className="Socials-Editor-Item-Options">
                  <div
                    className="Socials-Editor-Item-Option"
                    onClick={() => {
                      Remove(key)
                    }}>
                    <CloseSharpIcon />
                  </div>
                </div>
              </div>
            ))}

          {editing ? (
            <div className="Socials-Editor-Item-Alt">
              <img className="Socials-Editor-Item-Img" src={socialLink.icon} alt={socialLink.provider} />
              <div className="Socials-Editor-Item-Input-Container">
                <input placeholder="Text to show" value={linkTitle} onChange={e => setLinkTitle(e.target.value)} />
                <input placeholder="Where it leads" value={linkUrl} onChange={e => setLinkUrl(e.target.value)} />
              </div>
              <div className="Socials-Editor-Item-Options Socials-Editor-Item-Options-Alt">
                <div
                  className="Socials-Editor-Item-Option"
                  onClick={() => {
                    setEditing(false)
                    Add()
                  }}>
                  <CheckSharpIcon />
                </div>
                <div
                  className="Socials-Editor-Item-Option"
                  onClick={() => {
                    setEditing(false)
                    Reset()
                  }}>
                  <CloseSharpIcon />
                </div>
              </div>
            </div>
          ) : (
            <div className="Socials-Editor-Item" onClick={() => setEditing(true)}>
              <div className="Socials-Editor-Item-Add">
                <AddIcon />
              </div>
              <div className="Socials-Editor-Item-Info">
                <h2>Add New Social</h2>
                <p>New Social Link</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
